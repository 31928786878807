import { Component, OnInit } from '@angular/core'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { UntypedFormBuilder, Validators, UntypedFormGroup, ReactiveFormsModule, FormsModule, FormGroup } from '@angular/forms'
import { OrderService } from 'src/app/services/order.service'
import { Router, ActivatedRoute, RouterModule } from '@angular/router'
import { CommonService } from 'src/app/services/common.service'
import { visa_types } from 'src/app/data/visa.types'
import * as _ from 'lodash'
import { ProductService } from 'src/app/services/product.service'
import { FilterAttributeData } from 'src/app/types/products'
import { visa_entries } from 'src/app/data/visa.entries'
import { TagService } from 'src/app/services/tag.service'
import { environment } from 'src/environments/environment'
import { FormService } from 'src/app/services/form.service'
import { VisaLayoutModule } from 'src/app/components/visa.layout/visa.layout.module'
import { MatSelectModule } from '@angular/material/select'
import { MatRadioModule } from '@angular/material/radio'
import { VisaOptionModule } from 'src/app/components/visa.option/visa.option.module'
import { MatExpansionModule } from '@angular/material/expansion'
import { ApplicantInfoComponent } from 'src/app/components/applicant.info/applicant.info.component'
import { MatButtonModule } from '@angular/material/button'
import { TravelerForm } from 'src/app/types/traveler'
import { HelperService } from 'src/app/services/helper.service'

@Component({
  selector: 'gw-chkout-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  standalone: true,
  imports: [
    ApplicantInfoComponent,
    FormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    RouterModule,
    ReactiveFormsModule,
    VisaLayoutModule,
    VisaOptionModule
  ]
})

export class CountryComponent implements OnInit {
  public country_meta
  public visas
  public country
  public visa_types = visa_types
  public entries = visa_entries
  public types: string[]
  public selected_type: string
  public searchForm: FormGroup<TravelerForm>
  public default_bg: string = 'https://gw-dev-assets.s3.amazonaws.com/meta-content/img-travelvisa-hero-poster-1.jpg'
  public visaForm = this.formBuilder.group({
    addons: [[]],
    type: ['visa', Validators.required],
    subtype: ['', Validators.required],
    country: ['', [
      Validators.required,
      this.formService.locationValidator('country')
    ]],
    product_uuid: ['', Validators.required],
    option_uuid: ['', Validators.required]
  })
  public duplicatedProduct = false

  constructor(
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private commonService: CommonService,
    private orderService: OrderService,
    private productService: ProductService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tagService: TagService,
    private formService: FormService,
    private helperService: HelperService
  ) {
    this.matIconRegistry.addSvgIcon(
      `arrow_right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/arrow-right.svg")
    ).addSvgIcon(
      `arrow_down`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/arrow-down.svg")
    )

    this.route.data.subscribe((response) => {
      if (response.country && response.visas) {
        this.country_meta = response.country
        this.visas = response.visas
        this.defineTypes()
        this.checkForDuplicateProducts()

        this.commonService.getLocation(this.route.snapshot.params.country)
          .subscribe((response) => {
            if (response) {
              this.country = response

              if (this.selected_type) {
                this.visaForm.patchValue({
                  subtype: this.selected_type,
                  product_uuid: this.visas[this.selected_type]?.[0]?.uuid,
                  country: response.value
                })
              } else {
                this.visaForm.patchValue({
                  country: response.value
                })
              }
            } else {
              this.router.navigateByUrl('/travel-visa')
            }
          })
      }
    })

    this.searchForm = this.formService.getTravelerForm()
    let traveler = this.orderService.orderForm.controls.travelers.controls[0]

    if (traveler) {
      this.searchForm.controls.info.patchValue(traveler.controls.info.getRawValue())
    }
  }

  ngOnInit(): void {
    this.tagService.utagPageView('travel:expedited travel:visa:country')
  }

  private defineTypes() {
    if (this.visas) {
      this.types = Object.keys(this.visas)
      if (this.types.length > 0 && !this.types.includes('service_unavailable')) {
        this.selected_type = this.types[0]
        this.onSelectVisaType(this.selected_type)
      }
    }
  }

  public checkLocationValues(): boolean {
    let isValid = true
    const infoGroup = this.searchForm.controls.info as UntypedFormGroup 
    const validCitizenship = infoGroup.controls.citizenship.valid
    const validResidency = infoGroup.controls.residency.valid
    const validCountry = this.visaForm.controls.country.valid
    
    if (!validCitizenship || !validResidency || !validCountry) {
      isValid = false
    }
    
    return isValid
  }

  public getUpdatedProducts() {
    const locationChecks = this.checkLocationValues()

    if (this.visaForm.controls.country.value === this.country.value && locationChecks) {
      let product: FilterAttributeData = {
        citizenship: this.searchForm.value.info.citizenship,
        residency: this.searchForm.value.info.residency,
        residence_country: 'US',
        type: 'visa',
        country: this.visaForm.value.country,
        include_notes: true
      }

      this.productService.getProducts(product)
        .subscribe((products) => {
          this.visas = products
          this.defineTypes()
        })
        
      if (!this.orderService.orderForm.controls.travelers.controls[0]) {
        let traveler_object = this.formService.getTravelerForm()
        this.orderService.orderForm.controls.travelers.push(traveler_object)
      }

      let first_traveler = this.orderService.orderForm.controls.travelers.controls[0]
      first_traveler.patchValue(this.searchForm.getRawValue())
    }
  }

  public onSelectVisaType(type: string) {
    this.selected_type = type
    this.visaForm.patchValue({
      subtype: this.selected_type,
      product_uuid: this.visas[type][0].uuid,
      option_uuid: ''
    })
  }

  public beautifyPeriod(period: string): string {
    return this.helperService.beautifyPeriod(period)
  }

  submitForm() {
    const locationChecks = this.checkLocationValues()

    if (locationChecks) {
      if (!this.orderService.orderForm.controls.travelers.controls[0]) {
        let traveler_object = this.formService.getTravelerForm()
        this.orderService.orderForm.controls.travelers.push(traveler_object)
      }
  
      let first_traveler = this.orderService.orderForm.controls.travelers.controls[0]
      first_traveler.patchValue(this.searchForm.getRawValue())
      first_traveler.controls.products.push(this.visaForm)
  
      //Check for destination
      if (!this.orderService.orderForm.value.itinerary.country) {
        let destination = this.commonService.getCountry(this.visaForm.value.country)
  
        if (destination) {
          this.orderService.orderForm.controls.itinerary.controls.country.patchValue(destination)
        }
      }

      try {
        let product_value = this.visaForm.value
  
        if (product_value.product_uuid && product_value.option_uuid) {
          this.productService.getGAItem(first_traveler.value, product_value)
            .subscribe(response => {
              this.tagService.changeToCart(response, 'add_to_cart')
              this.navigateToCheckout()
            })
        } else {
          this.navigateToCheckout()
        }
      } catch (exception) {
        this.navigateToCheckout()
      }
    }
  }

  private navigateToCheckout() {
    this.orderService.saveCart()
    this.router.navigate(['/step-1'], {queryParamsHandling: 'preserve'})
  }

  cartIsActive(): boolean {
    let activeCart = false
    const traveler = this.orderService.orderForm.value.travelers[0]
    if (traveler) {
      const products = traveler.products
      if (products.length > 0) {
        activeCart = true
      }
    }
    
    return activeCart
  }
  
  checkForDuplicateProducts(): void {
    this.duplicatedProduct = false
    const product_uuid = this.visaForm.value.product_uuid
    const traveler = this.orderService.orderForm.value.travelers[0]
    if (traveler) {
      const products = traveler.products
      products.forEach(product => {
        if (product.product_uuid === product_uuid) {
          this.duplicatedProduct = true
        }
      })
    }
  }

  getServices() {
    if (this.selected_type.includes('evisa') || this.selected_type.includes('eta')) {
      return this.visas[this.selected_type][0].services.filter(service => {
        return service.name == 'E-Visa'
      })
    } else {
      return this.visas[this.selected_type][0].services.filter(service => {
        return service.name != 'E-Visa'
      })
    }
  }

  replaceCompany(text: string) { 
    let company = environment.support.visa_company || 'We'

    return text.replace('TravelVisa.com', company)
  }

  replaceEmail(text: string) {
    let email = environment.support.visa_email || 'eVisa@g3visas.com'

    return text.replace(/eVisa@g3visas.com/gi, email)
  }
}
