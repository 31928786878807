import { Injectable } from "@angular/core"
import { CommonService } from 'src/app/services/common.service'

@Injectable({
  providedIn: 'root'
})

export class CountriesResolverService {
  constructor(
    private commonService: CommonService
  ) {}

  resolve() {
    return this.commonService.getCountries()
  }
}
