import {Component, Input, OnInit, Inject, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { AmazonService } from 'src/app/services/amazon.service'
import { trigger, style, transition, animate } from '@angular/animations'
import { NgxSpinnerService } from 'ngx-spinner'
import { MessageComponent } from 'src/app/dialogs/message/message.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-amazon-pay',
  templateUrl: './amazon.component.html',
  styleUrls: ['./amazon.component.scss'],
  standalone: true,
  animations: [
    trigger('slideIn', [
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(-50%)', opacity: 0 }))
      ])
    ])
  ]
})

export class AmazonComponent implements OnInit, AfterViewInit {
  @Input() config: any
  @Input() credentials
  @ViewChild('AmazonPayButton') AmazonPayButton
  private customerProfile = null
  private orderReference = null
  private processing = false

  constructor(@Inject(DOCUMENT) private document,
    private amazonService: AmazonService,
    private dialogService: MatDialog,
    private spinner: NgxSpinnerService,
    private ref: ChangeDetectorRef) {}

  ngOnInit() {
    // when the user login Amazon-pay
    window.addEventListener('amazon-customer-login', (event: any) => {
      this.customerProfile = (event.detail.login ? event.detail.profile : null)
      window['amazonPay'].createAmazonWallet();
      this.hideSpinner()
    })

    // when the user open the Amazon-pay popup
    window.addEventListener( 'amazon-button-click', (event: any) => {
      // FIXME: We can show the spinner and close it when the user login but if the
      // FIXME: user close the login popup, how can we capture that event to hide the spinner?
      // this.showSpinner()
    })

    // when the order was created on Amazon-pay
    window.addEventListener('amazon-order-reference-created', (event: any) => {
      this.orderReference = event.detail.orderReference
      this.amazonService.transaction = this.orderReference
      // this.authorizeAmazonOrder()
    })

    // when the user change the payment method on Amazon-pay
    window.addEventListener('amazon-payment-selected', (event: any) => {
       // this.authorizeAmazonOrder()
    })
  }

  ngAfterViewInit() {
    if (!document.getElementById('amazon-script')) {
      this.createAmazonScriptTag()
    }
  }

  createAmazonScriptTag() {
    const scriptNode = this.document.createElement('script')
    scriptNode.type = 'text/javascript'
    scriptNode.id = 'amazon-script'
    scriptNode.innerHTML = this.buildScript()
    this.AmazonPayButton.nativeElement.appendChild(scriptNode)
  }

  /**
  * Generate all amazon scripts with the domain credentials
  * and with the events to listen to
  */
  buildScript() {
    return `
      var amazonPay = {
        // reference to the order
        orderReferenceId: null,

        // payment selected
        orderReference:null,

        // to create the amazon-pay button
        createAmazonButton: function(){
          OffAmazonPayments.Button("${this.config.button}", "${this.config.sellerId}", {
            merchant_id: "${this.config.lwaClientId}",
            type: "PwA",
            color: "Gold",
            size: "large",
            language: "en-US",

            authorization: function() {
              window.dispatchEvent(new CustomEvent('amazon-button-click', { detail: {} }));
              loginOptions = {
                scope: "profile payments:widget",
                popup: true
              };
              amazon.Login.authorize(loginOptions, function(response) {
                if ( response.error ) {
                  console.log('oauth error ' + response.error);
                  return;
                }
                amazon.Login.retrieveProfile(response.access_token, function(response) {
                  window.dispatchEvent(new CustomEvent('amazon-customer-login', { detail: {
                      login: true,
                      profile: response.profile
                    }
                  }));
                });
              });
            },

            onError: function(error) {
              window.dispatchEvent(new CustomEvent('amazon-customer-login', { detail: {
                  login: false,
                  profile: null
                }
              }));
              console.log(error.getErrorCode(), error.getErrorMessage())
            }
          })
        },

        // to create the amazon wallet widget to select the payment method
        createAmazonWallet: function(){

          new OffAmazonPayments.Widgets.Wallet({
            sellerId: "${this.config.sellerId}",

            onOrderReferenceCreate: function (orderReference) {
              window.dispatchEvent(new CustomEvent('amazon-order-reference-created', { detail: {
                  orderReference: orderReference.getAmazonOrderReferenceId()
                }
              }));
              amazonPay.orderReferenceId = orderReference.getAmazonOrderReferenceId();
            },

            onPaymentSelect: function(orderReference) {
              window.dispatchEvent(new CustomEvent('amazon-payment-selected', { detail: {
                  order: orderReference
                }
              }));
              amazonPay.orderReference = orderReference
            },

            design: {
              designMode: 'responsive'
            },

            onError: function(error) {
              if (error.getErrorCode() === '') {
                window.dispatchEvent(new CustomEvent('amazon-customer-login', { detail: {
                  login: false,
                  profile: null
                }
              }));
              }
            }
          }).bind("walletWidgetDiv");
        }
      };

    amazon.Login.setClientId("${this.credentials.client_id}");
    window.amazonPay.createAmazonButton();`
  }

  authorizeAmazonOrder() {
      if (this.processing) {
        return false
      }

      this.processing = true
      this.showSpinner()
      const orderCreation = this.amazonService.postRequest(`amazon/confirm/order/${this.config.domain}`, {
          amazon_order_reference_id: this.orderReference,
          amount: this.config.amount,
          seller_note: this.config.sellerNote,
          currency_code: this.config.currencyCode,
      }).subscribe((response) => {
          if (response.status === 200) {
            // store the authorization reference
          } else {
              this.dialogService.open(
                  // Open error dialog if Nonce Failed
                  MessageComponent, {
                      width: 'auto',
                      data: {
                          title: `Amazon-pay Error`,
                          message: `Please, make sure that you have enough funds in the selected payment method.<br><br>
                          If you continue to experience problems, please call
                          <a href="tel:800-220-1759">(800) 220-1759</a> and we’ll help
                          you complete your order.`,
                          icon: `warning`
                      }
                  }
              )
          }
          orderCreation.unsubscribe()
          this.hideSpinner()
          this.processing = false
        })
  }

  // TODO: we can move these methods out to a service because is used in paypal too
  showSpinner() {
      document.body.style.cursor = 'wait'
      this.spinner.show()
      this.ref.detectChanges()
  }

  // TODO: we can move these methods out to a service because is used in paypal too
  hideSpinner() {
      this.spinner.hide()
      this.ref.detectChanges()
      document.body.style.cursor = 'default'
  }
}
