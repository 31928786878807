import { Injectable } from '@angular/core';
import { Traveler } from '../types/traveler'

@Injectable({
  providedIn: 'root'
})

export class TravelerService {
  public hasPassport(traveler: Traveler): boolean {
    return traveler.products.some((product) => {
      return product.type == 'passport'
    })
  }
}
