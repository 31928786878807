<div class="gw-chkout-visa-layout">
  <div class="gw-chkout-visa-layout-hero">
    <img src="{{ bg }}"
      class="gw-chkout-visa-layout-hero__bg"
      alt="Country Image">
  </div>
  <section class="gw-chkout-visa-layout__body">
    <div class="gw-chkout-visa-layout__header">
      @if (country) {
        <h1>
          <span class="fi fi-{{ country.value }}"></span>
          {{ country.label }} Travel Visa
        </h1>
      } @else {
        <h1>
          Travel Visa
        </h1>
      }
    </div>
    <div class="gw-chkout-visa-layout__details">
      <ng-content></ng-content>
    </div>
  </section>
</div>
