import { Component, Input } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio'

@Component({
  selector: 'gwc-ca-travel',
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule
  ],
  templateUrl: './ca.travel.component.html',
  styleUrl: './ca.travel.component.scss'
})

export class CATravelComponent {
  @Input() processing_type: FormControl<string>
  @Input() submitted: boolean
}
