import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { MatExpansionModule } from '@angular/material/expansion'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LocationNamePipeModule } from 'src/app/pipes/location.name/location.name.module'
import { StickyDirectiveModule } from 'src/app/directives/sticky/sticky.module'
import { MatListModule } from '@angular/material/list'
import { provideNgxMask } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TrustpilotModule } from 'src/app/components/trustpilot/trustpilot.module'
import { CheckoutLayoutComponent } from './checkout.layout.component'
import { SummaryComponent } from './summary/summary.component'
import { RouterModule } from '@angular/router'
import { StepsComponent } from './steps/steps.component'
import { SecureModule } from '../secure/secure.module'

@NgModule({
  declarations: [
    CheckoutLayoutComponent,
    StepsComponent,
    SummaryComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    LocationNamePipeModule,
    MatExpansionModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    SecureModule,
    StickyDirectiveModule,
    TrustpilotModule
  ],
  exports: [
    CheckoutLayoutComponent,
  ],
  providers: [provideNgxMask()]
})

export class CheckoutLayoutModule {}
