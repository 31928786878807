import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ShippingService } from 'src/app/services/shipping.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gwc-google-address',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  templateUrl: './google.address.component.html',
  styleUrl: './google.address.component.scss'
})

export class GoogleAddressComponent implements AfterViewInit {
  @ViewChild('shippingAddressInput') shippingAddressInput: ElementRef

  @Input() addressControl: FormGroup
  @Input() type: string
  @Input() showAddress2 = true
  @Input() label: string = undefined
  @Output() updateMap = new EventEmitter()
  @Output() addressUpdated = new EventEmitter()

  private blurTimeout: NodeJS.Timeout
  public country: string = environment.source.country?.toLowerCase() || 'us'

  constructor(
    private shippingService: ShippingService,
    private ngZone: NgZone
  ) {}

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete()
  }

  ngOnDestroy() {
    clearTimeout(this.blurTimeout)
  }

  private getPlaceAutocomplete(): void {
    const options = {
      componentRestrictions: {country: this.country}
    }
    const autocomplete = new google.maps.places.Autocomplete(this.shippingAddressInput.nativeElement, options)

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.ngZone.run(() => {
        const place = autocomplete.getPlace()
        if (place.place_id) {
          this.saveAddress(place)
        } else {
          this.addressControl.get('address').patchValue({})
          this.addressControl.get('address_line').patchValue(null)
        }
      })
    })
  }

  public saveAddress(place: google.maps.places.PlaceResult): void {
    this.addressControl.get('address_line').patchValue(place.formatted_address)
    this.addressControl.get('place_id').patchValue(place.place_id)
    const url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo&q=place_id:" + place.place_id
    this.updateMap.emit({url})
    let address: any = this.shippingService.getPlaceFromGoogleObject(place)

    if (this.addressControl.get('address').get('address_2')?.value) {
      address.address_2 = this.addressControl.get('address').get('address_2')?.value
    }

    this.addressControl.get('address').patchValue(address)
    this.addressUpdated.emit({ place })
  }

  get addressLine() {
    const response = this.addressControl.get('address_line')
    return response
  }

  public onBlur() {
    this.blurTimeout = setTimeout(() => {
      if (!this.addressControl.get('address').get('address_1').value) {
        this.addressControl.get('address_line').setErrors({'selected': true})
      }
    }, 150)
  }
}
