export const SLIDES = {
  aarp: [
    {
      title: "Select Service Type and Place Your Order",
      hide_index: true, 
      icon: {
        name: "step_1",
        width: "90px",
        height: "90px"
      },
      elements: [
        "Select passport service and indicate how quickly you need it.",
        "<strong>Passport Renewal Bundles for AARP Members</strong> include shipping and government fees.",
        "All other passport services will require government fees to be paid separately."
      ]
    },
    {
      title: "Complete and Print Your Application Packet",
      hide_index: true, 
      icon: {
        name: "step_2",
        width: "90px",
        height: "90px"
      },
      elements: [
        "After placing your order, you will be directed to govWorks to begin your application.",
        "The user-friendly, online wizard will generate the required documents.",
        "Review and print your personalized packet and checklist."
      ]
    },
    {
      title: "Get a New Passport Photo",
      hide_index: true, 
      icon: {
        name: "step_3",
        width: "90px",
        height: "90px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo.",
        "Option A: Get a new passport photo at a retail location near you.",
        "Option B: <a href='https://www.passportphotocreator.com/' target='_blank'>Download the Passport Photo Creator app</a> and print at a local Walgreens."
      ]
    },
    {
      title: "Gather Your Documents for Submission",
      hide_index: true, 
      icon: {
        name: "step_4",
        width: "90px",
        height: "90px"
      },
      elements: [
        "Gather your documents and follow the submission instructions.",
        "<strong>Passport Renewal Bundles for AARP Members</strong> will include a prepaid FedEx shipping label.",
        "If your application does not have a FedEx shipping label you will be required to go through the <a href='#adjudication'>Adjudication process</a> with a Passport Acceptance Agent."
      ]
    }
  ],
  aaa: [
    {
      title: "Select Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "Expediting cost does not include shipping fees or government fees, which are paid separately to the U.S. Department of State (starting at $190 for adults)."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      title: "Get Your Passport Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo be submitted with your application.",
        "Visit your local AAA branch to obtain government approved passport photos.",
        "<a href='https://aaa.com/office' target='_blank'>Find your local AAA branch.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all required documents.",
        "Follow the instructions for shipping your documents.",
        "Please note: Some services will not require a FedEx shipping label and are shipped via the Passport Acceptance Agent."
      ]
    }
  ],
  aca: [
    {
      title: "Select Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "Expediting cost does not include shipping fees or government fees, which are paid separately to the U.S. Department of State (starting at $190 for adults)."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      title: "Get Your Passport Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo be submitted with your application.",
        "Visit your local AAA branch to obtain government approved passport photos.",
        "<a href='https://aaa.com/office' target='_blank'>Find your local AAA branch.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all required documentation (birth certification, passport photos, letter of authorization, expired passport).",
        "Use the FedEx shipping label to ship your application to Rush My Passport, where your documents are hand-carried to the U.S. Department of State."
      ]
    }
  ],
  sandals: [
    {
      title: "Select Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "<strong>Expediting cost does not include shipping fees or government fees</strong>, which are paid separately to the U.S. Department of State (<strong>starting at $190</strong> for adults)."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      title: "Get Your Passport Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo to be submitted with your application.",
        "Take the perfect passport photo every time and print nationwide.",
        "<a href='https://www.passportphotocreator.com/' target='_blank'>Download the Passport Photo Creator app today.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all required documents.",
        "Follow the instructions for shipping your documents.",
        "Please note: Some services will not require a FedEx shipping label and are shipped via the Passport Acceptance Agent."
      ]
    }
  ],
  default: [
    {
      title: "Select Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "<strong>Expediting cost does not include shipping fees or government fees</strong>, which are paid separately to the U.S. Department of State (<strong>starting at $190</strong> for adults)."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      title: "Get Your Passport Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo be submitted with your application.",
        "Take the perfect passport photo every time and print nationwide.",
        "<a href='https://www.passportphotocreator.com/' target='_blank'>Download the Passport Photo Creator app today.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all required documents.",
        "Follow the instructions for shipping your documents.",
        "Please note: Some services will not require a FedEx shipping label and are shipped via the Passport Acceptance Agent."
      ]
    }
  ]
}
