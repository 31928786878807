import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'
import { HeaderComponent } from './header/header.component'
import { AvatarComponent } from './header/avatar/avatar.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { StickyDirectiveModule } from '../directives/sticky/sticky.module'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { WarningModule } from './warning/warning.module'

@NgModule ({
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    AvatarComponent,
    BrowserModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    ReactiveFormsModule,
    StickyDirectiveModule,
    WarningModule
  ]
})

export class ComponentsModule {}
