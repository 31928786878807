<footer class="gw-chkout-footer">
  <div class="gw-chkout-footer__item">
    @if (phone) {
      <a class="gw-chkout-footer__phone"
        href="{{ phone.uri }}">
        Questions? {{ phone.number }}
      </a>
    }
    <a class="gw-chkout-footer__logo"
      href="{{ footer.logo_link }}">
      <img src="{{ footer.logo }}" alt="{{ footer.copyright }}" />
    </a>
  </div>
  <div class="gw-chkout-footer__item">
    <ul class="gw-chkout-footer__seals">
      <!-- Legacy McAfee seal. ToDo: remove once all sites are converted -->
      @if (seals.mcafee) {
        <li class="gw-chkout-footer__seals-item">
          <a href="{{ seals.mcafee }}">
            <img src="assets/svg/trusted_site.svg" 
              alt="McAfee" />
          </a>
        </li>
      }

      <!-- Trusted site seal -->
      @if (seals.trusted_site) {
        <li class="gw-chkout-footer__seals-item">
          <script type="text/javascript" src="https://cdn.ywxi.net/js/1.js" async></script>
          <div class="trustedsite-trustmark" data-type="202" data-width="120"  data-height="50"></div>
        </li>
      }

      <!-- TrustE site seal -->
      @if (seals.truste) {
        <li class="gw-chkout-footer__seals-item">
          <a href="//privacy.truste.com/privacy-seal/validation?rid={{seals.truste}}" 
            target="_blank">
            <img style="border: none; height: 34px;" 
              src="//privacy-policy.truste.com/privacy-seal/seal?rid={{seals.truste}}" 
              alt="TRUSTe"/>
          </a>
        </li>
      }
    </ul>
    <div class="gw-chkout-footer__copyright">
      <div>
        @for (type of ['terms', 'privacy', 'refund']; track type) {
          <span class="gw-chkout-footer__copyright-link">
            @if (links?.[type]) {
              <a target="_blank"
                [href]="links[type]">
                {{ labels[type] }}
              </a>
            } @else {
              <a (click)="openDialog(type)">
                {{ labels[type] }}
              </a>
            }
          </span>
        }
        <span class="gw-chkout-footer__copyright-main">
          {{ footer.copyright }} &copy; <a href="https://my.govworks.com">{{ year }}</a>. All Rights Reserved.
        </span>
      </div>

      @if (disclaimer) {
        <div class="gw-chkout-footer__copyright-disclaimer">
          {{ disclaimer }}
        </div>
      }
    </div>
  </div>
</footer>