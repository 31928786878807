import { CurrencyPipe, UpperCasePipe } from '@angular/common'
import { Component, Input, output } from '@angular/core'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { MatRadioChange, MatRadioModule } from '@angular/material/radio'
import { DateTime } from 'luxon'
import { ShippingOption } from 'src/app/types/shipping'

@Component({
  selector: 'gwc-shipping-options',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatRadioModule,
    ReactiveFormsModule,
    UpperCasePipe
  ],
  templateUrl: './shipping.options.component.html',
  styleUrl: './shipping.options.component.scss'
})

export class ShippingOptionsComponent {
  @Input() description: string
  @Input() field: FormControl
  @Input() options: ShippingOption[]
  @Input() submitted: boolean
  @Input() type: string
  shippingOptionSelected = output<MatRadioChange>()

  public displayTime(time) {
    return DateTime.fromFormat(time, "HH:mm:ss").toFormat("HH:mm a")
  }
}
