import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog'
import { MatGridListModule } from '@angular/material/grid-list'

@Component({
  selector: 'gw-chkout-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  standalone: true,
  imports: [
    MatGridListModule,
    MatDialogModule
  ]
})

export class TermsDialogComponent {
  public titles = {
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    refund: 'Refund Policy'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
}
