import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Traveler } from '../types/traveler'
import { Product } from '../types/products'
import { ProductService } from './product.service'
import { Address, Shipping, ShippingForm, ShippingOption } from '../types/shipping'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})

export class ShippingService extends ApiService {
  public shipping_options: ShippingOption[]
  public dynamic_shipping_options: {[key: string]: ShippingOption[]} = {}
  public shippingForm: FormGroup<ShippingForm> = this.formBuilder.group({
    address_line: this.formBuilder.control('', Validators.required),
    place_id: this.formBuilder.control(''),
    address: this.formBuilder.group({
      address_1: this.formBuilder.control('', Validators.required),
      address_2: this.formBuilder.control(''),
      city: this.formBuilder.control<string>('', Validators.required),
      state: this.formBuilder.control('', Validators.required),
      postal_code: this.formBuilder.control('', Validators.required),
      country: this.formBuilder.control(environment.source.country || 'US')
    }),
    speed: this.formBuilder.control<number>(null, Validators.required), 
    inbound_speed: this.formBuilder.control<number>(null)
  })
  
  constructor(
    http: HttpClient,
    private formBuilder: FormBuilder,
    private productService: ProductService
  ) { 
    super(http)
  }

  public getShippingOptions() {
    return this.getRequest(`shipping/costs`)
      .pipe(
        map((response) => {
          this.shipping_options = response

          return response
        })
      )
  }

  public needsInboundShipping(travelers: Traveler[]) {
    return !travelers.some((traveler) => {
      return traveler.products.some((product) => {
        return product.type == 'idp' || (product.type == 'passport' && this.productService.getProductDetails(traveler.info, product, 'inbound_shipping_required'))
      })
    })
  }

  public requireShippingAddress(traveler) {
    let requireShipping = traveler.products.some(product => {
      if (product.type === 'passport') {
        return this.productService.getProductDetails(traveler.info, product, 'outbound_shipping_required') 
          || this.productService.getProductDetails(traveler.info, product, 'outbound_shipping_required')
      } else if (product.type === 'idp') {
        return true
      } else {
        if (product.option_uuid) {
          return !this.productService.getProductDetails(traveler.info, product,'electronic')
        } else {
          return false
        }
      }
    })

    return requireShipping
  }

  public checkShipping(traveler: Traveler, fill: boolean = true): boolean {
    let shipping_included = traveler.products.some(product => {
      if (product.type === 'passport') {
        return this.productService.getProductDetails(traveler.info, product, 'shipping_included') || !this.productService.getProductDetails(traveler.info, product, 'outbound_shipping_required')
      }

      return false
    })

    if (shipping_included) {
      if (this.shippingForm.controls.speed.value) {
        this.shippingForm.controls.speed.reset()
      }

      this.shippingForm.controls.speed.setValidators([])
      return true
    }

    let mailaway: Product | null = this.isMailawayShipping(traveler)

    if (mailaway && (fill || this.shippingForm.controls.speed.value)) {
      let outbound = this.productService.getProductDetails(traveler.info, mailaway, 'mailaway_outbound')
      this.shippingForm.controls.speed.patchValue(outbound.id)
    } else if (this.shippingForm.controls.speed.value) {
      let selected = this.shipping_options.filter(item => item.id === this.shippingForm.controls.speed.value)[0]

      if (selected && selected.speed.includes('usps')) {
        this.shippingForm.controls.speed.reset()
      }
    } else {
      let no_shipping = traveler.products.every((product) => {
        if (product.type === 'digital-photo') {
          return true
        }
        
        return this.productService.getProductDetails(traveler.info, product, 'electronic')
      })

      if (no_shipping) {
        console.log('here')
        this.shippingForm.controls.speed.setValidators([])

        if (this.shippingForm.controls.speed.value) {
          let selected = this.shipping_options.filter(item => item.id === this.shippingForm.controls.speed.value)[0]

          if (selected && selected.speed.includes('usps')) {
            this.shippingForm.controls.speed.reset()
          }
        }
        
        return true
      } 
    }

    if (!mailaway && fill && !this.shippingForm.controls.speed.value) {
      let filtered = this.shipping_options.filter((option) => option.list)

      if (filtered.length === 1) {
        this.shippingForm.controls.speed.patchValue(filtered[0].id)

        let visa = traveler.products.some((product) => product.type === 'visa')

        if (visa) {
          this.shippingForm.controls.inbound_speed.patchValue(filtered[0].id)
        }

        return true
      }
    }

    return mailaway ? true : false
  }

  private isMailawayShipping(traveler: Traveler): Product | null {
    let mailaway: Product | null = null

    traveler.products.some(product => {
      if (this.productService.getProductDetails(traveler.info, product, 'is_mailaway')) {
        mailaway = product
        return true
      }
    })

    return mailaway
  }

  public getShippingPrice(travelers: Traveler[], bound: string) {
    const shipping: Shipping = this.shippingForm.getRawValue()

    switch (bound) {
      case 'inbound':
        return this.getInboundShippingPrice(travelers, shipping)
      case 'outbound':
        return shipping.speed ? this.getPrice(shipping.speed, 'outbound', shipping) : 0
      case 'total':
        const outbound = shipping.speed ? this.getPrice(shipping.speed, 'outbound', shipping) : 0
        const inbound = this.getInboundShippingPrice(travelers, shipping)
        return  outbound + inbound
    }
  }

  private getInboundShippingPrice(travelers: Traveler[], shipping: Shipping) {
    if (shipping.inbound_price) {
      return shipping.inbound_price/100
    }

    if (!this.needsInboundShipping(travelers)) {
      let mailaway_inbound

      travelers.forEach(traveler => {
        let product_mailaway = this.isMailawayShipping(traveler)
  
        if (product_mailaway) {
          mailaway_inbound = this.productService.getProductDetails(traveler.info, product_mailaway, 'mailaway_inbound')
        }
      })
  
      if (mailaway_inbound) {
        return mailaway_inbound.cost/100
      } else {
        // TODO: identify multiple traveler behavior
        let passport_product = travelers[0].products.filter(product => {
          return product.type == 'passport'
        })[0]

        if (passport_product) {
          let shipping = this.productService.getProductDetails(travelers[0].info, passport_product, 'inbound_shipping')
          return shipping ? shipping.cost/100 : 0
        }
      }
    }

    return shipping.inbound_speed ? this.getPrice(shipping.inbound_speed, 'inbound', shipping) : 0
  }

  private getPrice(speed: number, bound: 'outbound'|'inbound', shipping: Shipping) {
    let filtered = this.shipping_options.filter((item) => item.id === speed)

    if (filtered[0]?.type === 'dynamic') {
      return (bound === 'outbound' ? shipping.speed_price : shipping.inbound_price)/100
    }

    return filtered[0] ? filtered[0].cost/100 : 0
  }

  public getPlaceFromGoogleObject(place: google.maps.places.PlaceResult | google.maps.GeocoderResult) {
    let address: any = {}

    place.address_components.forEach((component) => {
      if (component.types.includes('street_number')) {
        address.address_1 = component.short_name
      } else if (component.types.includes('route')) {
        address.address_1 = `${address.address_1} ${component.short_name}`
      } else if (component.types.includes('locality')) {
        address.city = component.short_name
      } else if (!address.city && component.types.includes('sublocality_level_1') && component.short_name.length > 1) {
        address.city = component.short_name
      } else if (!address.city && component.types.includes('administrative_area_level_3') && component.short_name.length > 1) {
        address.city = component.short_name
      } else if (!address.city && component.types.includes('administrative_area_level_2') && component.short_name.length > 1) {
        address.city = component.short_name
      } else if (!address.city && component.types.includes('neighborhood') && component.short_name.length > 1) {
        address.city = component.short_name
      } else if (component.types.includes('administrative_area_level_1')) {
        address.state = component.short_name
      } else if (component.types.includes('postal_code')) {
        address.postal_code = component.short_name
      } else if (component.types.includes('country')) {
        address.country = component.short_name
      }
    })

    return address
  }

  public getDynamicShippingOptions(product_uuid: string, from_address: Address) {
    const data = {
      from_address,
      product_uuid,
      currency: environment.source.currency || 'USD'
    }
    
    return this.postRequest(`shipping/options`, data)
      .pipe(
        map((response) => {
          this.dynamic_shipping_options[product_uuid] = response.data

          return response
        })
      )
  }

  public addShippingPriceControl(cost: number | null, type: 'inbound_price'|'speed_price') {
    if (!this.shippingForm?.get(type)) {
      this.shippingForm?.addControl(type, this.formBuilder.control(cost))
    } else {
      const inbndPriceCtrl = this.shippingForm.get(type)
      inbndPriceCtrl.setValue(cost)
      inbndPriceCtrl.updateValueAndValidity()
      this.shippingForm.updateValueAndValidity()
    }
  }

  public clearShippingOtions(): void {
    const inbndSpeedCtrl = this.shippingForm?.get('inbound_speed')
    const inbndPriceCtrl = this.shippingForm?.get('inbound_price')
    // inbndSpeedCtrl should always exist
    inbndSpeedCtrl.setValue(null)
    inbndSpeedCtrl.updateValueAndValidity()
    if (inbndPriceCtrl) {
      inbndPriceCtrl.setValue(null)
      inbndPriceCtrl.updateValueAndValidity()
    }
    this.shippingForm.updateValueAndValidity()
  }

  public getAAALocation(zip_code: string) {
    return this.getRequest(`admin/manifest/aaa/idp/${zip_code}`)
  }

  public getDynamicShippingPrices(product_uuid: string, from_address: Address, to_address: Address, couriers: {[key: string]: string[]}) {
    return this.postRequest(`shipping/dynamic/options`, {from_address, to_address, couriers}).pipe(
      map((response) => {
        this.dynamic_shipping_options[product_uuid] = response.data

        return response
      })
    )
  }
}
