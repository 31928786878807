import { Component, Input } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gwc-gov-fee',
  standalone: true,
  imports: [],
  templateUrl: './gov.fee.component.html',
  styleUrl: './gov.fee.component.scss'
})

export class GovFeeComponent {
  @Input() product_type: string
  @Input() product_country: string
  @Input() domain: string
  @Input() gov_fee: number
  @Input() gov_fee_included: boolean

  public message: SafeHtml|null
  
  constructor(
    sanitizer: DomSanitizer
  ){
    this.message = environment.checkout_message ?  sanitizer.bypassSecurityTrustHtml(environment.checkout_message) : null
  }
}
