<gw-chkout-card [title]="hideHeader ? '':'How It Works'"
  titleStyle="capitalize">
  <swiper-container autoplay="true"
    navigation="true"
    pagination="true"
    autoplay-delay="3500"
    pagination-clickable="true"
    autoplay-disable-on-interaction="true">
    @for (slide of slider; track slide; let i = $index) {
      <swiper-slide>
        <div class="gw-chkout-slide gw-chkout-slide--{{size}} swiper-slide-content">
          <div class="gw-chkout-slide__icon icon-container">
            <span class="gw-chkout-slide__index"
            [class.gw-chkout-slide__index--hidden]="slide.hide_index">{{ i + 1 }}</span>
            <mat-icon [svgIcon]="slide.icon.name" [ngStyle]="{ 'width': slide.icon.width, 'height': slide.icon.height }"></mat-icon>
          </div>
          <div class="gw-chkout-slide__text">
            <h5>{{ slide.title }}</h5>
            <ul>
              @for (element of slide.elements; track element) {
                <li>
                  <span [innerHtml]="element"></span>
                </li>
              }
            </ul>
          </div>
        </div>
      </swiper-slide>
    }
  </swiper-container>
</gw-chkout-card>