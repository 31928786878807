import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { IQuestion } from '../models/question'
import { QUESTIONS } from '../data/questions'
import { environment } from 'src/environments/environment'
import { VISA_QUESTIONS } from '../data/visa.questions'
import { IDP_QUESTIONS } from '../data/idp.questions'

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  getQuestions(type: string): Observable<Array<IQuestion>> {
    if (environment.questions?.[type]) {
      return of(environment.questions[type]) 
    }

    let domain: string = environment.source.domain
    let source

    switch (type) {
      case 'visa':
        source = VISA_QUESTIONS
        break
      case 'idp': 
        source = IDP_QUESTIONS
        break
      default:
        source = QUESTIONS
    }

    let questions: Array<IQuestion> = source[domain]
    return questions ? of(questions) : of(source.default)
  }
}
