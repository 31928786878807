import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeadingComponent } from './heading.component'
import { MatButtonModule } from '@angular/material/button'
import { ApplicantInfoComponent } from '../applicant.info/applicant.info.component'

@NgModule({
  declarations: [
    HeadingComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ApplicantInfoComponent
  ],
  exports: [
    HeadingComponent
  ]
})

export class HeadingModule { }
