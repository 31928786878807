import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'gw-chkout-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class CardComponent implements OnInit {

  /**
   *  Card title 
   */
  @Input() title: string;

  /**
   *  Used for CSS 'text-transformation' property. Values can be 'capitalize' and 'uppercase' 
   */
  @Input() titleStyle: string; 

  /**
   *  Generate a bottom border or not
   */
  @Input() titleBordered: boolean;

  /**
   *  surround card by space
   */
  @Input() space: boolean = false

  /**
   *  Footer 
   */
  @Input() footer: string
  @Input() footerStyle: string
  @Input() compact: boolean = false

  constructor() { }

  ngOnInit() {

    /**
     * JS FIX:
     * 
     * CSS 'text-transform' property with value 'capitalize'
     * doesn't work when string is in uppercase
     */
    if (this.title) {
      this.title = this.title.toLocaleLowerCase()
    }
  }

}
