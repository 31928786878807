import { Component } from '@angular/core'
import { Location } from '@angular/common'
import { Router, RoutesRecognized, NavigationEnd } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, pairwise } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { TagService } from './services/tag.service'
import { TealiumService } from './services/tealium.service'
import { OrderService } from './services/order.service'

declare var ga: any
declare global {
  interface Window {
    uetq: any
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss'
  ]
})

export class AppComponent {
  public livechat = environment.livechat
  
  constructor(
    private router: Router,
    public location: Location,
    private titleService: Title,
    private tagService: TagService,
    private tealiumService: TealiumService,
    private orderService: OrderService
    ) {
      if (environment.tags && environment.tags.hasOwnProperty('tealium')) {
        this.tealiumService.setConfig({ 
          account: environment.tags.tealium.account, 
          profile: environment.tags.tealium.profile, 
          environment: environment.tags.tealium.env 
        })
      }
    }
    
  ngOnInit() {
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
      ).subscribe((e: any) => {
        this.orderService.previous = e[0].urlAfterRedirects
      })

    localStorage.removeItem('cartStatus')
    
    this.router.events
      .subscribe((event) => {
        if (event instanceof RoutesRecognized) {
          window.scrollTo(0,0)

          let route = event.state.root

          while (route.firstChild) {
           route = route.firstChild
          }

          this.titleService.setTitle(route.data.title)
        }

        if (event instanceof NavigationEnd) {
          this.isCartSubmitted(event.urlAfterRedirects)
          let url = event.urlAfterRedirects

          this.tagService.navigationTags(url)
        }
      })
    
    this.initLiveChat()
  }

  /**
   * After the order is submitted the client always should be at page-5
   * even though if it navigates to another page during the submitting process.
   * In step 5 if the user clicks back instead "start application" then we
   * go to the first step and reload the checkout
   *
   * @param currentPage: The current step
   */
  private isCartSubmitted(currentPage): void {
    switch (localStorage.getItem('cartStatus')) {
      case 'submitting':
        if (currentPage.indexOf('/step-5') !== -1) {
          localStorage.setItem('cartStatus', 'submitted')
        }
        break

      case 'submitted':
        localStorage.removeItem('cartStatus')
        localStorage.removeItem('cart')
        localStorage.removeItem('photo_token')
        localStorage.removeItem('photo_token_expiration')

        this.router.navigate(['/step-1'])
        this.router.events
            .pipe(filter(value => value instanceof NavigationEnd))
            .subscribe(event => {
              // A truly fresh restart
              location.reload()
            })
        break
    }
  }

  /**
   * Live chat script
   */
  private initLiveChat(): void {
    if( environment.livechat && environment.livechat.enabled ) {
      const script = document.createElement('script')
      script.innerHTML = `
        window.__lc = window.__lc || {};
        window.__lc.license = ${ environment.livechat.license_id };
        if(${ environment.livechat.hasOwnProperty('group') }) {
          window.__lc.group = ${ environment.livechat.group };
        }
        (function() {
        var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
        lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
        })();
      `
      document.head.appendChild(script);
    }
  }
}
