<div class="gw-chkout-steps">
  @for (item of steps; track item; let i = $index) {
    <span
      class="gw-chkout-steps__item"
    [ngClass]="{
      'gw-chkout-steps__item--current': i == step,
      'gw-chkout-steps__item--active': i < step
    }">
      <span class="gw-chkout-steps__item-count">
        @if (i >= step) {
          <span>
            {{ i + 1 }}
          </span>
        }
      </span>
      <span class="gw-chkout-steps__item-label">
        {{ item }}
      </span>
    </span>
  }
</div>
