import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormControl, Validators } from '@angular/forms'
import { OrderService } from 'src/app/services/order.service'
import * as _ from 'lodash'
import { Router } from '@angular/router'
import { CommonService } from 'src/app/services/common.service'
import { forkJoin } from 'rxjs'
import { FormService } from 'src/app/services/form.service'
import { LocationSelectComponent } from '../location.select/location.select.component'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'gw-chkout-applicant-info',
  templateUrl: './applicant.info.component.html',
  styleUrls: ['./applicant.info.component.scss'],
  standalone: true,
  imports: [
    LocationSelectComponent,
    MatButtonModule
  ]
})

export class ApplicantInfoComponent implements OnInit {
  @Input() type: string
  @Input() citizenship: UntypedFormControl
  @Input() residency: UntypedFormControl
  @Input() country: UntypedFormControl
  @Input() title: string
  @Output() onInfoUpdated = new EventEmitter

  constructor(
    private orderService: OrderService,
    private commonService: CommonService,
    private router: Router,
    private formService: FormService
  ) {}

  ngOnInit(): void {
    this.checkResidency()
  }

  private checkResidency(): void {
    this.residency.addValidators(Validators.required)
    this.orderService.getCart()
    let orderFormValue = this.orderService.orderForm.value

    if (orderFormValue.travelers && orderFormValue.travelers.length > 0) {
      let traveler = orderFormValue.travelers[0]

      if (traveler.info.residency) {
        this.residency.patchValue(traveler.info.residency)
      }
    } 

    if (!this.residency.value) {
      this.commonService.getRegionCode()
        .subscribe(response => {
          this.residency.patchValue(response)
        })
    }
  }

  public formUpdated(field: string) {
    if (!this.type) {
      if (this.checkForm()) {
        if (field === 'country') {
          this.onInfoUpdated.emit()
          this.router.navigate(['travel-visa', this.country.value])
        } else {
         this.onInfoUpdated.emit()
        }
     }
    }
  }

  public onSearch($event: Event) {
    if (this.citizenship.value == this.country.value) {
      return this.country.setErrors({match: true})
    }

    if (this.type == 'restrictions') {
      this.onInfoUpdated.emit(this.country.value)
    } else {
      this.goToCountry($event)
    }
  }

  public checkForm() {
    let valid = true 

    if (!this.country.value) {
      this.country.markAsTouched()
      this.country.setErrors({ invalid: true })
      valid = false
    }

    if (!this.residency.value) {
      this.residency.markAsTouched()
      this.residency.setErrors({ invalid: true })
      valid = false
    }

    if (!this.citizenship.value) {
      this.citizenship.markAsTouched()
      this.citizenship.setErrors({ invalid: true })
      valid = false
    }

    return valid
  }

  public goToCountry($event: Event) {
    $event.preventDefault()

    if (!this.checkForm()) return

    let requests = [
      this.commonService.getLocation(this.country.value, 'country'),
      this.commonService.getLocation(this.residency.value, 'state'),
      this.commonService.getLocation(this.citizenship.value, 'country')
    ]

    forkJoin(requests).subscribe((response) => {
      if (response[0] && response[1] && response[2]) {
        if (!this.orderService.orderForm.controls.travelers.controls[0]) {
          let traveler_object = this.formService.getTravelerForm()
          this.orderService.orderForm.controls.travelers.push(traveler_object)
        }

        this.orderService.orderForm.controls.travelers.controls[0].patchValue({ info: {residency: this.residency.value, citizenship: this.citizenship.value }})
        this.orderService.saveCart()
        this.onInfoUpdated.emit()
        this.router.navigate(['travel-visa', this.country.value])
      } else {
        if (!response[0]) {
          this.country.setErrors({ invalid: true })
        }

        if (!response[1]) {
          this.residency.setErrors({ invalid: true })
          this.residency.markAsTouched
        }

        if (!response[2]) {
          this.citizenship.setErrors({ invalid: true })
          this.residency.markAsTouched
        }
      }
    })
  }
}
