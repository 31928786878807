import { NgModule } from '@angular/core'
import { SecureComponent } from './secure.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [
    SecureComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SecureComponent
  ]
})

export class SecureModule {}
