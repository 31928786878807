import { CurrencyPipe } from '@angular/common'
import { Component, Input, inject } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatRadioModule } from '@angular/material/radio'
import { OrderService } from 'src/app/services/order.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'gwc-refund',
	standalone: true,
	imports: [
		MatRadioModule,
		ReactiveFormsModule,
		CurrencyPipe
	],
	templateUrl: './refund.component.html',
	styleUrl: './refund.component.scss',
})

export class RefundComponent {
	#orderService = inject(OrderService)

	@Input({required: true}) orderForm: FormGroup
	currency: string = environment.source.currency || 'USD'

	getRefundGuarantee(): number {
		return (this.#orderService.getBasePrice() - this.#orderService.getDiscountTotal(true) + this.#orderService.getShippingPrice()) * this.#orderService.REFUND_GUARANTEE_RATE
	}

	getServicePrice(): number {
		return this.#orderService.getServicePrice()
	}
}
