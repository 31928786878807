<div class="gw-chkout-card"
  [class.gw-chkout-card--space]="space"
  [class.gw-chkout-card--compact]="compact">
  @if (title) {
    <div
      [ngClass]="{ 'gw-chkout-card__title--bordered': titleBordered }"
      class="gw-chkout-card__title">
      <h4 [ngClass]="{ 'capitalize': titleStyle == 'capitalize', 'uppercase': titleStyle == 'uppercase' }">{{ title }}</h4>
    </div>
  }
  <div class="gw-chkout-card__body">
    <ng-content></ng-content>
  </div>
</div>