export const VISA_SLIDES = {
  fedex: [
    {
      title: "Select Country and Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Confirm your Nationality and state of residence.",
        "Select the travel Destination (Country) and Visa type.",
        "Expediting processing speeds range depending upon the visa type and country to be visited.",
        "Expediting Cost does not include shipping fees or government fees."
      ]
    },
    {
      title: "Important Instructions",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "Once your order has been placed, you will receive an itemized list of requirements for your visa request.",
        "Please complete in full the visa application and gather any supporting documents needed for the request."
      ]
    },
    {
      title: "Get Your Photo at FedEx Office",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every application requires a printed government-compliant photo be submitted with your application.",
        "See a team member at FedEx Office to purchase your photo.",
        "<a href='https://local.fedex.com/?promo=passport' target='_blank'>Find a FedEx Office near you.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all the required documentation (visa application, passport photos, travel itinerary, passport with minimum 6 months of validity).",
        "Use the FedEx shipping label to ship your application to our office, where your documents will be reviewed and submitted to the Embassy/Consulate for processing.",
        "E-visas are handled online and do not require shipping."
      ]
    }
  ],
  aaa: [
    {
      title: "Select Country and Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Confirm your Nationality and state of residence.",
        "Select the travel Destination (Country) and Visa type.",
        "Expediting processing speeds range depending upon the visa type and country to be visited.",
        "Expediting Cost does not include shipping fees or government fees."
      ]
    },
    {
      title: "Important Instructions",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "Once your order has been placed, you will receive an itemized list of requirements for your visa request.",
        "Please complete in full the visa application and gather any supporting documents needed for the request."
      ]
    },
    {
      title: "Get Your Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every application requires a printed government-compliant photo be submitted with your application.",
        "Check with your local AAA branch to obtain your passport/visa photos.",
        "<a href='https://aaa.com/office' target='_blank'>Find your local AAA branch.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all the required documentation (visa application, passport photos, travel itinerary, passport with minimum 6 months of validity).",
        "Use the FedEx shipping label to ship your application to our office, where your documents will be reviewed and submitted to the Embassy/Consulate for processing.",
        "E-visas are handled online and do not require shipping."
      ]
    }
  ],
  aca: [
    {
      title: "Select Country and Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Confirm your Nationality and state of residence.",
        "Select the travel Destination (Country) and Visa type.",
        "Expediting processing speeds range depending upon the visa type and country to be visited.",
        "Expediting Cost does not include shipping fees or government fees."
      ]
    },
    {
      title: "Important Instructions",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "Once your order has been placed, you will receive an itemized list of requirements for your visa request.",
        "Please complete in full the visa application and gather any supporting documents needed for the request."
      ]
    },
    {
      title: "Get Your Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every application requires a printed government-compliant photo be submitted with your application.",
        "Check with your local AAA branch to obtain your passport/visa photos.",
        "<a href='https://aaa.com/office' target='_blank'>Find your local AAA branch.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all the required documentation (visa application, passport photos, travel itinerary, passport with minimum 6 months of validity).",
        "Use the FedEx shipping label to ship your application to our office, where your documents will be reviewed and submitted to the Embassy/Consulate for processing."
      ]
    }
  ],
  default: [
    {
      title: "Select Country and Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Confirm your Nationality and state of residence.",
        "Select the travel Destination (Country) and Visa type.",
        "Expediting processing speeds range depending upon the visa type and country to be visited.",
        "Expediting Cost does not include shipping fees or government fees."
      ]
    },
    {
      title: "Important Instructions",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "Once your order has been placed, you will receive an itemized list of requirements for your visa request.",
        "Please complete in full the visa application and gather any supporting documents needed for the request."
      ]
    },
    {
      title: "Get Your Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every application requires a printed government-compliant photo be submitted with your application.",
        "Take the perfect photo every time and print nationwide."
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all the required documentation (visa application, passport photos, travel itinerary, passport with minimum 6 months of validity).",
        "Use the FedEx shipping label to ship your application to our office, where your documents will be reviewed and submitted to the Embassy/Consulate for processing."
      ]
    }
  ]
}
