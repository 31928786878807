<article class="gw-checkout-container gw-chkout-terms">
  <h2>
    {{ titles[type] }}
  </h2>
  <section>
    @switch (type) {
      @case ('terms') {
        <section>
          <p>I hereby authorize the Expedited Travel network to submit my passport application to the U.S. Passport Agency and to accept delivery of the passport on my behalf. Under the provisions of the Privacy Act of 1974 (Public Law 93-579), no information may be released from U.S. Government files without the prior written consent of the individual in question. Consequently, an employee of the U.S. Passport Agency cannot discuss the details of your passport application with the courier without your permission.</p>
          <p>I understand that a travel itinerary is required to use an expediting service. If a travel itinerary cannot be provided, the Department of State may accept a letter from a business stating you are traveling on business if the trip is business related.</p>
          <p>Expedited Travel is not responsible for any delays or errors caused by mail couriers such as FedEx, UPS or any other commercial carrier. Likewise, we are also not responsible for any delays resulting from errors or omissions in information provided to us by a Passport applicant. Applicants are responsible for the completeness of all application material.</p>
          <p>Your acknowledgement of the terms and conditions herein signifies that you understand and agree to the following:</p>
          <ol class="gw-chkout-terms__list">
            <li>
              We act as your limited agent for the limited purpose of submitting and facilitating your application through a courier (“Agent”). We cannot accept responsibility for delays in or loss of any document(s) by any party not directly under our control, including, but not limited to, Travel Agents, Consulates, Embassies, Passport Offices, or any other Entities or any delivery-service involved in handling documents. We are a courier service which expedites the final delivery of your passport under the National Hand-Carry Procedures for Passport Applications and Completed Passports. We are not the U.S. government.
            </li>
            <li>
              You understand that you are paying an additional fee to {{ name }} and that you are still responsible for government fees which will be paid directly to the United States Department of State (“Issuing Authority”).
            </li>
            <li>
              We do not issue your passport or other travel documents. We are an alternative to the US Post Office and the Issuing Authority which allows you to obtain your passport without requiring an in-person visit to the Issuing Authority’s locations, which may not be conveniently located to your personal address. We help you submit & retrieve your passport or other travel documents as your authorized Agent, in a timely manner. In addition, our process allows for you to receive periodic updates on the status of your order and we perform quality review procedures on your application to reduce the chance of any suspensions placed on your account by the Issuing Authorities.
            </li>
            <li>
              We have no control over the decisions made by Issuing Authorities regarding denial or approval of any passport or travel document. We have no control over issuing authority’s requirements for additional supporting documents and/or additional supporting requirements based upon the information submitted by you whether directly or through us as your agent. We have no control over the substance and/or completeness of your applications and statements therein which may affect the decision to either approve or deny your passport or other travel documents. You understand that you are solely responsible for the completeness and accuracy of all information submitted to any Agency for issuance of any passport or travel document.
            </li>
            <li>
              We reserve the right to refuse service to any customer upon our suspicion or belief that information may be inaccurate, incomplete or otherwise deficient. Such refusal of service is at our sole discretion. Our maximum liability for refusal of service is limited to the amount of fee paid to and collected by us from you under all circumstances, without limitation.
            </li>
            <li>
              We have no control over official requirements of any Issuing Agency. Requirements for issuance of travel documents can and do change often. We cannot be held responsible for changes in official requirements whether mandatory or subjective. We will attempt to notify you of any such changes as they become available.
            </li>
            <li>
              Expedited Travel, its owners, Agents and affiliates, and employees shall not provide any guarantees express or implied. Liability is limited to fees paid. {{ name }}, its owners, agents and affiliates, and employees shall not be liable for its ordinary negligence. You hereby release and agree to indemnify and hold harmless {{ name }}, its owners, agents and affiliates, and employees for any and all liability related to ordering and delivery of your passport, even if it’s due to their own negligence.
            </li>
            <li>
              If your required documentation and/or supporting documentation is not received by us in the specified time period (typically 3 business days, but sooner depending upon service requested or required) additional charges may be incurred by you to facilitate service to provide a passport or other travel document by the departure date listed by you within your order form. We cannot guarantee that even with additional fees or charges that such documents will be provided by your listed departure date when your required documentation is not received by us in the specified time period.
            </li>
            <li>
              Any order canceled within 24 hours or 1 business day and not under manifest is entitled to a full refund. Any order under manifest occurs after speaking with a representative and a reservation of limited capacity has been placed on your behalf. Given the limited capacity of these manifests, any order under manifest will not be entitled to a refund. If you cancel any time after 24 hours, you will not be entitled to a refund. However, any canceled order not entitled to a refund will remain active for 12 months from the date your order was placed and may be processed at any time throughout that period or transferred to a friend or family member.
            </li>
            <li>
              During world health crisis and pandemic events, significant delays can occur that may impact Expedited Travel’s ability to secure passport documents within communicated time frames.  These delays are out of our control and under no circumstances shall we be held liable.
            </li>
          </ol>
          <p><b>Expedited Travel</b></p>
          <p>222 Clematis Street<br/>
            Suite 200<br/>
          West Palm Beach, FL, 33401</p>
        </section>
      } @case ('privacy') {
        <section>
          <p>Effective Date: 12/26/2016</p>
          <p>This privacy policy applies to <a [href]="website_url">{{ website }}</a> owned and operated by Expedited Travel. This privacy policy describes how Expedited Travel collects and uses the personal information you provide on our web site: <a [href]="website_url">{{ website }}</a>. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information.</p>
          <p>If you have questions or complaints regarding our privacy policy or practices, please contact us at {{ email }}. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third party dispute resolution provider (free of charge) at <a href="https://feedback-form.truste.com/watchdog/request">https://feedback-form.truste.com/watchdog/request</a>.</p>
          <p>Expedited Travel is committed to protecting your privacy. We take the trust our customers and site visitors place in us very seriously. In order to process your order, we need to know some standard information such as your name, e-mail address, mailing address, date of birth, credit card number and telephone number.</p>
          <p>For the purpose of filing your travel documents we need to know/provide to the issuing agency the following information (the following list may not be all inclusive and this information is collected offline and associated with the personal information named above and collected from you online.):</p>
          <ul>
            <li>Prior US Passport</li>
            <li>Your Travel itinerary</li>
            <li>Your Birth certificate</li>
            <li>Your Driver’s license</li>
          </ul>
          <p>Our users also have the ability to submit a third party’s personal information. This only occurs in the instance that a user submits their child’s personal information to register them for our passport service- this information would include name, age and address. The information our user submits will be used only for the purpose for which it is collected and will not be used for any other reason. It is our Privacy Policy that this information shall remain private and confidential. If you wish to remove this third party information you can contact us at {{ email }}. Accordingly, the personal information you provide is stored in a secure location and is accessible only by designated staff. We employ the use of SSL encryption when transmitting your sensitive financial information and employ industry accepted standards in protecting your information. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security. If you have any questions about security on our Web site, you can contact us at {{ email }}. Your personal information is used only for the purposes for which you provide the information. We will share your personal information with third parties only in the ways that are described in this privacy policy. We do not sell your personal information to third parties. Your personal information is used only for the purposes for which you provide the information, with the following limitation:</p>
          <ol>
            <li>
              <p><b>Agents:</b> We use an outside shipping company to fulfill orders, and a credit card processing company to bill you for services. We also employ the services of a third party agent in hosting our chat feature. These companies do not retain, share, store or use personally identifiable information for any other purposes.</p>
            </li>
            <li>
              <p><b>Service Providers:</b> We use other third parties such as The Department of State and consulates to the country of choice to provide passport and visa services on the Sites. We will share personal information as necessary for the third party to provide that service. These third parties are prohibited from using your personally identifiable information for any other purpose. When you submit an order, your information is encrypted with secure server software to protect the information from unauthorized access. We will not sell, rent or trade any personal information we collect from you on our web site unless as otherwise disclosed in this privacy statement.</p>
            </li>
            <li>
              <p><b>Service Updates:</b> It is our policy to keep you informed for the duration of your order; we will notify you via e-mail and telephone of the status of your order. If you would rather not receive any updates from us, please send a request to be removed from our e-mail update list to: {{ email }}</p>
            </li>
            <li>
              <p><b>Cookies and Other Tracking Technologies:</b> Expedited Travel and its partners use cookies or similar technologies to analyze trends, administer the website, track user’s™ movements around the website, and to gather demographic information about our user base as a whole. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.<br/>
              As is true of most web sites, we gather certain information automatically and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data. We do not link this automatically collected data to other information we collect about you.</p>
            </li>
            <li>
              <p><b>Behavioral Targeting/ Re-Targeting:</b> We partner with a third party to either display advertising on our Web site or to manage our advertising on other sites. Our third party partner may use technologies such as cookies or similar technologies to gather information about your activities on this site and other sites in order to provide you advertising based upon your browsing activities and interests. If you wish to not have this information used for the purpose of serving you interest-based ads, you may opt-out by clicking here. Please note this does not opt you out of being served ads. You will continue to receive generic ads.</p>
            </li>
            <li>
              <p><b>Order Processing:</b> When sending personal data with credit card information over the Internet to {{ name }}, customers should feel secure in the knowledge that this data is protected by encryption over a “Secure Socket Layer (SSL)” technology, which ensures safe transmission.</p>
            </li>
            <li>
              <p><b>Legal disclaimer:</b> In certain situations, Expedited Travel may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.<br/>
              We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, bankruptcy proceedings or legal process served on our Web site.</p>
            </li>
            <li>
              <p><b>Changes in this Privacy Statement:</b> If we decide to change our privacy policy, we will post those changes to this privacy statement, the home page, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by email, or by means of a notice on our home page prior to the change becoming effective.</p>
            </li>
            <li>
              <p><b>Business Transitions:</b> In the event Expedited Travel goes through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, your personally identifiable information will likely be among the assets transferred. You will be notified via email or prominent notice on our Web site for 30 days of any such change in ownership or control of your personal information.</p>
            </li>
            <li>
              <p><b>Contacting the Website:</b> If you have any questions about this Privacy Policy, the practices or dealings with the Sites, you can contact our Customer Service at {{ email }}. You may also use this email address to update or delete any personal information (including third party information) you may have already submitted to the website. Upon request Expedited Travel will also provide you with information about whether we hold any of your personal information. We will respond to your request within a reasonable timeframe.</p>
            </li>
            <li>
              <p><b>Data Retention:</b> We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
            </li>
            <li>
              <p><b>Links to 3rd Party Sites:</b> Our Site includes links to other Web sites whose privacy practices may differ from those of Expedited Travel. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any Web site you visit.</p>
            </li>
            <li>
              <p><b>Blog/ Forum:</b> Our Web site offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at {{ email }}. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</p>
            </li>
            <li>
              <p><b>Testimonials:</b> We display personal testimonials of satisfied customers on our site in addition to other endorsements. With your consent we may post your testimonial along with your name. If you wish to update or delete your testimonial, you can contact us at {{ email }}.</p>
            </li>
            <li>
              <p><b>Social Media Widgets:</b> Our Web site includes Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button or interactive mini-programs that run on our site. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these Features are governed by the privacy policy of the company providing it.</p>
            </li>
          </ol>
          <ul class="footer-nav"><li><a href="https://checkout.rushmyphoto.com/terms-of-use">TERMS AND CONDITIONS</a></li><li class="line">|</li><li><a href="https://checkout.rushmyphoto.com/privacy-policy">PRIVACY POLICY</a></li></ul>
          <p>
            <b>Expedited Travel</b>
            <br/><br/>
            222 Clematis Street<br/>
            Suite 200<br/>
            West Palm Beach, FL, 33401
            <br/><br/>
            {{ email }}
          </p>
          <h4>Privacy Notice for California Residents</h4>
          <p>Effective Date: January 1, 2020</p>
          <p>The Privacy Notice for California Residents (“Privacy Notice”) supplements the information contained in our Privacy Policy, and applies solely to visitors, users and others who reside in the State of California (<b>“consumers”</b> or <b>“you”</b>). This Privacy Notice addresses your rights under the California Consumer Privacy Act of 2018 (<b>“CCPA”</b>); any terms defined in the CCPA have the same meaning when used in this Privacy Notice. You can download a printable copy of this Privacy Notice here.</p>
          <p><b>INFORMATION WE COLLECT</b></p>
          <p>This website and our services collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device (<b>“personal information”</b>). We may collect or have collected in the past twelve (12) months, the following categories of personal information.  We obtain the categories of personal information listed below as set forth in the methods described in the “Where do we get this information?” section of the Privacy Policy.</p>
          <ul>
            <li>
              <p><b>Identifiers.</b> Such as a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or other similar identifiers.</p>
            </li>
            <li>
              <p><b>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</b> Such as name, signature, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</p>
            </li>
            <li>
              <p><b>Protected classification characteristics under California or federal law.</b> Such as age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
            </li>
            <li>
              <p><b>Commercial information.</b> Such as records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
            </li>
            <li>
              <p><b>Internet or other similar network activity.</b> Such as browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.</p>
            </li>
            <li>
              <p><b>Geolocation data.</b> Such as physical location or movements.</p>
            </li>
            <li>
              <p><b>Professional or employment-related information.</b> Such as current or past job history or performance evaluations.</p>
            </li>
            <li>
              <p><b>Inferences drawn from other personal information.</b> Such as profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
            </li>
          </ul>
          <p><b>USE OF PERSONAL INFORMATION</b></p>
          <p>We may use or disclose the personal information we collect for one or more of the business purposes indicated in the “How do we use your information?” section of our Privacy Policy.</p>
          <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
          <p><b>SHARING PERSONAL INFORMATION</b></p>
          <p>We may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. We share your personal information with the categories of third parties listed in the “Who do we share your information with?” section of our Privacy Policy.</p>
          <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
          <ul>
            <li>Identifiers</li>
            <li>California Customer Records personal information categorie</li>
            <li>Protected classification characteristics</li>
            <li>Commercial information</li>
            <li>Internet or other similar network activity</li>
            <li>Geolocation data</li>
            <li>Professional or employment-related information</li>
          </ul>
          <p>We do not sell personal information. In the event that we do sell any personal information, we will update this Privacy Policy to list the categories of consumers’ personal information sold.</p>
          <p><b>YOUR RIGHTS AND CHOICES</b></p>
          <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
          <p><b>Right to Access Specific Information and Data Portability Right</b></p>
          <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past twelve (12) months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
          <ul>
            <li>The categories of personal information we collected about you.</li>
            <li>The categories of sources for the personal information we collected about you.</li>
            <li>Our business or commercial purpose for collecting or selling that personal information.</li>
            <li>The categories of third parties with whom we share that personal information.</li>
            <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
            <li>If we disclosed your personal information for a business purpose, the business purpose for which personal information was disclosed, and the personal information categories that each category of recipient obtained.</li>
          </ul>
          <p><b>Right to Delete</b></p>
          <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
          <p><b>Exercising Your Rights</b></p>
          <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:</p>
          <ul>
            <li>Calling us at (866) 238-9794</li>
            <li>Sending us an e-mail at support&#64;expeditedtravel.com</li>
          </ul>
          <p>For submissions via email please use the downloadable Verifiable Consumer Request Form.</p>
          <p>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
          <p>You may only make such a request for access or data portability twice within a 12-month period. The verifiable consumer request must provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative and describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</p>
          <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
          <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response electronically. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
          <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why We made that decision and provide you with a cost estimate before completing your request.</p>
          <p><b>Non-Discrimination</b></p>
          <p>We will not discriminate against you for exercising any of your CCPA rights</p>
          <p><b>CONTACT INFORMATION</b></p>
          <p>If you have any questions or comments about this notice, the ways in which we collect and use your information described in this Privacy Policy, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at</p>
          <p>
            <b>Phone:</b>	(866) 238-9794<br/>
            <b>E-mail:</b>	support&#64;expeditedtravel.com<br/>
            <b>Postal Address:</b>
            <br/><br/>
            Expedited Travel, LLC<br/>
            ATTN: Data Protection Officer<br/>
            222 Clematis Street<br/>
            Suite 200<br/>
            West Palm Beach, FL, 33401<br/>
          </p>
        </section>
      } @case ('refund') {
        <section>
          <p>Any ordered canceled and not under manifest is entitled to a full refund. Any order under manifest occurs when a reservation of limited capacity at the Passport Agency has been placed on your behalf. Given the limited capacity of these reservations, any order under manifest will not be entitled to a refund. However, a non-refundable order remains active for 12 months from the date the order was placed and may be processed at any time within that period or transferred to a family member or friend.</p>
          <mat-grid-list class="gw-chkout-terms__grid"
            rowHeight="50px"
            cols="2"
            rows="3">
            <mat-grid-tile [colspan]="2">
              <b>Expedited Travel Refund Policy</b>
            </mat-grid-tile>
            <mat-grid-tile>Service Purchase – Not Manifested</mat-grid-tile>
            <mat-grid-tile>Full Refund of Fees Paid</mat-grid-tile>
            <mat-grid-tile>Service Purchased – Order has been Manifested</mat-grid-tile>
            <mat-grid-tile>No Refund</mat-grid-tile>
          </mat-grid-list>
          <p>All refund requests require the customer to complete an electronic Refund Request Form (RRF). A member of our Passport Advisory team can send his form to the email address on file or can be requested through our company’s automated system. A request for this form does not guarantee a refund will be granted.</p>
          <p>By agreeing to the terms herein, refunds are not possible for any reason beyond Expedited Travel’s control, including but not limited to:</p>
          <ol>
            <li>
              Flight cancellations, missing flights, flight delays, or other travel itinerary changes made by the airline or customer.
            </li>
            <li>
              Delays in passport or other travel document deliveries.
            </li>
            <li>
              Lost, stolen, or misplaced tickets.
            </li>
            <li>
              Passport procurement difficulties, including changes in policy, regulations or requirements.
            </li>
            <li>
              Incomplete or inaccurate filing information provided.
            </li>
          </ol>
          <p>The foregoing list is representative only.</p>
          <p>The foregoing list is representative only. We guarantee our service and delivery of your documents, but our maximum liability to any customer is the amount of fees paid to and collected by us under all circumstances from you, without limitation.</p>
          <p>No guarantee or warranty express or implied is provided other than stated here in above. Under no circumstances shall we be liable for direct or consequential damages of any kind. Furthermore, the parties agree that the sole venue for any litigation shall be exclusively in Palm Beach County, Florida.</p>
          <p><b>Expedited Travel</b></p>
          <p>222 Clematis Street<br/>
            Suite 200<br/>
          West Palm Beach, FL, 33401</p>
        </section>
      }
    }
  </section>
  @if (trustpilot) {
    <gw-chkout-trustpilot
      [trustpilot]="trustpilot">
    </gw-chkout-trustpilot>
  }
</article>