import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { IPayPalConfig } from 'ngx-paypal'
import { OrderService } from 'src/app/services/order.service'
import { HttpClient } from '@angular/common/http'
import { MessageComponent } from 'src/app/dialogs/message/message.component'
import { environment } from 'src/environments/environment'
import { BehaviorSubject } from 'rxjs'
import * as _ from 'lodash'
import { MatDialog } from '@angular/material/dialog'

@Injectable()

export class PaypalService extends ApiService {

  public payPalConfig ?: IPayPalConfig
  public transaction
  public sale
  public name = 'PayPalExpress'
  public status

  /**
   * Service constructor
   *
   * @param http: Http service
   * @param orderService: The order
   * @param dialogService: The Dialogs
   */
  constructor(
    http: HttpClient,
    private orderService: OrderService,
    private dialogService: MatDialog) {

    super(http)

    this.transaction = null
    this.status = new BehaviorSubject('init')
  }

  /**
   * Initialize the component and update the shopping cart
   * it works ok for intent AUTHORIZE
   *
   * https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
   */
  public initServerConfig(clientId: string): void {
    this.payPalConfig = {
      clientId: clientId,

      // for creating orders (transactions) on server see
      createOrderOnServer: (data) => fetch(`${environment.API}payment/paypal/create/transaction/${environment.source.domain}`, {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ data : [ this.orderService.getPurchaseUnits() ] })
        })
        .then((res) => {
          return res.json()
        })
        .then((order) => {
          return order.id
        }),

      advanced: {
        extraQueryParams: [
          { name: 'disable-funding', value: 'credit' },
          { name: 'intent', value: 'authorize' },
          { name: 'disable-card', value: 'discover,visa,amex,mastercard'}
        ]
      },

      authorizeOnServer: (approveData) => {
        this.sale = approveData.orderID
        this.status.next('processing')
        return fetch(`${environment.API}payment/paypal/authorize/transaction/${environment.source.domain}`, {
          method: 'post',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            order_id: approveData.orderID
          })
        }).then((res) => {
          return res.json();
        }).then((details) => {
          this.transaction = details.authorization_id
          this.status.next('done')
        });
      },

      onApprove: (data, actions) => {
      },

      onCancel: (data, actions) => {
        this.transaction = null
      },

      onError: err => {
        this.transaction = null
      }
    }
  }

  /**
   * Form validation
   *
   */
  public validateForm() {
    if (this.transaction) {
      localStorage.setItem('cartStatus', 'submitting')
      this.orderService.createOrder(this.transaction, { merchant: 'paypal', sale: this.sale })
    } else {
      this.status.next('error')
      this.orderService.orderCreateSubject.next({success: false})
      this.dialogService.open(
        MessageComponent, {
          width: 'auto',
          data: {
            title: `Paypal Authorization Error`,
            message: `Please make sure you authorized the payment on PayPal Express Gateway. Please,
            <b>click PayPal button</b> and authorize the purchase.<br><br>
              If you continue to experience problems, please call
              <a href="tel:800-220-1759">(800) 220-1759</a> and we’ll help
              you complete your order.`,
            icon: `warning`
          }
        }
      )
    }
  }
}
