import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatMenuModule } from '@angular/material/menu'
import { AuthService } from 'src/app/services/auth.service'
import { User } from 'src/app/types/user'

@Component({
  selector: 'gw-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule
  ]
})

export class AvatarComponent {
  @Input() user: User | null
  
  constructor(
    private authService: AuthService
  ) {}

  logout(): void {
    this.authService.logout()
      .subscribe()
  }
}
