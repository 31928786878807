import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { ProductService } from '../services/product.service'
import { OrderService } from '../services/order.service'
import { mergeMap } from 'rxjs/operators'
import { CommonService } from '../services/common.service'

@Injectable({
  providedIn: 'root'
})

export class VisaProductResolverService {
  private userIP: string
  private regionCode: string
  constructor(
    private commonService: CommonService,
    private productService: ProductService,
    private orderService: OrderService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    let form = this.orderService.getCart()
    let citizenship = form && form.travelers.length == 1 && form.travelers[0].info.citizenship ? form.travelers[0].info.citizenship : 'US'
    let residency = form && form.travelers.length == 1 && form.travelers[0].info.residency ? form.travelers[0].info.residency : ''
    let country_code = route.params.country.toLowerCase()
    let product = {
      type: 'visa',
      country: country_code,
      citizenship,
      residency,
      residence_country: 'US',
      include_notes: true,
      include_requirements: true
    }

    if (residency) {
      return this.productService.getProducts(product)
    } else {
      return this.commonService.getRegionCode()
        .pipe(
          mergeMap(response => {
            product.residency = response
            return this.productService.getProducts(product)
          })
        )
    }
  }
}
