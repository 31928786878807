import { Pipe, PipeTransform } from '@angular/core'
import { CommonService } from 'src/app/services/common.service'

@Pipe({
  name: 'locationName'
})

export class LocationNamePipe implements PipeTransform {
  constructor(
    private commonService: CommonService  
  ) {}

  transform(location_code: string, type: string = 'country', html?: boolean): string {
    let location: string

    this.commonService.getLocation(location_code, type)
      .subscribe((response) => {
        location = response.label
      })

    if (html) {
      return `
        <span class="fi fi-${location_code}"></span>
        ${location}
      `
    }

    return location || location_code
  }
}
