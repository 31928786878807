export const IDP_QUESTIONS = { 
  default: [
    {
      title: "Why Carry an IDP?",
      description: `Your IDP is a valid form of identification in 150 countries worldwide and contains your name, photo and driver information. It translates your identification information into 10 languages — so it speaks the language even if you don't. Most countries highly recommend an International Driving Permit.`
    },
    {
      title: "Do I have to apply ahead of my trip?",
      description: `Another country cannot issue an IDP to be used in conjunction with a US driver’s license. An IDP accompaning a US driver’s license can only be issued in the USA.`
    },
    {
      title: "Do I need an IDP to drive abroad?",
      description: `Many countries require an IDP to drive legally. Fines for driving without an IDP can be quite costly. Check countries requirements before travel.`
    },
    {
      title: "Am I required to have an IDP in foreign countries?",
      description: `There are some countries that do not recognize a drivers license from the United States, but officially recognize an IDP (e.g. Hungary). There are other countries that honor a United States drivers license, but require a local language translation (e.g. Spain). The IDP can satisfy these types of situations. Keep in mind, however, that while a certain country’s government may not require an IDP, car rental companies located in that country, such as Hertz, may require an IDP to rent a car. Purchasing an IDP prior to departure can save a lot of potential hassles.
        <br/><br/>
        <strong>NOTE:</strong> If you are living permanently in a foreign country, even though you may still hold a U.S. state issued driver's license, <strong>you must abide by local driver's licensing laws.</strong> Many countries require that you obtain a local license once you take up employment and/or residency.`
    },
    {
      title: "If someone visits the United States from a foreign country and discovers that they need an IDP, can they purchase one from AAA?",
      description: `An IDP must be issued in the same country as the driver’s license of the traveler. Therefore, if someone from the United Kingdom has a U.K. driver’s license, their IDP must also be issued in the United Kingdom.`
    },
    {
      title: "If someone has a driver’s license from a U.S. territory such as Guam, Puerto Rico, or the U.S. Virgin Islands, how would an IDP work for them?",
      description: `Since their driver’s license was issued by a U.S. possession, their IDP would also be issued in the United States. Contact a AAA office in the United States, and the IDP can be issued and mailed.`
    },
    {
      title: "I am on active duty in the military and my U.S. license has or is due to expire. Can I apply for an IDP?",
      description: `Each state has its own provisions for extending the validity of state driving licenses for active duty military. Though valid in a home state, an expired U.S. license held by active duty military may not be valid or acceptable in a foreign country per that country’s driving requirements. See <a href="http://www.dmv.org/" target="_blank">DMV.org</a> for state information on renewals, keeping in mind that an IDP is only a translation of the license, not a license to drive. Military applicants must understand the conditions under which an IDP is valid if insisting upon issuance of a permit against an expired U.S. license that may be considered valid in their home state until discharged from active duty.
        <br/><br/>
        <strong>NOTE:</strong> If a state DMV allows renewal online or by mail for active duty military personnel stationed out of state, applicants should renew their license prior to issuance of an IDP. Some states will issue a DL236 card to be presented with an expired license to extend validity.`
    },
    {
      title: "I am traveling to South America, so which permit should I issue?",
      description: `The International Driving Permit is valid for South America, EXCEPT Brazil and Uruguay. The Inter-American Driving Permit (IADP) must be issued for those two countries.`
    }
  ]
}