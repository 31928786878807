<article>
  @if (type === 'photo') {
    <section class="text text--photo">
      <header>
        <h1><strong>Thank you</strong> for your order. What happens next?</h1>
      </header>
      <p class="gw-chkout-confirmation__p">
        Your order confirmation for <strong>{{ confirmationNumber }}</strong> {{ !external ? 'and a link to download your photo ' : '' }} has been sent to <em>{{ email }}</em> from GovWorks.
      </p>
      <img class="gw-chkout-confirmation__gov-works" src="assets/svg/govworks.svg" alt="Submission Ready"/>
      <div class="gw-chkout-confirmation__start">
        <div class="gw-chkout-confirmation__card gw-chkout-confirmation__card--small">
          <!-- <div class="gwc-confirmation__card-body"> -->
            <h3 class="gw-chkout-confirmation__heading">{{external ? 'Your Order has been sent to ' + externalSource + ' for printing.' : 'Download your photo now.'}}</h3>
            @if(external) {
              <img src="assets/svg/office-depot-confirmation.svg" />
              <p class="gw-chkout-confirmation__paragraph">The selected {{ externalSource }} location received your request and will be notified when the order is ready for pickup.</p>
            }
            @else {
              <svg>
                <use href="assets/svg/photo.svg#download"/>
              </svg>
              <p>A link to download your photo was also sent to your email address.</p>
              <div class="gw-chkout-confirmation__card-btn">
                <a mat-flat-button
                  color="primary"
                  class="gw-chkout-main-btn gw-chkout-main-btn--noicons gw-chkout-btn--rounded gw-chkout-main-btn--notransform"
                  [href]="client">
                  Download Photo
                </a>
              </div>
            }
          <!-- </div> -->
        </div>
      </div>
    </section>
} @else {
  <header>
    <h1><strong>Thank you</strong> for your order. What happens next?</h1>
    <h2>Continue your application and generate your forms.</h2>
  </header>
  <section class="text">
    <!-- <img class="gw-chkout-confirmation__gw"
    src="assets/svg/govworks.svg"/> -->
    <p class="gw-chkout-confirmation__p">Your application and forms will be securely managed through our trusted technology platform <strong>GovWorks</strong>, providing you with a fast and seamless experience.</p>
    <!-- <p>Your order confirmation <span class="confirmation-number">{{ confirmationNumber }}</span> has been sent to <em>{{ email }}</em> along with login information to manage your application process. You can always complete your application later online, or you can start immediately by clicking below.</p> -->
    <div class="gw-chkout-confirmation__start">
      <div class="gw-chkout-confirmation__card">
        <h3>Get Started Now</h3>
        <img src="assets/png/confirmation_submission_ready.png" alt="Submission Ready"/>
        <div class="gw-chkout-confirmation__card-btn">
          <a mat-flat-button
            color="primary"
            class="gw-chkout-main-btn"
            [href]="client">
            Start My Application
          </a>
        </div>
      </div>
      <div class="gw-chkout-confirmation__card">
        <h3>Start Later Via Email</h3>
        <img src="assets/png/confirmation_online_wizard.png" alt="Online Wizard"/>
        <div>
          Your order confirmation <strong>{{ confirmationNumber }}</strong> has been sent to <em>{{ email }}</em> from
          <img style="height: 24px; width: auto; display: inline-block; margin-bottom: 0; vertical-align: middle;" src="assets/svg/govworks.svg" alt="Submission Ready"/>
          .
        </div>
      </div>
    </div>
  </section>
}
<!-- <ng-template #confirmation>
  <header>
    <h1><strong>Thank you</strong> for your order. What happens next?</h1>
    <h2>Continue your application and generate your forms.</h2>
  </header>
  <section class="text">
    <img class="gw-chkout-confirmation__gw"
    src="assets/svg/govworks.svg"/>
    <p class="gw-chkout-confirmation__p">Your application and forms will be securely managed through our trusted technology platform <strong>GovWorks</strong>, providing you with a fast and seamless experience.</p>
    <p>Your order confirmation <span class="confirmation-number">{{ confirmationNumber }}</span> has been sent to <em>{{ email }}</em> along with login information to manage your application process. You can always complete your application later online, or you can start immediately by clicking below.</p>
    <div class="gw-chkout-confirmation__start">
      <div class="gw-chkout-confirmation__card">
        <h3>Get Started Now</h3>
        <img src="assets/png/confirmation_submission_ready.png" alt="Submission Ready"/>
        <div class="gw-chkout-confirmation__card-btn">
          <a mat-flat-button
            color="primary"
            class="gw-chkout-main-btn"
            [href]="client">
            Start My Application
          </a>
        </div>
      </div>
      <div class="gw-chkout-confirmation__card">
        <h3>Start Later Via Email</h3>
        <img src="assets/png/confirmation_online_wizard.png" alt="Online Wizard"/>
        <div>
          Your order confirmation <strong>{{ confirmationNumber }}</strong> has been sent to <em>{{ email }}</em> from
          <img style="height: 24px; width: auto; display: inline-block; margin-bottom: 0; vertical-align: middle;" src="assets/svg/govworks.svg" alt="Submission Ready"/>
          .
        </div>
      </div>
    </div>
  </section>
</ng-template> -->
<!-- <section class="next-steps">
<ul>
  <li>
    <div class="thumb">
      <img src="assets/png/confirmation_online_wizard.png" alt="Online Wizard"/>
    </div>
    <div class="step-text">
      <strong>Online Wizard</strong>
      <p>You’ll be guided every step of the way with our intuitive online wizard to ensure you don’t run into any issues.</p>
    </div>
  </li>
  <li>
    <div class="thumb">
      <img src="assets/png/confirmation_submission_ready.png" alt="Submission Ready"/>
    </div>
    <div class="step-text">
      <strong>Submission Ready</strong>
      <p>Once you complete the wizard, we’ll generate a completely personalized package that is submission ready.</p>
    </div>
  </li>
  <li *ngIf="shipping">
    <div class="thumb">
      <img src="assets/png/confirmation_rapid_shipping.png" alt="Rapid Shipping"/>
    </div>
    <div class="step-text">
      <strong>Rapid Shipping</strong>
      <p>Use your pre-paid shipping label to send out your completed package, and one of our processing specialists will receive it.</p>
    </div>
  </li>
  <li>
    <div class="thumb">
      <img src="assets/png/confirmation_live_tracking.png" alt="Live Tracking"/>
    </div>
    <div class="step-text">
      <strong>Live Tracking</strong>
      <p>You’ll receive live status updates from the moment you drop off your passport until the moment it is back in your hands.</p>
    </div>
  </li>
</ul>
</section> -->
</article>
