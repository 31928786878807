<gw-chkout-visa-layout [bg]="background_img">
  <form class="gw-chkout-countries__form">
    <gw-chkout-applicant-info type="search"
      [citizenship]="applicantForm.controls.citizenship"
      [residency]="applicantForm.controls.residency"
      [country]="applicantForm.controls.country">
    </gw-chkout-applicant-info>
  </form>
  <h2>
    Top Tourist Visas {{ today | date:'yyyy' }}
  </h2>
  <div class="gw-chkout-countries-top">
    @for (country of countries; track country) {
      <a [routerLink]="['/travel-visa', country]"
        class="gw-chkout-countries-top__item">
        <div class="gw-chkout-countries-top__bg gw-chkout-countries-top__bg--{{ country }}">
        </div>
        <span class="gw-chkout-countries-top__item-label"
          innerHtml="{{ country | locationName:'country':true }}">
        </span>
      </a>
    }
  </div>
</gw-chkout-visa-layout>