<gw-chkout-card class="gw-chkout-signin"
  [class.gw-chkout-signin--compact]="compact"
  titleStyle="uppercase"
  [title]="!compact ? 'RETURNING CUSTOMERS' : ''"
  [titleBordered]="!compact">
  <p [innerHtml]="signin_label"></p>
  <a mat-button
    class="gw-chkout-grey-btn"
    [href]="signinUrl"
    title="sign in">
    Sign in
  </a>
  @if (links.length > 0) {
    <div
      class="gw-chkout-signin__links"
    [ngClass]="{ 'gw-chkout-signin__links--capitalize': linksStyle == 'capitalize', 
    'gw-chkout-signin__links--uppercase': linksStyle == 'uppercase' }">
      @if (links.includes('passport')) {
        <a
          routerLink="/">
          Passport Services
        </a>
      }
      @if (links.includes('visas')) {
        <a
          routerLink="/visas">
          Travel Visa Services
        </a>
      }
    </div>
  }
</gw-chkout-card>
