import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, UntypedFormArray, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CommonService } from 'src/app/services/common.service'
import { FormService } from 'src/app/services/form.service'
import { OrderService } from 'src/app/services/order.service'
import { ProductService } from 'src/app/services/product.service'
import { FilterAttributeData } from 'src/app/types/products'
import { environment } from 'src/environments/environment'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { EnvironmentLandingPage } from 'src/app/types/environment'
import { HelperService } from 'src/app/services/helper.service'

@Component({
  selector: 'gw-chkout-home-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})

export class HeadingComponent implements OnInit {
  @Input() type: string
  
  public companyName: string = environment.source.name
  public hide_main_cta: boolean|undefined
  public heading: SafeHtml
  public subHeading: SafeHtml
  public description: SafeHtml
  public cta_label: string
  public departureDate = this.helperService.getFollowingBusinessDays()
  public link: string = '/step-1'
  public products = null
  public variation: number
  public bg: string
  public defaults = {
    passport: {
      heading: `Passports With ${this.companyName}.`,
      description: `Traveling soon and need a passport? Begin your passport application now through ${this.companyName}.`
    },
    visas: {
      heading: `Traveling soon and need a travel visa?`,
      description: `Save time and a trip to the embassy and consulate.`
    },
    restrictions: {
      heading: 'International Travel Restrictions',
      description: `COVID-19 has transformed international travel and each country has implemented unique travel 
        restrictions and entry requirements. Use the form below to learn what’s required for your travel destination.`
    },
    idp: {
      heading: 'International Driving Permit',
      description: 'Planning to rent a car and venturing out into the world on your own? Plan to obtain an International Driving Permit before you go.'
    }
  }
  public applicantForm = this.formBuilder.group({
    citizenship: ['US', Validators.required],
    residency: ['', Validators.required],
    country: ['', Validators.required],
  })

  constructor(
    private router: Router,
    private formService: FormService,
    private orderService: OrderService,
    private productService: ProductService,
    private commonService: CommonService,
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ){}

  ngOnInit() {
    this.defineHeading()
    this.getTraveler()
    this.getProducts()
  }

  private getTraveler(): void {
    let traveler = this.orderService.orderForm.controls.travelers.controls[0]

    if (!traveler) {
      this.orderService.orderForm.controls.travelers.push(this.formService.getTravelerForm())
      traveler = this.orderService.orderForm.controls.travelers.controls[0]
    }
  }

  private getProducts(): void {
    if (['passport', 'idp'].includes(this.type)) {
      let product_object: FilterAttributeData = this.type === 'passport' ? this.formService.getPassportProductRequest() : this.formService.getIDPProductRequest()
      
      this.productService.getProducts(product_object)
        .subscribe(response => {
          this.products = response
        })
    } 
  }

  private defineHeading(): void {
    let settings: EnvironmentLandingPage = environment.landing_pages?.[this.type]

    this.hide_main_cta = settings?.hide_main_cta
    this.heading = settings?.heading ? this.sanitizer.bypassSecurityTrustHtml(settings.heading) : this.defaults[this.type]?.heading
    this.description = settings?.description ? this.sanitizer.bypassSecurityTrustHtml(settings.description) : this.defaults[this.type]?.description
    this.variation = settings?.variation || 1
    this.bg = settings.bg
    this.cta_label = settings.cta_label || 'START APPLICATION'
  }

  public onStartApplication() {
    let product_uuid: string = this.type === 'idp' ? this.products[0].uuid : ''
    let option_uuid: string = this.type === 'idp' ? this.products[0].services[0].uuid : ''
    let addons: any[] = this.type === 'idp' ? this.products[0].addons.filter(item => item.required).map(item => item.uuid) : []
    let product = this.formService.getProductForm(this.type)
    let product_value = {
      type: this.type,
      product_uuid,
      option_uuid,
      subtype: this.type === 'idp' ? 'idp':'',
      country: 'US',
      addons
    }

    product.patchValue(product_value)

    let traveler = this.orderService.orderForm.controls.travelers.controls[0]
    if (traveler) {
        let products = traveler.controls.products as UntypedFormArray
        let desired = products.value.filter(product => product.type === this.type)

        if (!desired[0]) {      
          products.push(product)
        }
    }

    let residency = traveler.controls.info.controls.residency

    if (!residency.value) {
      this.commonService.getRegionCode()
        .subscribe(response => {
          residency.patchValue(response)
          this.orderService.saveCart()
          return this.router.navigate([this.link], {queryParamsHandling: 'merge'})
        })
    } else {
      this.orderService.saveCart()
      return this.router.navigate([this.link], {queryParamsHandling: 'merge'})
    }
  }
}
