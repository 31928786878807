import { Injectable } from "@angular/core"
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { ApiService } from './api.service'
import { v1 } from 'uuid'
import { HttpClient } from '@angular/common/http'
import { ExtraGetFacilityOptions } from "../models/extra-get-facility-options.model"

@Injectable()

export class LocationsService extends ApiService {
  public router_data
  public locationsSubject: Subject<any> = new Subject<any>()
  public currentLocation: Subject<any>  = new BehaviorSubject({
    location: {
      lat: 25.766206,
      lng: -80.182897
    },
    label: "Miami, FL 33131, USA"
  })

  getFacilities(lat, lng, extras?: ExtraGetFacilityOptions) {
    const data: ExtraGetFacilityOptions = {
      radius: 25,
      limit: 25,
      brand: 'fedex',
      category: ['passport-photo'],
      ...extras
    }
    return this.postRequest(`facility/near/${lat}/${lng}`, data)
  }

  getFacilitiesByZipCode(zipCode: string, extras?: ExtraGetFacilityOptions) {
    const data: ExtraGetFacilityOptions = {
      radius: 50,
      limit: 50,
      category: 'photo-print',
      ...extras
    }
    return this.postRequest(`facility/near/${zipCode}`, data)
  }

  setCurrentLocation(data) {
    return this.currentLocation.next(data)
  }
}