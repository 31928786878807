import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { OrderService } from 'src/app/services/order.service'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router'
import { MessageComponent } from 'src/app/dialogs/message/message.component'
import * as _ from 'lodash'
import { MatDialog } from '@angular/material/dialog'

@Injectable()

export class AmazonService extends ApiService {
  public amazonConfig = null
  public transaction
  public sale
  public name = 'AmazonPay'
  public status

  constructor(
    http: HttpClient,
    private orderService: OrderService,
    private dialogService: MatDialog,
    private router: Router) {
    super(http)
    this.transaction = null
    this.status = new BehaviorSubject('init')
  }

  /**
   * Initialize the component and update the shopping cart
   *
   */
  public initConfig(data): void {
    const details = this.orderService.getPurchaseUnits()

    this.amazonConfig = {
      button: 'AmazonPayButton',
      sellerId: data.sellerId,
      mode: data.mode,
      domain: data.domain,
      amount: details.amount.value,
      currencyCode: details.amount.currency_code,
      sellerNote: details.items[0].name,
      returnURL: this.router.url,
      cancelReturnURL: this.router.url,
      accessKey: data.accessKey,
      lwaClientId: data.clientId
    }
  }

  /**
   * Get the merchant session
   */
  getMerchantSession(validationURL) {
    return this.postRequest(`payment/apple/validate/merchant/${this.amazonConfig.domain}`, {
      validationURL: validationURL
    })
  }

  /**
   * Form validation
   *
   */
  public validateForm() {
    if (this.transaction !== null) {
      localStorage.setItem('cartStatus', 'submitting')
      this.orderService.createOrder(this.transaction, { merchant: 'amazon', sale: this.sale })
    } else {
      this.orderService.orderCreateSubject.next({success: false})
      this.dialogService.open(
          MessageComponent, {
            width: 'auto',
            data: {
              title: `Amazon-pay Authorization Error`,
              message: `Please make sure you clicked the Amazon Pay button and selected the payment method of your preference.<br><br>
              If you continue to experience problems, please call
              <a href="tel:800-220-1759">(800) 220-1759</a> and we’ll help
              you complete your order.`,
              icon: `warning`
            }
          }
      )
    }
  }

}
