<header class="gw-chkout-heading gw-chkout-header--{{variation}}"
  [style]="bg ? 'background-image: url(' + bg + ')' : ''">
  <h1 [innerHtml]="heading"></h1>
  <p [innerHtml]="description"></p>
  @if (!hide_main_cta && type !== 'visa') {
    <a mat-flat-button
      color="primary"
      id="btn-start-app"
      class="gw-chkout-main-btn"
      [disabled]="!products"
      (click)="onStartApplication()">
      {{ cta_label }}
    </a>
  }
  @if (variation === 3 && type === 'visa') {
    <gw-chkout-applicant-info
      type="heading"
      [citizenship]="applicantForm.controls.citizenship"
      [residency]="applicantForm.controls.residency"
      [country]="applicantForm.controls.country">
    </gw-chkout-applicant-info>
  }
  @if (subHeading) {
    <p
      [innerHtml]="subHeading"
      class="gw-chkout-heading__sub">
    </p>
  }
  <!-- <label for="btn-start-app">Your departure date must be later than: {{ departureDate | date:'MM/dd/yyyy'}}</label> -->
</header>
