<gw-chkout-card [title]="support.toUpperCase()" 
	[titleBordered]="true" 
	titleStyle="uppercase">
	<img src="assets/png/contact.png" alt="">
	<mat-list class="gw-chkout-contact">
		@if (phone) {
			<mat-list-item>
				<a href="{{ phone.uri }}" title="phone">
					<svg>
						<use href="assets/svg/contact.svg#headphone"/>
					</svg>        
					{{ phone.number }}
				</a>
			</mat-list-item>
		}

		@if (livechat) {
			<mat-list-item>
				<a href="#" title="chat" (click)="openLiveChat($event)">
					<svg>
						<use href="assets/svg/contact.svg#chat"/>
					</svg>        
					Live Chat
				</a>
			</mat-list-item>
		}

		@if (email) {
			<mat-list-item>
				<a href="mailto:{{ email }}" title="email">
					<svg>
						<use href="assets/svg/contact.svg#envelope"/>
					</svg>
					{{ email }}
				</a>
			</mat-list-item>
		}

	</mat-list>

	@if (variation === 3) {
		<section class="gw-chkout-contact__track">
			<a mat-button 
				class="gw-chkout-grey-btn gw-chkout-contact__track-btn"
				[href]="signinUrl" 
				title="sign in">
				Track Your Order
			</a>
		</section>
	}
</gw-chkout-card>
