import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, output } from '@angular/core'
import { CommonService } from 'src/app/services/common.service'
import { map } from 'rxjs/operators'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { Location } from 'src/app/types/products'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { LowerCasePipe } from '@angular/common'
import { MatInputModule } from '@angular/material/input'

@Component({
  selector: 'gw-chkout-location-select',
  templateUrl: './location.select.component.html',
  styleUrls: ['./location.select.component.scss'],
  standalone: true,
  imports: [
    LowerCasePipe,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class LocationSelectComponent implements OnInit {
  @ViewChild('filter') filterInput: ElementRef<HTMLInputElement>

  @Input() field: FormControl
  @Input() label: string
  @Input() type: string
  @Input() appearance: string = 'fill'
  onSelect = output()

  public list: Location[] = []
  public filteredList: Location[]

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getList()
  }

  private getList(): void {
    let request = this.commonService.getCountries()

    if (this.label === 'Country') {
      request = request.pipe(
        map(countries => {
          return countries.filter(country => country.value !== 'US')
        })
      )
    }

    if (this.type == 'state') {
      request = this.commonService.getStates()
    }

    request.subscribe((response) => {
      this.list = response
      this.filteredList = this.list
    })
  }

  public displayFn(value: string): string {
    let filtered = this.list.filter(item => item.value === value)

    return filtered[0]?.label || '' 
  }

  public filterItems(): void {
    const filterValue = this.filterInput.nativeElement.value.toLowerCase()

    if (!filterValue) {
      this.filteredList = this.list
    } else {
      let startList = []
      let middleList = []

      this.list.forEach(option => {
        if (option.label.toLowerCase().startsWith(filterValue)) {
          startList.push(option)
        } else if (option.label.toLowerCase().includes(filterValue)) {
          middleList.push(option)
        }
      })

      this.filteredList = [...startList, ...middleList]
    }
  }
}
