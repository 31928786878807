import { NgModule } from "@angular/core"
import { TrustpilotComponent } from "./trustpilot.component"

@NgModule({
  declarations: [
    TrustpilotComponent
  ],
  exports: [
    TrustpilotComponent
  ]
})

export class TrustpilotModule {}
