export const IDP_SLIDES = {
  idp: [
    {
      title: "Place your order",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Provide applicant's information and return shipping addres.",
        "Costs include IDP fee, processing feee, and photo service."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "After placing your order, you will be directed to create a govWorks account to complete your IDP application.",
        "The easy-to-use wizard will generate the necessary documentation to process your application.",
        "Every IDP application requires a government-compliant photo be submitted with your application. Take the perfect passport photo every time using your govWorks account."

      ]
    },
    {
      title: "Take Your Photo",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "e-Sign your application through the govWorks account.",
        "No shipping required."
      ]
    }
  ],
  default: [
    {
      title: "Select Service Type",
      icon: {
        name: "gw-slider-mouse",
        width: "45px",
        height: "88px"
      },
      elements: [
        "Select the type of passport service you need, and indicate how quickly you need it.",
        "Expedited processing speeds range from same day service to 8-10 business days.",
        "Expediting cost does not include shipping fees or government fees, which are paid separately to the U.S. Department of State (starting at $190 for adults)."
      ]
    },
    {
      title: "Complete Online Wizard",
      icon: {
        name: "gw-slider-papers",
        width: "69px",
        height: "92px"
      },
      elements: [
        "After selecting expediting options, you will be directed to create a govWorks account to complete your passport forms.",
        "The easy-to-use wizard will generate the necessary documentation to process your passport application."
      ]
    },
    {
      title: "Get Your Passport Photo",
      icon: {
        name: "gw-slider-user",
        width: "97px",
        height: "97px"
      },
      elements: [
        "Every passport application requires a printed government-compliant photo be submitted with your application.",
        "Take the perfect passport photo every time and print nationwide.",
        "<a href='https://www.passportphotocreator.com/' target='_blank'>Download the Passport Photo Creator app today.</a>"
      ]
    },
    {
      title: "Gather Documents and Ship Them via FedEx",
      icon: {
        name: "gw-slider-fedex",
        width: "109px",
        height: "64px"
      },
      elements: [
        "Review your checklist to ensure you have all required documentation (birth certification, passport photos, letter of authorization, expired passport).",
        "Use the FedEx shipping label to ship your application to Rush My Passport, where your documents are hand-carried to the U.S. Department of State."
      ]
    }
  ]
}
