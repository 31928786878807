import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { environment } from 'src/environments/environment'

@Injectable()

export class AppleService extends ApiService {
	public name

	public startApplePaySession() {
		return this.postRequest(`payment/apple/start/session`, {source: environment.source.domain})
	}

	public initConfig(config) {
		this.name = config.credentials.displayName || 'RushMyPassport'
		return ApplePaySession.canMakePayments()
	}
}
