import { UntypedFormGroup } from '@angular/forms'
import { DateTime } from 'luxon'

export function validateDate(min, max) {
    return (control: UntypedFormGroup) => {
      const minimumDate = DateTime.fromFormat(min, 'yyyy-MM-dd')
      const maximumDate = DateTime.fromFormat(max, 'yyyy-MM-dd')

      if (control.value == '') {
        return {}
      }

      const controlValueDate = DateTime.fromFormat(control.value, 'MM/dd/yyyy')

      if (!controlValueDate.isValid || control.value.length < 10) {
        return { invalid: true }
      }

      if (controlValueDate > maximumDate) {
        return { 'future': true }
      } else if (controlValueDate < minimumDate) {
        return { 'past': true }
      }

      return null
    }
}