import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'gwc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent {
  @Input() label: string
  @Input() disabled: boolean
  @Input() type: string
  @Input() color: string
  @Input() spinner: boolean
  @Input() fullwidth: boolean
  @Input() icon: string
  @Input() class_list: string
  @Output() callback = new EventEmitter()

  public emitCallback(): void {
    if (this.callback) {
      this.callback.emit()
    }
  }
}
