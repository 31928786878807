import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { HomeComponent } from './home.component'
import { SliderComponent } from './slider/slider.component'
import { QuestionsComponent } from './questions/questions.component'
import { ContactComponent } from './contact/contact.component'
import { MatIconModule } from '@angular/material/icon'
import { MatExpansionModule } from '@angular/material/expansion'
import { RouterModule } from '@angular/router'
import { LocationValidator } from 'src/app/validators/location.validator'
import { CardModule } from 'src/app/components/card/card.module'
import { SigninModule } from 'src/app/components/signin/signin.module'
import { HeadingModule } from 'src/app/components/heading/heading.module'
import { MatButtonModule } from '@angular/material/button'
import { MatListModule } from '@angular/material/list'
import { CommonModule } from '@angular/common'
import { WarningModule } from 'src/app/components/warning/warning.module'
import { ApplicantInfoComponent } from 'src/app/components/applicant.info/applicant.info.component'

@NgModule({
  declarations: [
    HomeComponent,
    SliderComponent,
    QuestionsComponent
  ],
  imports: [
    ApplicantInfoComponent,
    ContactComponent,
    CommonModule,
    CardModule,
    HeadingModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    SigninModule,
    WarningModule
  ],
  providers: [
    LocationValidator
  ], 
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class HomeModule {}
