<div class="gw-chkout-main gw-chkout-ship">
  <h3 class="gw-chkout-label">
    {{ shipping_type === 'basic' ? '' : 'Shipping' }} Address
  </h3>
  <p>
    @if (shipping_type === 'basic') {
      Please keep in mind P.O. Boxes cannot be used.
    } @else {
      @if (idp) {
        Please provide shipping address for your new International Driving Permit.
      } @else {
        Your application is eligible to be mailed in for processing. Please enter your address to see the rates.
      }
    }
  </p>
  @if (mapURL) {
    <div class="gw-chkout-ship__map">
      <iframe [src]="mapURL"
        frameBorder="0"></iframe>
      <span class="preloader"></span>
    </div>
  }
  <form [formGroup]="shipping" #shippingForm="ngForm">
    <gwc-google-address [addressControl]="shipping"
      type="shipping"
      (updateMap)="setMapURL($event)"
      (addressUpdated)="addressUpdated()">
    </gwc-google-address>

    @if (idp) {
      <h3 class="gw-chkout-label">
        Driver's License
      </h3>
      <p>
        Please provide the zip code indicated on your driver's license to identify the processing location.
      </p>
      <mat-form-field class="gw-chkout-field gw-chkout-field--half"
        [hideRequiredMarker]="true">
        <mat-label>
          Zip code
        </mat-label>
        <input matInput
          name="dl_zip_code"
          pattern="^\d{5}$"
          [formControl]="idp_zip">
        <mat-error>Please provide the applicant's first name.</mat-error>
      </mat-form-field>
    }

    @if (show_shipping) {
      <div class="gw-chkout-ship__options">
        <h3 class="gw-chkout-label">Shipping Options</h3>
        @if (fedex_logo) {
          <img class="gw-chkout-ship__options-logo"
            src="assets/svg/fedex.svg"
            alt="Fedex">
        }
      </div>

      @if (!shipping.value.address.address_1 || (shipping_type === 'dynamic' && !ready_for_dynamic)) {
        <p>
          Options will appear once an address is entered.
          <span class="gw-chkout-ship__arrow">
            ↳
          </span>
        </p>
      } @else if (shipping_type === 'basic') {
        <div [class.gw-chkout-ship__select--hidden]="!shipping.value.address.address_1 && !shipping_address">
          @if (show_inbound) {
            <gwc-shipping-options [options]="shipping_options"
              description="Please select the inbound shipping option that works for you."
              [field]="shipping.controls.inbound_speed"
              [submitted]="submitted">
            </gwc-shipping-options>
          }
          <gwc-shipping-options [options]="shipping_options"
            description="Please select the outbound shipping option that works for you."
            [field]="shipping.controls.speed"
            [submitted]="submitted">
          </gwc-shipping-options>
        </div>
      } @else if (shipping_type === 'dynamic') {
        @if (loading_dynamic_options === true) {
          <mat-spinner class="gwc-shipping__loader"
            [diameter]="54"
            color="primary"></mat-spinner>
        } @else {
          <gwc-shipping-options [options]="dynamic_options"
            description="Please select the shipping option that works for you."
            [field]="idp ? shipping.controls.speed : shipping.controls.inbound_speed"
            [submitted]="submitted"
            (shippingOptionSelected)="dynamicShippingOptionSelected($event, idp ? 'speed_price' : 'inbound_price')">
          </gwc-shipping-options>
        }
      }
    } 
  </form>
  @if (domain === 'rmp_ca') {
    <div class="gwc-shipping__separator">
      Or
    </div>
    <button mat-button
      (click)="onSubmitInPerson()"
      color="primary"
      class="gw-chkout-main-btn gwc-shipping__skip">
      Submit In-Person
    </button>
  }
</div>
