<div class="gw-chkout-message">
  <!-- <svg>
    <use attr.xlink:href="assets/svg/{{ data.icon }}.svg"></use>
  </svg> -->
  <img alt="Message"
    src="assets/svg/{{ data.icon }}.svg"/>
  <h3 class="gw-chkout-message__title">
    {{ data.title }}
  </h3>
  <div [innerHtml]="data.message"></div>
</div>
