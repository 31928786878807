import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable, of, Subject } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})

export class AuthService extends ApiService {
  public userSubject: Subject<any> = new Subject<any>()
  private user

  checkIfUserExists(data): Observable<any> {
    return this.postRequest('user/exists', data)
  }

  login(data) {
    return this.postRequest('auth/login', data)
      .pipe(
        map((response) => {
          if (response.data) {
            this.user = response.data
          } else {
            this.user = null
          }
          
          this.userSubject.next(this.user)

          return response
        })
      )
  }

  logout() {
    return this.postRequest('auth/logout', {})      
      .pipe(
        map((response) => {
          this.user = null
          this.userSubject.next(this.user)

          return response
        })
      )
  }

  check() {
    return this.getRequest('check') 
      .pipe(
        map((response) => {
          if (response.uuid) {
            this.user = response
          } else {
            this.user = null
          }

          this.userSubject.next(this.user)

          return response
        })
      )
  }

  getUser() {
    if (this.user) return of(this.user)

    return this.check() 
      .pipe(
        map((response) => {
          if (response.uuid) {
            this.user = response
          } else {
            this.user = null
          }
          this.userSubject.next(this.user)

          return this.user
        })
      )
  }

  public requestLoginCode(email: string) {
    return this.postRequest(`auth/password/access_code`, {email})
  }

  public checkAARPMembership(code: string) {
    return this.postRequest(`oauth/aarp/getUser`, {code})
  }
}
