import { Component, Inject } from '@angular/core'
import { Validators, FormBuilder } from '@angular/forms'
import { AuthService } from 'src/app/services/auth.service'
import { OrderService } from 'src/app/services/order.service'
import { environment } from 'src/environments/environment'
import { animate, style, transition, trigger } from '@angular/animations'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'gw-chkout-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ])
    ])
  ]
})

export class LoginComponent {
  public authForm = this.formBuilder.group({
    email: [this.data.email || '', Validators.required],
    password: ['', Validators.required],
  })
  public error: boolean = false
  public submitting: boolean = false
  public logo: string = environment.login.logo
  public mode: string = 'password'
  public code_requested: boolean = false
  public initialLoad: boolean = true

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    private orderService: OrderService,
    private formBuilder: FormBuilder
  ) {}

  public login(): void {
    if (this.authForm.valid) {
      this.submitting = true

      let loginSubscription = this.authService.login(this.authForm.value)
        .subscribe((response) => {
          loginSubscription.unsubscribe()
          this.submitting = false
          if (response.error) {
            this.error = true
          } else {
            this.orderService.user = response.data
            this.orderService.userForm.controls.email.patchValue(response.data.email)
            if (environment.source.domain !== 'rushmyphoto') {
              this.orderService.createHotLead()
              this.orderService.setFormValidity(true)
            }
            this.dialogRef.close()
          }
        })
    }
  }

  public changeMode(mode: string) {
    this.initialLoad = false
    this.mode = mode
  }

  public requestCode() {
    this.submitting = true
    this.authService.requestLoginCode(this.authForm.getRawValue().email)
      .subscribe(response => {
        this.code_requested = true
        this.submitting = false
      })
  }
}
