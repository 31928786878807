@if (type === 'passport' && !hide_banner) {
  <gw-chkout-warning>
  </gw-chkout-warning>
}

<article class="gw-checkout-container">
  <div class="gwc-home-row gwc-home-row--top">
    <div class="gwc-home-row__left gwc-home-row__left--{{variation}} {{ variation < 3 ? 'gwc-home-row__left--65':'' }}">
      <gw-chkout-home-heading [type]="type">
      </gw-chkout-home-heading>
    </div>
    @if (variation !== 3) {
      <div class="gwc-home-row__right gw-chkout__signin-right">
        <gw-chkout-home-signin [type]="type"> 
        </gw-chkout-home-signin>
      </div>
    }
  </div>
  @if (['aarp', 'sandals'].includes(domain) && type === 'passport') {
    <gw-chkout-card [compact]="true"
      [space]="true">
      <div class="gw-chkout-extra">
        <div class="gw-chkout-extra__img">
          <img [src]="domain === 'aarp' ? 'assets/png/passport.png' : 'assets/png/passport_sand.jpeg'">
        </div>
        @if (domain === 'aarp') {
          <div>
            <h4>
              Passport Renewal Bundles for AARP Members Include:
            </h4>
            <ul>
              <li>
                A user-friendly online experience 
              </li>
              <li>
                All government and shipping fees
              </li>
              <li>
                Document pre-check for accuracy
              </li>
              <li>
                Concierge-level assistance and dedicated support
              </li>
            </ul>
            <div class="gw-chkout-home__btns">
              <a mat-flat-button
                color="primary" 
                class="gw-chkout-main-btn"
                [href]="'/step-1?product=passport-renewal'">
                Passport Renewal Bundles
              </a>
              <a mat-flat-button 
                color="primary"
                class="gw-chkout-main-btn gw-chkout-main-btn--flat gw-chkout-main-btn--light"
                [href]="'/step-1?&product=new-passport'">
                Other Passport Services
              </a>
            </div>
          </div>
        } @else if (domain === 'sandals') {
          <div>
            <h4>
              Let us help you get to the Caribbean with our expedited services:
            </h4>
            <ul>
              <li>
                New passports, renewals, name change, lost, stolen, damaged, second, and child passports
              </li>
              <li>
                A user-friendly online application experience
              </li>
              <li>
                Document pre-check services for added peace-of-mind
              </li>
              <li>
                Fast application processing and real-time tracking
              </li>
              <li>
                Dedicated customer support via phone, email, or chat
              </li>
            </ul>
          </div>
        }

      </div>
    </gw-chkout-card>
  }
  @if (variation !== 3) {
    @if (type === 'visa') {
      <gw-chkout-card [space]="true"
        class="gw-chkout-home__card--space">
        <gw-chkout-applicant-info type="home" 
          title="Search Visas"
          [citizenship]="applicantForm.controls.citizenship"
          [residency]="applicantForm.controls.residency"
          [country]="applicantForm.controls.country">
        </gw-chkout-applicant-info>
      </gw-chkout-card>
    }

    <div class="gw-chkout__signin-btm">
      <gw-chkout-home-signin [type]="type"> 
      </gw-chkout-home-signin>
    </div>
  }

  <section class="gwc-home__section">
    @if (variation === 3) {
      <h3>
        How It Works
      </h3>
    }

    <gw-chkout-home-slider [type]="type"></gw-chkout-home-slider>
  </section>


  <section class="gwc-home__section">
    @if (variation === 3) {
      <h3>
        Common Questions
      </h3>
    }

    <div class="gwc-home-row">
      <div class="gwc-home-row__left">
        <gw-chkout-home-questions [type]="type">
        </gw-chkout-home-questions>
      </div>
      @if (hasContactSupport || ['fedex', 'aaa'].includes(domain)) {
        <div class="gwc-home-row__right">
          @if (hasContactSupport) {
            <gw-chkout-home-contact [variation]="variation">
            </gw-chkout-home-contact>
          }

          <!-- TrustBox widget - Slider -->
          @if (['fedex', 'aaa'].includes(domain)) {
            <div class="trustpilot-widget gw-chkout-home__trust" 
              data-locale="en-US" 
              data-template-id="54ad5defc6454f065c28af8b" 
              data-businessunit-id="4f22c5a500006400051282dd" 
              data-style-height="240px" 
              data-style-width="100%" 
              data-theme="light" 
              [attr.data-tags]="domain === 'fedex' ? 'FedEx' : 'AAA'" 
              data-stars="5" 
              data-review-languages="en">
              <a href="https://www.trustpilot.com/review/rushmypassport.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>
          }
          <!-- End TrustBox widget -->
        </div>
      }

    </div>
  </section>
</article>