import { Component } from '@angular/core'
import { ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core'
import { MatCalendar } from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Component({
    selector: 'datepicker-header',
    templateUrl: './datepicker-header.component.html',
    styleUrls: ['./datepicker-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  
  export class DatepickerHeader<D> implements OnDestroy {
    private destroyed = new Subject<void>()
  
    constructor(
        private calendar: MatCalendar<D>, private dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
      calendar.stateChanges
          .pipe(takeUntil(this.destroyed))
          .subscribe(() => cdr.markForCheck())
    }
  
    ngOnDestroy() {
      this.destroyed.next()
      this.destroyed.complete()
    }
  
    get periodLabel() {
      return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel)
    }
  
    previousClicked(mode: 'month' | 'year') {
      this.calendar.activeDate = mode === 'month' ?
          this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
          this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1)
    }
  
    nextClicked(mode: 'month' | 'year') {
      this.calendar.activeDate = mode === 'month' ?
          this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
          this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1)
    }
  }
  