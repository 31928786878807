@if (links && links[type]) {
  <a href="{{ links[type] }}"
    target="_blank"
    [innerHTML]="labels[type]">
  </a> 
} @else {
  <a (click)="openDialog(type)"
    [innerHTML]="labels[type]">
  </a> 
}
