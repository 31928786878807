import { Component } from '@angular/core'
import { environment } from 'src/environments/environment'
import { OrderService, PhoneObject } from 'src/app/services/order.service'
import { MatDialog } from '@angular/material/dialog'
import { TermsDialogComponent } from 'src/app/dialogs/terms/terms.component'
import { DateTime } from 'luxon'

@Component({
  selector: 'gw-chkout-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {  
  public seals: any = environment.seals
  public phone: PhoneObject = this.orderService.phone
  public footer = environment.footer
  public links = environment.legal_links
  public year: string = DateTime.now().toFormat('yyyy')
  public disclaimer: string|undefined = environment.footer.footer_disclaimer
  public labels = {
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    refund: 'Refund Policy'
  }

  constructor(
    private dialogService: MatDialog,
    private orderService: OrderService
  ) {}

  public openDialog(type: string) {
    this.dialogService.open(TermsDialogComponent,{
      closeOnNavigation: true,
      data: {
        type
      }
    })
  }
}
