import { NgModule } from '@angular/core'
import { Routes, RouterModule, UrlSegment } from '@angular/router'
import { environment } from 'src/environments/environment'
import { ConfirmationComponent } from './containers/confirmation/confirmation.component'
import { HomeGuard } from './guards/home.guard'
import { HomeComponent } from './containers/home/home.component'
import { CountriesComponent } from './containers/countries/countries.component'
import { CountriesResolverService } from './resolvers/countries.resolver'
import { StatesResolverService } from './resolvers/states.resolver'
import { TermsComponent } from './containers/terms/terms.component'
import { AgentGuard } from './guards/agent.guard'
import { CountryComponent } from './containers/country/country.component'
import { VisaProductResolverService } from './resolvers/visa.product.resolver'
import { CountryMetaResolverService } from './resolvers/country.meta.resolver'

const routes: Routes = [
  {
    path: 'step-5',
    component: ConfirmationComponent,
    data: {
      title: 'Step 5 - Order Confirmation'    
    }
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [
      HomeGuard
    ],
    data: {
      title: environment.landing_pages[environment.landing_pages.root_type || 'passport']?.title,
      type: environment.landing_pages.root_type || 'passport'
    }
  },
  {
    path: 'international-driving-permit',
    component: HomeComponent,
    canActivate: [
      HomeGuard
    ],
    data: {
      title:`${environment.source.name} | International Driving Permit`,
      type: 'idp'
    }
  },
  {
    matcher: visaPath,
    component: HomeComponent,
    resolve: {
      countries: CountriesResolverService,
      states: StatesResolverService
    },
    canActivate: [
      HomeGuard
    ],
    data: {
      title: `${environment.source.name} | Visa Expediting`,
      type: 'visa'
    }
  },
  {
    path: 'locations',
    loadChildren: () => import('./containers/locations/locations.module').then(m => m.LocationsModule),
    data: {
      title: environment.locations_page ? environment.locations_page.title : 'Locations'
    }
  },
  {
    path: 'travel-visa/:country',
    component: CountryComponent,
    resolve: {
      countries: CountriesResolverService,
      states: StatesResolverService,
      visas: VisaProductResolverService,
      country: CountryMetaResolverService
    },
    data: {
      title: 'Travel Visa'
    }
  },
  {
    matcher: travelVisaPath,
    component: CountriesComponent,
    resolve: {
      countries: CountriesResolverService,
      states: StatesResolverService
    },
    data: {
      title: 'Travel Visa'
    }
  },
  {
    path: 'terms-of-use',
    component: TermsComponent,
    data: {
      type: 'terms',
      title: 'Terms of Use'
    }
  },
  {
    path: 'privacy-policy',
    component: TermsComponent,
    data: {
      type: 'privacy',
      title: 'Privacy Policy'
    }
  },
  {
    path: 'refund-policy',
    component: TermsComponent,
    data: {
      type: 'refund',
      title: 'Refund Policy'
    }
  },
  {
    path: 'photo',
    loadChildren: () => import('./containers/photo/photo.routes').then(r => r.PHOTO_ROUTES)
  },
  {
    path: 'agent',
    loadChildren: () => import('./containers/aaa.agent.signup/aaa.agent.signup.module').then(m => m.AaaAgentSignupModule),
    canActivate: [
      AgentGuard
    ],
    data: {
      fullScreen: true
    }
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }

function visaPath(url: UrlSegment[]) {
  if (url.length == 1) {
    const path = url[0].path

     if (path.startsWith('visa')) {
      return { consumed: url }
    }
  }

  return null
}

function travelVisaPath(url: UrlSegment[]) {
  if (url.length == 1) {
    const path = url[0].path

     if (path.startsWith('travel-visa') ||
      path.startsWith('travelvisa')) {
      return { consumed: url }
    }
  }

  return null
}
