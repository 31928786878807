import { NgModule } from '@angular/core'
import { SoldoutPipe } from './soldout.pipe'

@NgModule({
  declarations: [
    SoldoutPipe
  ],
  exports: [
    SoldoutPipe
  ]
})

export class SoldoutPipeModule { }
