import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { CountriesComponent } from './countries.component'
import { VisaLayoutModule } from 'src/app/components/visa.layout/visa.layout.module'
import { RouterModule } from '@angular/router'
import { LocationNamePipeModule } from 'src/app/pipes/location.name/location.name.module'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { ApplicantInfoComponent } from 'src/app/components/applicant.info/applicant.info.component';

@NgModule({
  declarations: [
    CountriesComponent
  ],
  imports: [
    ApplicantInfoComponent,
    CommonModule,
    FormsModule,
    LocationNamePipeModule,
    ReactiveFormsModule,
    RouterModule,
    VisaLayoutModule
  ]
})

export class CountriesModule { }
