import { FormGroup, Validators, AsyncValidator, AbstractControl } from '@angular/forms'
import { CommonService } from 'src/app/services/common.service'
import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})

export class LocationValidator implements AsyncValidator {
  constructor(
    private commonService: CommonService  
  ) {}

  validate(control: AbstractControl) {
    return this.commonService.getLocation(control.value, 'state')
      .pipe(
        map((response) => {
          console.log('Validator response: ', response)
          return response ? null : { invalid: true }
        }))
  }
}
