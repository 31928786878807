import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { VisaOptionComponent } from './visa.option.component'

@NgModule({
  declarations: [
    VisaOptionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    VisaOptionComponent
  ]
})

export class VisaOptionModule {}
