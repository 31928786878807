import { NgModule } from '@angular/core'
import { LoginComponent } from './login/login.component'
import { MessageComponent } from './message/message.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ButtonModule } from '../components/button/button.module'

@NgModule ({
  declarations: [
    LoginComponent,
    MessageComponent
  ],
  imports: [
    ButtonModule,
    MatInputModule, 
    MatButtonModule,
    MatDialogModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatProgressSpinnerModule
  ]
})

export class DialogsModule {}