<button mat-flat-button
  class="gwc-button gw-chkout-main-btn gw-chkout-main-btn--notransform {{class_list}}"
  (click)="emitCallback()"
  [color]="color"
  [disabled]="disabled"
  [type]="type"
  [class.gwc-button--loading]="spinner"
  [class.gwc-button--fullwidth]="fullwidth">
  <mat-spinner class="gwc-button__spinner" diameter="20" [hidden]="!spinner"></mat-spinner>
  <span [hidden]="spinner">
    @if (icon) {
      <img
        src="assets/svg/{{icon}}.svg"
        alt="Icon" />
    }
    {{ label }}
  </span>
</button>
