import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder, Validators } from '@angular/forms'
import { TagService } from 'src/app/services/tag.service'

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})

export class CountriesComponent implements OnInit {
  public background_img = 'https://cdn.travelvisa.com/video/hero/img-travelvisa-hero-poster-3.jpg'
  public countries = ['cn', 'tz', 'in', 'tr', 'sa', 'ke']
  public applicantForm = this.formBuilder.group({
    citizenship: ['US', Validators.required],
    residency: ['', Validators.required],
    country: ['', Validators.required],
  })
  public today = new Date()

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tagService: TagService
  ) { }

  ngOnInit(): void {
    this.tagService.utagPageView('travel:expedited travel:visa:search')
  }
}