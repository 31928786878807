import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ComponentsModule } from './components/components.module'
import { FunnelModule } from './containers/funnel/funnel.module'
import { DatepickerHeader } from './containers/funnel/datepicker-header/datepicker-header.component'
import { HttpClientModule } from '@angular/common/http'
import { ServiceModule } from './services/service.module'
import { DialogsModule } from './dialogs/dialogs.module'
import { HomeModule } from './containers/home/home.module'
import { ConfirmationModule } from './containers/confirmation/confirmation.module'
import { CountriesModule } from './containers/countries/countries.module'
import { TravelRestrictionsModule } from './containers/travel.restrictions/travel.restrictions.module'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatRadioModule } from '@angular/material/radio'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { environment } from 'src/environments/environment'
import { LiveChatWidgetModule } from "@livechat/widget-angular"
import { FooterComponent } from './components/footer/footer.component'

// configure Bugsnag ASAP
if (environment.production) {
  Bugsnag.start({ apiKey: '24fa9b2ec51688c98170fb9ef768badc', appVersion: '1.0.3' })
  BugsnagPerformance.start({ apiKey: '24fa9b2ec51688c98170fb9ef768badc' })
}

const providers = environment.production ? [ { provide: ErrorHandler, useFactory: errorHandlerFactory } ] : []

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent,
    DatepickerHeader
  ],
  imports: [
    BrowserModule,
    FooterComponent,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ComponentsModule,
    DialogsModule,
    ServiceModule,
    CountriesModule,
    HomeModule,
    ConfirmationModule,
    TravelRestrictionsModule,
    AppRoutingModule,
    FunnelModule,
    LiveChatWidgetModule
  ],
  bootstrap: [AppComponent],
  providers: providers
})

export class AppModule { }
