<div class="gw-amazon">
  @if (wallet.innerHTML.length == 0) {
    <div
      id="AmazonPayButton"
      #AmazonPayButton
      @slideIn
      class="gw-amazon__buttom">
    </div>
  }
  <div #wallet
    [hidden] = "wallet.innerHTML.length == 0"
    id="walletWidgetDiv"
    class="gw-amazon__wallet">
  </div>
</div>
