import { CurrencyPipe, NgClass } from '@angular/common'
import { Component, Input } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialog } from '@angular/material/dialog'
import { TermsDialogComponent } from 'src/app/dialogs/terms/terms.component'
import { environment } from 'src/environments/environment'
import { LegalLinkComponent } from '../legal.link/legal.link.component'

@Component({
  selector: 'gwc-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    NgClass,
    CurrencyPipe,
    LegalLinkComponent
  ]
})

export class DisclaimerComponent {
  @Input() ccForm: FormGroup
  @Input() total: number
  @Input() submitted: boolean
  @Input() isPickupOptSelected: boolean
  public isRMPhotoEnv = environment?.source?.type === 'photo'

  public links = environment.legal_links
  public currency: string = environment.source.currency || 'USD'

  constructor(
    private dialogService: MatDialog
  ) {}

  public openDialog(type: string) {
    this.dialogService.open(TermsDialogComponent,{
      closeOnNavigation: true,
      data: {
        type
      }
    })
  }
}
