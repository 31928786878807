import { Injectable } from '@angular/core'
import { Observable, from, of } from 'rxjs'
import { ApiService } from './api.service'
import { catchError, map, } from 'rxjs/operators'
import { Location } from '../types/products'
import { mergeMap } from 'rxjs/operators'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()

export class CommonService extends ApiService {
  public regionCode
  public router_data
  public countries
  public states

  getCountry(code: string): Location {
    if (!this.countries) return null

    let filtered = this.countries.filter((country: Location) => country.value === code)
    return filtered[0] || null
  }

  getCountries():Observable<Location[]> {
    if (this.countries) {
      return from([this.countries])
    }

    return this.getRequest('populate/countries')
      .pipe(
        map(response => {
          this.countries = response

          return response
        })
      )
  }

  getState(code: string): Location {
    if (!this.states) return null

    let filtered = this.states.filter((state: Location) => state.value === code)
    return filtered[0] || null
  }

  getStates():Observable<Location[]> {
    if (this.states) {
      return from([this.states])
    }

    return this.getRequest('populate/states')
      .pipe(
        map(response => {
          this.states = response

          return response
        })
      )
  }

  getLocation(code: string, type?: string):Observable<Location> {
    let source: Observable<Location[]>

    if (type == 'state') {
      source = this.getStates()
    } else {
      source = this.getCountries()
    }

    return source.pipe(
      map((response) => {
        let filter = response.filter((country) => {
          return country.value.toLowerCase() == code.toLowerCase()
        })

        return filter[0]
      })
    )
  }

  getCountryMeta(country_code: string) {
    return this.getRequest(`meta/country/${country_code}`)
  }

  getCountryRestrictions(country_code: string) {
    return this.getRequest(`report/travel-restrictions/${country_code}`)
  }

  // fetch ip/location aka region code
  getRegionCode() {
    if (this.regionCode) {
      return from([this.regionCode])
    }

    return this.getRequestExternal('https://ipv4.jsonip.com')
      .pipe(
        mergeMap((data) => { 
          return this.getRequest(`location/data/${data.ip}`)
            .pipe(
              map(response => {
                this.regionCode = response.region_code === 'CA' ? 'CA-S': response.region_code
                
                return this.regionCode
              })
            )
        }),
        catchError((error: HttpErrorResponse) => {
          return of('FL')
        })
      )
  }
}
