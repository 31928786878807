import { Component, Input } from '@angular/core'

@Component({
  selector: 'gw-chkout-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})

export class StepsComponent {
  @Input() steps: string[]
  @Input() step: number
}
