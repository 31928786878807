import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ButtonComponent } from './button.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [
    ButtonComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ButtonComponent
  ]
})

export class ButtonModule {}
