import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})

export class WarningComponent implements OnInit {
  ngOnInit() {

  }
}
