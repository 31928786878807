import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class TrackingService {
  getTracking(key) {
    let jsonData
    let storedData = this.getCookie(key)
    let currentTime = new Date()
    let expireTime

    if (!storedData) {
      return storedData
    }

    try {
      jsonData = JSON.parse(storedData)
    } catch (err) {
      // Data may not be JSON formatted, return raw Data
      return storedData
    }

    expireTime = new Date(jsonData.expire)

    if (currentTime.getTime() > expireTime.getTime()) {
      // Data is expired, return null
      return null
    }

    if ('data' in jsonData) {
      return jsonData.data
    }
    return null
  }

  getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }
    
    return ""
  }

  getLinkShare() {
    let ls = this.getCookie('gw_ls')
    let entrytime = this.getCookie('gw_ls_entrytime')
    let siteid = this.getCookie('gw_ls_siteid')

    if (!entrytime || !siteid) {
      return null
    }

    return {ls, entrytime, siteid}
  }
}
