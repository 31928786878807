export const VISA_QUESTIONS = {
  default: [
    {
      title: `What is a Travel Visa?`,
      description: `A travel visa gives permission for a non-citizen to enter a particular country for a limited duration. Visas often come in the form of a sticker or stamp affixed to Visa (blank) pages within your passport. A travel visa is issued by the Consulate or Embassy of the country you wish to travel. Requirements and application procedures vary per country. It is your responsibility to determine whether or not a travel visa is required for your upcoming trip. To see the travel visa requirements for a specific destination go to our resource section.`
    },
    {
      title: `What are the benefits of using our service?`,
      description: `<strong>UP-TO-DATE REQUIREMENTS</strong> - The process of obtaining a Visa can be very challenging and time consuming due to the frequent change in requirements, forms and in-person appointments. Consulates/Embassies allow you to process your visa directly with them, but the time frame for completion is slower and any paperwork errors can cause further delay.<br/><br/>
        <strong>HELP WITH FORMS</strong> - A Travel Visa service allows you to complete the required paperwork from home while reviewing the documents with an experienced Visa Specialist.<br/><br/>
        <strong>SAVE TIME</strong> - Our registered couriers have a working relationship with the government office to submit your paperwork directly for processing and in order to return to you via overnight delivery. We also assist with correcting any unexpected problems the same day.<br/><br/>
        <strong>FASTER PROCESSING</strong> - Many countries do not offer a rush processing service, but will allow a third party courier to expedite for an additional fee. `
    },
    // {
    //   title: `Does my country need a Visa?`,
    //   description: `There are also additional countries which require Non-US Citizens residing in the United States with a Green Card or Long Term Visa to obtain a visa before traveling.`
    // },
    {
      title: `Can I get a Visa upon arrival into the country I am traveling to?`,
      description: `There are a few countries that allow you to obtain your visa upon arrival. These rules change often so it’s best to check for the latest information. Expect that during the busy travel season this process can take some time, possibly a few hours at the airport. Some countries, which allow you to obtain a Visa upon arrival, will also allow you the convenience of obtaining the Visa ahead of time.`
    },
    {
      title: `How long does it take to get a Visa?`,
      description: `The time to process a travel visa varies for each destination country. Allow, on average, several weeks to process depending on travel season and type of visa. It is this uncertainty that makes using a visa courier service beneficial. Our service works diligently with the Consulates and Embassies to expedite your visa as fast as possible.`
    },
    {
      title: `Is my passport required to get a Travel Visa?`,
      description: `Visas generally come in the form of a sticker or stamp that is affixed directly to your passport pages. For that reason, you will be required to send your original passport as part of your paperwork requirements to obtain a visa.<br/><br/>
        Your passport must be:
        <ul>
          <li>In good condition</li>
          <li>Cannot be expiring 6 months beyond your intended stay in the country</li>
          <li>Requires that you have one to two blank visa pages left</li>
        </ul>
        <strong>If your passport does not meet these criteria, we will be able to help expedite a new passport or renew your previous passport before applying for your visa.</strong>`
    },
    {
      title: `What is the difference between Visa validity and maximum stay?`,
      description: `<strong>Visa Validity</strong> is in reference to how long the Visa itself will be valid. For example, if your Visa was issued on January 1st and it is valid for 1 year, then the Visa will expire Dec. 31st.<br/><br/>
        <strong>Maximum Length of Stay</strong> is in reference to how long you can stay in the country at any particular visit. For example, China Visas allow you to stay in their country for up to 30 days. If you arrive in the country on January 1st then you must leave the country by January 30th.<br/><br/>
        Every country has its own validity requirements and length of stay rule. Some countries allow you to purchase longer validity or longer length of stay options for the visa. In some cases, you can request an extension on your visa upon arrival in the country at the Ministry of Foreign Affairs.<br/><br/>
        <strong>Note: For Non-Citizens living in the US, governments will generally only offer the least validity available.</strong>`
    },
    {
      title: `What is the difference between a single entry, multiple entry or double entry?`,
      description: `You will find there are generally two main types of Visa options for visiting a country.<br/><br/>
        <strong>Single Entry Visa</strong> - allows you to enter the particular country only one time, which means the visa is invalid as soon as the holder leaves the country. This applies to someone who plans on entering the country and has no plans to return in the near future.<br/><br/>
        <strong>Multiple Entry Visa</strong> - allows multiple entries into the country with the same visa without reapplying, which means you can enter/exit as many times as you want, up until the expiration date on the visa issued. You would request this visa if you plan on returning to the country within the timeframe that the visa would still be valid.<br/><br/>
        <strong>Double or Triple Entry Visa</strong> – Some countries limit the amount of times you can return to them.`
    },
    {
      title: `What is the difference between a Tourist and a Business Visa?`,
      description: `<strong>Tourist Visa</strong> - travel for Tourist purposes only. Generally, if you have not been invited by a business or institution your visa will be a Tourist Visa.<br/><br/>
        <strong>Business Visa</strong> – travel for the purpose of doing some kind of business with a company, individual, or institution. Business visas are more versatile, as you are always welcome to travel as a Tourist on a Business Visa. Business Visas require a Letter of Invitation from your host in the country. Business visas are also often used for travel for conventions and company retreats.`
    },
    {
      title: `My Passport is expired, but my Visa is not. Is my Visa still valid?`,
      description: `It is very common that a visa will outlive the passport, as some countries offer visas that are good for as long as 10 years. In these cases, you should have no difficulty traveling with a new passport and the expired passport with the valid visa.`
    },
    {
      title: `I am a non-citizen living in the United States with a Green Card or Long Term Visa (such as a Work Visa or Student Visa). Can I get a Travel Visa through your company?`,
      description: `Our service can assist all nationalities to countries that US citizens also need visas to travel. Understand, there are often different paperwork, costs, and timeframes for non-citizens. You will also need to provide a copy of the document showing your legal residence in the US (Green Card or Visa).`
    },
  ]
}