<div class="gw-chkout-cc gw-chkout-cc--{{ cc_type }}"
  [class.gw-chkout-cc--defined]="cc_type">
  <ul class="gw-chkout-cc__number">
    <li id="number-target">**** **** **** 1234</li>
  </ul>
  <ul class="gw-chkout-cc__valid">
    <li id="month-target">**/**</li>
  </ul>
  <div class="gw-chkout-cc__name">
    <span id="name-target">name on card</span>
  </div>
</div>
<div class="form-holder form-holder-card-info">
  <form [formGroup]="ccForm" #paymentForm="ngForm">
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field"
        [hideRequiredMarker]="true">
        <mat-label>Credit Card Number</mat-label>
        <input matInput
          ccNumber
          (input)="valueChange()"
          formControlName="cc_number"
          id="cc-number"
          type="tel"
          autocomplete="cc-number">
        <mat-error>
          Please enter a valid credit card number.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="gw-chkout-form">
      <mat-form-field class="gw-chkout-field"
        [hideRequiredMarker]="true">
        <mat-label>Name on Card</mat-label>
        <input matInput
          type="text"
          formControlName="cc_name"
          autocomplete="cc-given-name">
      </mat-form-field>
    </div>
    <div class="gw-chkout-form gw-chkout-form--payment">
      <mat-form-field class="gw-chkout-field gw-chkout-field--border gw-chkout-field--half gw-chkout-traveler__select gw-chkout-traveler__select--desktop"
        [hideRequiredMarker]="true">
        <mat-label>Month</mat-label>
        <mat-select formControlName="cc_exp_month">
          @for (month of months; track month) {
            <mat-option
              [value]="month">
              {{ month }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- Month Mobile Select -->
      <mat-form-field class="gw-chkout-field gw-chkout-field--border gw-chkout-field--full gw-chkout-traveler__select gw-chkout-traveler__select--mobile">
        <mat-label>Month</mat-label>
        <select matNativeControl formControlName="cc_exp_month">
          @for (month of months; track month) {
            <option
              [value]="month">
              {{ month }}
            </option>
          }
        </select>
      </mat-form-field>
      <!-- End Month Mobile Select -->

      <mat-form-field class="gw-chkout-field gw-chkout-field--border gw-chkout-field--half gw-chkout-traveler__select gw-chkout-traveler__select--desktop"
        [hideRequiredMarker]="true">
        <mat-label>Year</mat-label>
        <mat-select formControlName="cc_exp_year">
          @for (year of years; track year) {
            <mat-option [value]="year">
              {{ year }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <!-- Year Mobile Select -->
      <mat-form-field class="gw-chkout-field gw-chkout-field--border gw-chkout-field--full gw-chkout-traveler__select gw-chkout-traveler__select--mobile">
        <mat-label>Year</mat-label>
        <select matNativeControl formControlName="cc_exp_year">
          @for (year of years; track year) {
            <option
              [value]="year">
              {{ year }}
            </option>
          }
        </select>
      </mat-form-field>
      <!-- End Year Mobile Select -->

      <mat-form-field class="gw-chkout-field gw-chkout-field--half gw-chkout-payment__ccv"
        [hideRequiredMarker]="true">
        <mat-label>CVV</mat-label>
        <input matInput
          ccCVC
          formControlName="cc_ccv"
          id="cc-cvc"
          type="tel"
          autocomplete="off"
          mask="0009">
        <img class="gw-chkout-cc__cc-img"
          src="assets/png/icon_credit_card.png" />
      </mat-form-field>
    </div>
  </form>
  <h3 class="gw-chkout-label">
    Billing Address
  </h3>
  <form [formGroup]="billingAddressForm">
    @if (shippingAddressAvailable) {
      <mat-checkbox class="gwc-cc-billing__checkbox"
        color="primary"
        formControlName="same_as_shipping"
        (change)="checkboxUpdated($event.checked)">
        Same as shipping
      </mat-checkbox>
    }
    @if (billingAddressForm.get('same_as_shipping').value === false) {
      <gwc-google-address [addressControl]="billingAddressForm"
        type="billing"
        (addressUpdated)="billingAddressUpdated()">
      </gwc-google-address>
    }
  </form>
</div>
