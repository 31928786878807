@if (!funnel) {
  @if (header_message) {
    <div
      class="gwc-header-message"
      [innerHTML]="header_message">
    </div>
  }
  @if (settings?.header?.warning) {
    <div>
      <gw-chkout-warning></gw-chkout-warning>
    </div>
  }
}
<header class="gw-chkout__header"
  [ngClass]="{'gw-chkout__header--fixed': scrolled}">
  <div class="gw-chkout__header-body">
    <a href="{{ website }}"
      class="gw-chkout-header__logo">
      <img alt="Passports"
        src="{{ header.logo }}"/>
    </a>
    <div class="gw-chkout-header__right">
      @if (!hide_phone) {
        @if (settings?.links?.length) {
          @for (link of settings.links; track link) {
            @if (!link.internal) {
              <a
                [href]="link.url"
                class="gw-chkout-header__link">
                <strong>
                  <span class="gw-chkout-header__link-desktop">{{ link.label }}</span>
                  <span class="gw-chkout-header__link-mobile">{{ link.mobile_label }}</span>
                </strong>
              </a>
            }
            @if (link.internal) {
              <a
                [routerLink]="link.url"
                class="gw-chkout-header__link">
                <strong>
                  <span class="gw-chkout-header__link-desktop">{{ link.label }}</span>
                  <span class="gw-chkout-header__link-mobile">{{ link.mobile_label }}</span>
                </strong>
              </a>
            }
          }
        }
        @if (phone) {
          <a
            href="{{ phone.uri }}"
            class="gw-chkout-header__link gw-chkout-header__link--phone">
            <span class="gw-chkout-header__link-content">
              <strong>Questions?</strong> {{ phone.number }}
            </span>
            <svg>
              <use href="assets/svg/shared.svg#phone"/>
            </svg>
          </a>
        }
      }
      @if (user) {
        <gw-avatar
          [user]="user">
        </gw-avatar>
      }
    </div>
  </div>
</header>
@if (message && funnel) {
  <div @slideInOut
    class="gw-chkout-timer">
    <span class="gw-chkout-timer__message">
      We're holding your order for:
      <div class="gw-chkout-timer__timer"></div>
      <span class="gw-chkout-timer__countdown">{{ countDown }}</span>
    </span>
  </div>
}
@if (message) {
  <div class="gw-chkout-space"></div>
}
