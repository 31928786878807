<span class="gw-chkout-passport-opt">
  <span class="gw-chkout-passport-opt__body">
    <strong>
      {{ option.friendly_name || option.name }}
    </strong>
    @if (option.min_interval !== 'P0D') {
      <span class="gw-chkout-passport-opt__timeframe">
        {{ displayDuration(option) }}
      </span>
    }
    @if (option.sold_out) {
      <div class="gw-chkout-passport-opt__somsg">
        This is a popular service that sells out quickly, please try back tomorrow morning.
      </div>
    } @else if (option.display_message && option.display_number >= 5) {
      <span class="gw-chkout-passport-opt__recbadge">
        Recommended
      </span>
    } @else if (option.meta?.pill) {
      <span class="gw-chkout-passport-opt__recbadge">
        {{ option.meta.pill }}
      </span>
    }
    @if (option?.meta?.type === 'location-search' && option?.meta?.brand === 'office-depot') {
      <span class="gw-chkout-passport-opt__payment-method">Paid at pick-up</span>
    }
  </span>
  <strong class="gw-chkout-passport-opt__price">
    @if (option.sold_out) {
      <span class="gw-chkout-passport-opt__soldout">
        Sold Out Today
      </span>
    } @else if (option.meta?.compare_to) {
      <span class="gw-chkout-passport-opt__compare">
        {{ option.meta.compare_to | currency:'USD':'symbol':'1.2-2' }}
      </span>
    } 
    <span>{{ (option.cost ? option.cost/100 : option.default_price) | currency:'USD':'symbol':'1.2-2' }}</span>
  </strong>
</span>
@if (option.display_message && option.display_number < 5) {
  <div class="gw-chkout-passport-opt__recommended">
    <strong class="gw-chkout-passport-opt__recommended-badge">
      Recommended
    </strong>
    <span [innerHtml]="option.display_message">
    </span>
  </div>
}

@if (option.meta?.description) {
  <div class="gw-chkout-passport-opt__desc">
    {{ option.meta?.description }}
  </div>
}

@if (option.meta?.breakdown) {
  <section
    class="gw-chkout-passport-opt-breakdown">
    @for (item of option.meta.breakdown; track item) {
      <div
        class="gw-chkout-passport-opt-breakdown__item">
        <div>
          {{ item.label }}
        </div>
        <div>
          @if (item.comparing_to) {
            <span>
              {{ item.comparing_to | currency:'USD':'symbol':'1.2-2' }}
            </span>
          }
          {{ item.price | currency:'USD':'symbol':'1.2-2' }}
        </div>
      </div>
    }
  </section>
}
