<div class="gw-chkout-secure"
  [ngClass]="{'gw-chkout-secure--photo': isRMPhotoEnv}">
  <span class="gw-chkout-secure__sign">
    secure form
  </span>
  <h1>
    {{ headline }}
  </h1>
  <span class="gw-chkout-secure__sign--secundary">
    {{ description }}
  </span>
</div>