import { Injectable } from '@angular/core'
import { TealiumService } from './tealium.service'
import { environment } from 'src/environments/environment'

import * as _ from 'lodash'

declare var dataLayer: any
declare var gtag: any
declare var ga: any
declare var criteo_q: any

@Injectable({
  providedIn: 'root'
})

export class TagService {
  constructor(
    private tealiumService: TealiumService
  ){}

  navigationTags(url: string) {
    try {
      ga('set', 'page', url)
      ga('send', 'pageview')
    } catch (e) {
      console.log('GA error: ', e)
    }

    try {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'page_view', { 'page_path': url })
    } catch (e) {
      console.log('Bing error: ', e)
    }

    if (url.includes('step-1')) {
      this.orderStepTags()
    } else if (url.includes('step-2')) {
      this.accountStepTags()
    } else if (url.includes('step-4')) {
      this.paymentStepTags()
    }
  }

  orderStepTags() {
    this.criteoTags('view')
  }

  accountStepTags() {
    this.criteoTags('view')
  }

  paymentStepTags() {
    this.criteoTags('payment')
  }

  criteoTags(type: string, data: any = {}) {
    if (environment.hasOwnProperty('tags') && environment.tags.hasOwnProperty('criteo')) {
      try {
        criteo_q = criteo_q || []
        let deviceType = /iPad/.test(navigator.userAgent) ? "t" :
              /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d"
  
        switch (type) {
          case 'view':
            criteo_q.push(
              { event: "setAccount", account: environment.tags.criteo },
              { event: "setSiteType", type: deviceType },
              { event: "viewItem", item: "1" }
            )

            break
          case 'payment':
            criteo_q.push(
              { event: "setAccount", account: environment.tags.criteo },
              { event: "setSiteType", type: deviceType },
              { event: "viewBasket", item: [ 
                { id: "1", price: "0", quantity: 1 } 
              ]}
            )

            break
          case 'conversion': 
            let transaction_id = data ? data.order_number : (new Date()).getTime()
            let email = data && data.customer ? data.customer.email : 'N/A'
  
            criteo_q.push(
              { event: "setAccount", account: environment.tags.criteo },
              { event: "setSiteType", type: deviceType },
              { event: "setEmail", email: email },
              { event: "trackTransaction", id: transaction_id, item: [
                { id: "1", price: data.total.toString(), quantity: 1 } 
              ]}
            )
            
            break
        }
      } catch (exception) {
        console.log('Criteo error: ', exception)
      }
    }
  }

  orderCreateTags(info) {
    try {
      if (environment.hasOwnProperty('tags') && environment.tags.hasOwnProperty('adwords')  && environment.tags.hasOwnProperty('gtag_conv')) {
        gtag('event', 'conversion', {
          'send_to': `${environment.tags.adwords}/${environment.tags.gtag_conv}`,
          'value': info.total,
          'currency': 'USD',
          'transaction_id': info.order_number
        })
        console.log('conversion event')
      }
    } catch (exception) {
      console.log('Create conversion error: ', exception)
    }

    try {
      gtag('event', 'set_checkout_option', {
        'checkout_step': 4,
        'checkout_option': info.processor
      })

      console.log('checkout option: ', info.processor)
    } catch (exception) {
      console.log('Checkout option error: ', exception)
      console.log('Payment mode: ', exception)
    }

    try {
      gtag('event', info.processor, {
        'event_category': 'OrderPaymentMode',
        'event_label': 'Payment Mode'
      })

      console.log('order payment: ', info.processor)
    } catch (exception) {
      console.log('Payment mode: ', exception)
    }
    
    try {
      console.log('purchase event: ', info.items)

      gtag('event', 'purchase', {
        'transaction_id': info.order_number,
        'affiliation': environment.source.domain,
        'value': info.total,
        'currency': 'USD',
        'tax': '0.00',
        'shipping': info.shipping,
        'items': info.items,
        'coupon': info.coupon
      })
    } catch (exception) {
      console.log('Create purchase error: ', exception)
    }

    try {
      let products = []
      let transactions = []

      info.items.forEach((item) => {
        products.push(item.name)
        transactions.push(item.price.toString())
      })

      let transaction = {
        confirmation_id: info.order_number,
        category: ['travel:expedited travel'],
        product: products,
        quantity: [products.length.toString()],
        transaction_amount: transactions,
        transaction_salesamount: info.total.toString()
      }

      this.utagPageView('travel:expedited travel:confirmation', transaction)
    } catch (exception) {
      console.log('Create tealium error', exception)
    }

    this.criteoTags('conversion', info)
  }

  utagPageView(page: string, transaction = null) {
    console.log('utagPageView')
    if (environment.tags && environment.tags.hasOwnProperty('tealium')) {
      try {
        let cart = JSON.parse(localStorage.getItem('cart'))

        let data = {
          page: page,
          clubcode: cart.params.club ? cart.params.club : '',
          channel: 'travel',
          subchannel1: 'expedited travel'
        }

        if (transaction) {
          console.log('transaction')
          data = _.merge(data, transaction)
        }
  
        console.log('tealium: ', data)
        this.tealiumService.view(data)
      } catch (exception) {
        console.log('Tealium issue: ', exception)
      }
    }
  }

  steelHouseConfirmation(order_number, order_amount) {
    let steelHouse = document.createElement('script')
    steelHouse.type = 'text/javascript'
    steelHouse.innerHTML = `
      (function(){var x=null,p,q,m,
      o="32094",
      l="${order_number}",
      i="${order_amount}",
      c="", k="", g="", j="", u="", shadditional="";
      try{
        p=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,512)):""
      } catch(n) {
        p=document.referrer!==null?document.referrer.toString().substring(0,512):""
      }
      
      try{
        q=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location
      } catch(b) {
        q=document.location
      }
      
      try{
        m=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,512)):""
      } catch(z) {
        try{
          m=q!==null?encodeURIComponent(q.toString().substring(0,512)):""
        } catch(h) {
          m=""
        }
      }
      var A,y=document.createElement("script"),w=null,v=document.getElementsByTagName("script"),t=Number(v.length)-1,r=document.getElementsByTagName("script")[t];
      if (typeof A==="undefined") {
        A=Math.floor(Math.random()*100000000000000000)
      }
      w="dx.steelhousemedia.com/spx?conv=1&shaid="+o+"&tdr="+p+"&plh="+m+"&cb="+A+"&shoid="+l+"&shoamt="+i+"&shocur="+c+"&shopid="+k+"&shoq="+g+"&shoup="+j+"&shpil="+u+shadditional;
      y.type="text/javascript";
      y.src=("https:"===document.location.protocol?"https://":"http://")+w;
      r.parentNode.insertBefore(y,r)}());
    `

    document.head.appendChild(steelHouse)
  }

  // GA4
  beginCheckout(info) {
    try {
      console.log('ga4 begin_checkout event: ', info)

      gtag('event', 'begin_checkout', info)
    } catch (exception) {
      console.log('Create ga4 begin_checkout error: ', exception)
    }
  }

  generateLead(total) {
    try {
      console.log('ga4 generate_lead event: ', total)

      gtag('event', 'generate_lead', {
        'currency': 'USD',
        'value': total,
      })
    } catch (exception) {
      console.log('Create ga4 generate_lead error: ', exception)
    }
  }

  selectPromotion(promotion, items) {
    try {
      console.log('ga4 select_promotion event: ', {
        promotion_name: promotion.promotion_code,
        items: items.ga4_items
      })

      gtag('event', 'select_promotion', {
        promotion_name: promotion.promotion_code,
        items: items.ga4_items
      })
    } catch (exception) {
      console.log('Create ga4 select_promotion error: ', exception)
    }
  }

  public changeToCart(item, event) {
    try {
      gtag('event', event, {
        currency: 'USD',
        value: item.price,
        items: [item]
      })
    } catch (exception) {
      console.log('Create ga4 add_to_cart error: ', exception)
    }
  }
}
