import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { ISlide } from '../models/slide'
import { SLIDES } from '../data/slides'
import { environment } from 'src/environments/environment'
import { VISA_SLIDES } from '../data/visa.slides'
import { IDP_SLIDES } from '../data/idp.slides'

@Injectable({
  providedIn: 'root'
})

export class SlidesService {
  getSlides(type: string): Observable<Array<ISlide>> {
    if (environment.slides?.[type]) {
      return of(environment.slides?.[type])
    }

    let source: { [key: string]: Array<ISlide> } = type === 'passport' ? SLIDES : type === 'visa' ? VISA_SLIDES : IDP_SLIDES

    return of(source.default)
  }
}
