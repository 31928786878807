import { Component, inject, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TermsDialogComponent } from 'src/app/dialogs/terms/terms.component'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'gwc-legal-link',
	templateUrl: './legal.link.component.html',
	styleUrls: ['./legal.link.component.scss'],
	standalone: true
})

export class LegalLinkComponent {
	@Input() type: 'terms'|'privacy'|'refund'
	public links = environment.legal_links
	private dialogService = inject(MatDialog)
	public labels = {
		terms: 'Terms&nbsp;of&nbsp;Use',
		privacy: 'Privacy&nbsp;Policy'
	}

	public openDialog(type: string): void {
    this.dialogService.open(TermsDialogComponent,{
      closeOnNavigation: true,
      data: {
        type
      }
    })
  }
}
