import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { OrderService } from 'src/app/services/order.service'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject } from 'rxjs'
import { Router } from '@angular/router'
import { ShippingService } from './shipping.service'
import { MatDialog } from '@angular/material/dialog'

@Injectable()

export class GoogleService extends ApiService {
    public googleConfig = null
    public transaction
    public sale
    public name = 'GooglePay'
    public status

    constructor(
        http: HttpClient,
        private orderService: OrderService,
        private shippingService: ShippingService,
        private dialogService: MatDialog,
        private router: Router) {

        super(http)
        this.transaction = null
        this.status = new BehaviorSubject('init')
    }

    public initConfig(data): Promise<boolean> {
        // how the payment method selected by your customers is handled and used to complete a transaction.
        const tokenizationSpecification = {
            type: 'DIRECT',
            parameters: {
                protocolVersion: 'ECv2',
                publicKey: 'BOdoXP1aiNp.....kh3JUhiSZKHYF2Y='
            }
        }

        // more details about the information that you need to request in order to successfully place the transaction
        const cardPaymentMethod = {
            type: 'CARD',
            tokenizationSpecification: tokenizationSpecification,
            parameters: {
                allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                billingAddressRequired: true,
                billingAddressParameters: {
                    format: 'FULL',
                    phoneNumberRequired: true
                }
            }
        }

        // general Google API configuration parameters
        const googlePayBaseConfiguration = {
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [cardPaymentMethod]
        }

        this.googleConfig = {
            merchantIdentifier: data.merchantIdentifier,
            available: false,
            settings: {
                // general Google API configuration parameters
                googlePayBaseConfiguration: googlePayBaseConfiguration,

                // how the payment method selected by your customers is handled and used to complete a transaction.
                tokenizationSpecification: tokenizationSpecification,

                // more details about the information that you need to request in order to successfully place the transaction
                cardPaymentMethod: cardPaymentMethod,

                // information about the merchant performing the request
                merchantInfo: {
                    merchantId: '01234567890123456789',
                    merchantName: 'Example Merchant Name'
                },

                // financial details about the transaction
                transactionInfo: this.orderService.getPurchaseUnits()
            }
        }

        return window['googlePayClient'].isReadyToPay(googlePayBaseConfiguration)
    }
}
