
import { Component, Input } from '@angular/core'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { CreditCardDirectivesModule } from 'angular-cc-library'
import { GoogleAddressComponent } from '../google.address/google.address.component'
import { OrderService } from 'src/app/services/order.service'
import { DateTime } from 'luxon'

@Component({
  selector: 'gwc-credit-card',
  templateUrl: './credit.card.component.html',
  styleUrls: ['./credit.card.component.scss'],
  standalone: true,
  imports: [
    CreditCardDirectivesModule,
    FormsModule,
    GoogleAddressComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule
]
})

export class CreditCardComponent {
  @Input() ccForm: FormGroup
  @Input() billingAddressForm: FormGroup
  @Input() shippingAddressAvailable: boolean
  @Input() state: string | undefined
  @Input() country: string | undefined

  
  public months: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  public years: Array<string> = []
  public cc_type: string = null

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService
  ) {
  }

  ngOnInit() {
    if (!this.shippingAddressAvailable) {
      this.checkboxUpdated(false)
      this.billingAddressForm.get('same_as_shipping').patchValue(false)
    } else {
      this.getTaxes(this.state, this.country)
    }
    
    this.years = this.getNext10Years()
  }

  private getTaxes(state, country) {
    if (country === 'CA') {
      this.orderService.getTaxValue(state)
    }
  }

  public getNext10Years = (): string[] => {
    const years = []
    const currentYear: DateTime = DateTime.now()
  
    for (let i = 0; i < 10; i ++) {
      years.push(currentYear.plus({years: i}).toFormat('yyyy'))
    }
  
    return years
  }

  public valueChange(): void {
    const cc_class = document.querySelector('#cc-number').className
    if (cc_class.includes('visa')) {
      this.cc_type = 'visa'
    } else if (cc_class.includes('amex')) {
      this.cc_type = 'amex'
    } else if (cc_class.includes('mastercard')) {
      this.cc_type = 'mastercard'
    } else if (cc_class.includes('discover')) {
      this.cc_type = 'discover'
    } else {
      this.cc_type = null
    }
  }

  public checkboxUpdated(checked: boolean): void {
    if (checked === true) {
      // If billing address matches shipping address remove extra keys from the billingAddressForm
      this.billingAddressForm.removeControl('address')
      this.billingAddressForm.removeControl('address')
      this.billingAddressForm.removeControl('address_line')
    } else if (checked === false) {
      // If billing address doesn't match shipping address add address related keys to billingAddressForm
      const address = this.formBuilder.group({
        address_1: ['', Validators.required],
        address_2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        postal_code: ['', Validators.required],
        country: ['', Validators.required]
      })

      this.billingAddressForm.addControl('place_id', this.formBuilder.control(''))
      this.billingAddressForm.addControl('address_line', this.formBuilder.control('', Validators.required))
      this.billingAddressForm.addControl('address', address)
    }
  }

  public billingAddressUpdated() {
    const addressFormGroup = this.billingAddressForm.controls.address as FormGroup
    this.getTaxes(addressFormGroup.controls.state.value, addressFormGroup.controls.country.value)
  }
}
