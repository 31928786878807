import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-home-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit {
  @Input() type: string

  public signinUrl: string = environment.client_url
  public links = []
  public linksStyle = 'uppercase'
  public signin_label: SafeHtml = ''
  public compact: boolean 

  constructor(
    private sanitizer: DomSanitizer
  ){}

  ngOnInit() {
    this.signin_label = this.sanitizer.bypassSecurityTrustHtml(environment.landing_pages?.[this.type]?.signin || "Sign in to our secure application portal.")
    this.compact = environment.landing_pages?.[this.type]?.compact_signin
    
    if (environment.landing_pages?.[this.type]?.sign_in_links) {
      this.links = environment.landing_pages?.[this.type]?.sign_in_links
    }
  }
}
