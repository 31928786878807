@if (user && user.uuid) {
  <button mat-button
    class="gw-chkout__avatar"
    [matMenuTriggerFor]="userMenu"
    aria-label="User Menu">
    <img [src]="user.avatar"
      [alt]="user.first_name + ' ' + user.last_name">
  </button>
}
<mat-menu #userMenu="matMenu" class="gw-chkout__avatar-menu">
  <button mat-menu-item
    (click)="logout()">
    <span>Logout</span>
  </button>
</mat-menu>
