export const environment: any = {
  "production": true,
  "API": "https://api.rushmypassport.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": true,
  "header": {
    "logo": "assets/svg/rmp_dark.svg"
  },
  "support": {
    "phone": "8335678976",
    "email": "info@rushmypassport.com",
    "label": ""
  },
  "login": {
    "enabled": false,
    "logo": "assets/svg/rmp_dark.svg"
  },
  "terms": {
    "website_url": "https://www.rushmypassport.com",
    "website": "www.rushmypassport.com",
    "website_name": "RushMyPassport.com",
    "website_email": "info@rushmypassport.com"
  },
  "legal_links": {
    "terms": "/terms-of-use",
    "privacy": "/privacy-policy",
    "refund": "/refund-policy"
  },
  "source": {
    "domain": "aarp",
    "main_website": "",
    "promo_code": false,
    "name": "AARP"
  },
  "slides": {
    "passport": [
      {
        "title": "Select Service Type and Place Your Order",
        "hide_index": true, 
        "icon": {
          "name": "step_1",
          "width": "90px",
          "height": "90px"
        },
        "elements": [
          "Select passport service and indicate how quickly you need it.",
          "<strong>Passport Renewal Bundles for AARP Members</strong> include shipping and government fees.",
          "All other passport services will require government fees to be paid separately."
        ]
      },
      {
        "title": "Complete and Print Your Application Packet",
        "hide_index": true, 
        "icon": {
          "name": "step_2",
          "width": "90px",
          "height": "90px"
        },
        "elements": [
          "After placing your order, you will be directed to govWorks to begin your application.",
          "The user-friendly, online wizard will generate the required documents.",
          "Review and print your personalized packet and checklist."
        ]
      },
      {
        "title": "Get a New Passport Photo",
        "hide_index": true, 
        "icon": {
          "name": "step_3",
          "width": "90px",
          "height": "90px"
        },
        "elements": [
          "Every passport application requires a printed government-compliant photo.",
          "Option A: Get a new passport photo at a retail location near you.",
          "Option B: <a href='https://www.passportphotocreator.com/' target='_blank'>Download the Passport Photo Creator app</a> and print at a local Walgreens."
        ]
      },
      {
        "title": "Gather Your Documents for Submission",
        "hide_index": true, 
        "icon": {
          "name": "step_4",
          "width": "90px",
          "height": "90px"
        },
        "elements": [
          "Gather your documents and follow the submission instructions.",
          "<strong>Passport Renewal Bundles for AARP Members</strong> will include a prepaid FedEx shipping label.",
          "If your application does not have a FedEx shipping label you will be required to go through the <a href='#adjudication'>Adjudication process</a> with a Passport Acceptance Agent."
        ]
      }
    ]
  },
  "footer": {
    "logo": "assets/svg/rmp_dark.svg",
    "logo_link": "/",
    "copyright": "RushMyPassport.com, LLC",
    "footer_disclaimer": "AARP member benefits are provided by third parties, not by AARP or its affiliates. Providers pay royalty fees to AARP for the use of its intellectual property. These fees are used for the general purposes of AARP. Some provider offers are subject to change and may have restrictions. Please contact the provider directly for details."
  },
  "landing_pages": {
    "passport": {
      "hide_main_cta": true,
      "enabled": true,
      "hide_banner": true,
      "title": "AARP | Passport Expediting",
      "compact_signin": true, 
      "signin": "Returning Customer?<br/>Sign in here.",
      "sign_in_links": ["visas"],
      "heading": "Renewing your U.S. Passport has never been easier.",
      "meta": {
        "description": "AARP members and their families have exclusive access to passport renewal bundles along with first-time, child, and replacement passports with RushMyPassport."
      },
      "description": "<section style=\"display: flex;\"><img src=\"assets/svg/aarp_logo.svg\" class=\"gw-chkout-home__aarp\"><div>AARP members and their families can purchase exclusive passport renewal bundles with RushMyPassport.<section style=\"color: #000000; font-weight: 700; font-size: 15px;\"><a target=\"_blank\" style=\"color: #000000; text-decoration: none;\" href=\"https://appsec.aarp.org/mem/join?campaignId=FFKURUSH&cmp=ASI_P_MU_JN_RUSHMYPASSPORT\">Become an AARP member</a> | <a target=\"_blank\"  style=\"color: #000000; text-decoration: none;\" href=\"https://appsec.aarp.org/mem/renew?campaignId=FFKURUSH&cmp=ASI_P_MU_RN_RUSHMYPASSPORT\">Renew your AARP membership</a></section></div></section>",
    },
    "visa": {
      "enabled": true,
      "title": "AARP | Visa Expediting",
      "compact_signin": true, 
      "signin": "Returning Customer?<br/>Sign in here.",
      "sign_in_links": ["passport"],
      "heading": "Hassle-free expedited travel visa services.",
      "meta": {
        "description": "AARP members and their families receive exclusive pricing on Business visas, tourist visas, and everything in between."
      },
      "description": "<section style=\"display: flex;\"><img src=\"assets/svg/aarp_logo.svg\" class=\"gw-chkout-home__aarp\"><div>AARP members receive exclusive pricing on Business visas, tourist visas, and everything in between.<section style=\"color: #000000; font-weight: 700; font-size: 15px;\"><a target=\"_blank\"  style=\"color: #000000; text-decoration: none;\" href=\"https://appsec.aarp.org/mem/join?campaignId=FFKURUSH&cmp=ASI_P_MU_JN_RUSHMYPASSPORT\">Become an AARP member</a> | <a target=\"_blank\"  style=\"color: #000000; text-decoration: none;\" href=\"https://appsec.aarp.org/mem/renew?campaignId=FFKURUSH&cmp=ASI_P_MU_RN_RUSHMYPASSPORT\">Renew your AARP membership</a></section></div></section>"
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true, 
  "seals": {
  "truste": "170aae01-cd7c-4ad6-9007-5894464c0962"
 },
  "summary_cta_icon": true,
  "locations_page": {
    "enabled": false
  },
  "tags": {
    "mouseflow": "a4b10d95-cf95-4ba3-a395-3c78a36837ce",
    "tag_manager": "GTM-NNLNFVF",
    "ga4": ["G-5S9MVF86SJ", "G-W1WNW27389"]
  }
};