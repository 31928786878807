import { Component, OnInit, Input } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { QuestionsService } from 'src/app/services/questions.service'
import { Subscription } from 'rxjs'
import { IQuestion } from 'src/app/models/question'
import { first } from 'rxjs/operators'

@Component({
  selector: 'gw-chkout-home-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})

export class QuestionsComponent implements OnInit {
  @Input() type: string
  @Input() hideHeader: boolean
  
  public questions: Array<IQuestion>
  public questionsSuscription: Subscription

  constructor(
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private questionsService: QuestionsService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      `arrow_right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("./assets/svg/arrow-right.svg")
    )
  }

  ngOnInit() {
    // Getting questions data
    this. questionsSuscription = this.questionsService.getQuestions(this.type).pipe(
      first()
    ).subscribe(
      data => {
        this.questions = data
      }
    )
  }

  ngOnDestroy() {
    if (this.questionsSuscription !== undefined) {
      this.questionsSuscription.unsubscribe()
    }
  }
}
