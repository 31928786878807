import { Injectable } from '@angular/core'
import { ShippingService } from '../services/shipping.service'
import { ActivatedRouteSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root'
})

export class ShippingResolverService {
  constructor(
    private shippingService: ShippingService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.shippingService.getShippingOptions()
  }
}