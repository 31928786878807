import { Component, Input } from '@angular/core'

@Component({
  selector: 'gw-chkout-visa-layout',
  templateUrl: './visa.layout.component.html',
  styleUrls: ['./visa.layout.component.scss']
})

export class VisaLayoutComponent {
  @Input() bg: string
  @Input() country: { label: string, value: string }
}
