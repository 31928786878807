import { Injectable, EventEmitter } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

export class HeaderService {
  private timerInterval: NodeJS.Timeout
  private time: number = 0

  /**
   * It is used as flag to know when the timer is runing and then to show the header message
   */
  public timer: boolean = false
  /**
   * It is fired every seconds while the timer is runing
   */
  public countDown: EventEmitter<string> = new EventEmitter()
  /**
   * It is fired when the timer count down stopping
   */
  public timerStopped: EventEmitter<any> = new EventEmitter()
  /**
   * It is fired when the timer count down starting
   */
  public timerStarted: EventEmitter<any> = new EventEmitter()

  constructor() {}

  /**
   * Runs the count down storing the time in the session and fireing the countDown event every second
   */
  private runTimer(): void {
    this.countDown.emit(this.fixTime(this.time))
    this.timerInterval = setInterval(() => {
      sessionStorage.setItem('time', this.time.toString())

      if (this.time > 0) {
        this.time--
        this.countDown.emit(this.fixTime(this.time))
      } else this.stopTimer()
    }, 1000)
  }

  private fixTime(value: number): string {
    let sec = value % 60
    let min = Math.floor(value / 60)

    return (min < 10 ? '0' + min : min)  + ':' +  (sec < 10 ? '0' + sec : sec) 
  }

  /**
   * Checks the session store and load the seconds to continue with the count down after refreshing the page
   */
  private settingInterval(): void {
    this.time = sessionStorage.getItem('time') ? +sessionStorage.getItem('time') : 900
    clearInterval(this.timerInterval)
  }

  /**
   * Starts the timer count down
   */
  startTimer(reset: boolean): void {
    if (reset === false && this.timer) return
  
    this.settingInterval()
    if (this.timer === false) {
      this.timer = true
      sessionStorage.setItem('timer', this.timer.toString())
    }
    this.runTimer()
    this.timerStarted.emit()
  }

  /**
   * Stops the timer count down
   */
  stopTimer(): void {
    clearInterval(this.timerInterval)
    this.timer = false
    sessionStorage.setItem('timer', this.timer.toString())
    sessionStorage.removeItem('time')
    this.timerStopped.emit()
  }
}
