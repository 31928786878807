import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LocationNamePipe } from './location.name.pipe'

@NgModule({
  declarations: [
    LocationNamePipe
  ],
  exports: [
    LocationNamePipe
  ]
})

export class LocationNamePipeModule { }
