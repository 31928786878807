import { Component, OnInit, Input } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { ISlide } from 'src/app/models/slide'
import { SlidesService } from 'src/app/services/slides.service'
import { Subscription } from 'rxjs'
import { first } from 'rxjs/operators'
import { register } from 'swiper/element/bundle'

@Component({
  selector: 'gw-chkout-home-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})

export class SliderComponent implements OnInit {
  @Input() type: string
  @Input() hideHeader: boolean
  @Input() size: string
  
  public slider: Array<ISlide>
  public slidesSuscription: Subscription

  constructor(
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    private slidesService: SlidesService
  ) {}

  ngOnInit() {
    // register Swiper custom elements
    register()

    // Getting slider data
    this.slidesSuscription = this.slidesService.getSlides(this.type).pipe(
      first()
    ).subscribe(
      data => {
        this.slider = data
        this.slider.forEach(item => {
          this.matIconRegistry.addSvgIcon(
            item.icon.name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/svg/${item.icon.name}.svg`)
          )
        })
      }
    )
  }

  ngOnDestroy() {
    if (this.slidesSuscription !== undefined) {
      this.slidesSuscription.unsubscribe()
    }
  }
}
