import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { environment } from 'src/environments/environment'
import { TagService } from 'src/app/services/tag.service'

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent {
  public email: string
  public confirmationNumber: string
  public client: string
  public shipping: boolean = true
  public type: string = environment.source.type
  public external: boolean = false
  public externalSource: string = undefined

  constructor(
    private activatedRoute: ActivatedRoute,
    private tagService: TagService
  ) {
    let params = this.activatedRoute.snapshot.queryParams,
    govworks_api = environment.API

    if (!govworks_api.includes('govworks')) {
      govworks_api = environment.production ? 'https://api.govworks.com/api/' : 'https://api.dev.govworks.com/api/'
    }

    this.confirmationNumber = params.order
    this.email = params.email
    this.external = params.external_order === '1'
    this.externalSource = this.mapExternalSource(params.external_source)

    if (this.type === 'photo') {
      this.client = `${govworks_api}application/${params.digital_photo_app}/download/photo/${params.digital_photo_token}`
    } else {
      this.client=`${govworks_api}auth/token/${params.token}/${params.uuid}`
    }
    this.shipping = params.shipping === 'true'
    
    if (environment.production && environment.hasOwnProperty('tags') && environment.tags.hasOwnProperty('steelhouse')) {
      this.tagService.steelHouseConfirmation(this.confirmationNumber, params.total)
    }
  }

  private mapExternalSource(name: string): string {
    switch (name) {
      case 'officedepot': return 'Office Depot'
    }
  }
}
