import { Injectable } from '@angular/core'
import { DateTime, Duration } from 'luxon'
import { DateTime as BusinessDateTime } from 'luxon-business-days'

@Injectable({
  providedIn: 'root'
})

export class HelperService {
  public parseDuration = (from: string, to: string): string => {
    let unit: string
    const from_number = parseInt(from.match(/\d+/)[0])
    const to_number = parseInt(to.match(/\d+/)[0])
  
    if (from.includes('D')) {
      unit = 'Business Day'
    } else if (from.includes('W')) {
      unit = 'Week'
    }
  
    return `${from_number} ${from_number === to_number ? '' : ' - ' + to_number} ${unit}${to_number > 1 ? 's' : ''}`
  }
  
  public getFollowingBusinessDays = (followingDays: number = 1): DateTime => {
    const localDateTime = BusinessDateTime.local()
    return localDateTime.plusBusiness({ days: followingDays })
  }
  
  public beautifyRange = (from: string, to: string): string => {
    const fromDays = Duration.fromISO(from).days
    const toDays = Duration.fromISO(to).days
  
    const onlyFromDaysResponse = `${fromDays} Business Days`
    const fromToDaysResponse = `${fromDays}-${toDays} Business Days`
  
    if (fromDays === 0) {
      return ''
    }
  
    return from === to ? onlyFromDaysResponse : fromToDaysResponse
  }
  
  public beautifyPeriod = (period: string): string => {
    const periodDuration = Duration.fromISO(period)
  
    switch (true) {
      case period.includes('D'):
        return `${periodDuration.days} Days`
      case period.includes('W'):
        return `${periodDuration.weeks} Weeks`
      case period.includes('M'):
        return `${periodDuration.months} Months`
      case period.includes('Y'):
        return `${periodDuration.years} Years`
    }
  }
  
  public formatDate = (
    value: string,
    oldFormat: string = 'M/dd/yyyy',
    newFormat: string = 'MM/dd/yyyy'
  ): string => {
    return DateTime.fromFormat(value, oldFormat).toFormat(newFormat)
  }
}
