import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HomeGuard {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let type = next.routeConfig.data.type

    if (!environment.landing_pages?.[type]?.enabled) {
      if (environment.source.type === 'photo') {
        this.router.navigate(['/photo/step-1'])
      } else {
        this.router.navigate(['/step-1'])
      }
    }

    return true
  }
}
