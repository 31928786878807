import { NgModule } from "@angular/core"
import { ConfirmationComponent } from './confirmation.component'
import { CommonModule } from "@angular/common"
import { MatButtonModule } from "@angular/material/button"

@NgModule({
  declarations: [
    ConfirmationComponent
  ],
  imports: [
    CommonModule, 
    MatButtonModule
  ]
})

export class ConfirmationModule {}
