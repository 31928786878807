<div id="gw-chkout"></div>

<gw-chkout-header></gw-chkout-header>

<div class="gw-chkout"
  [ngClass]="{ 'gw-chkout--space': this.location.path() != '/locations' }">
  <router-outlet></router-outlet>
</div>

@if (location.path() != '/locations') {
  <gw-chkout-footer></gw-chkout-footer>
}

@if (livechat?.enabled) {
  <livechat-widget [license]="livechat.license_id"
    [group]="livechat.group">
  </livechat-widget>
}
