export const DESCRIPTIONS = {
  'passport': {
    'new-passport': '<b>To apply for a new U.S. passport</b> you must be at least 16 years old and have never had a U.S. passport, or your previous passport has been expired for more than 5 years. If the applicant is younger than 16 you must apply for a child passport.',
    'passport-renewal': '<b>To apply for a U.S. passport renewal</b> you must be able to submit your most recent 10-year adult U.S. passport, which must be undamaged and issued within the past 15 years. <b>Child passports cannot be renewed.</b>',
    'child-passport': `<b>To apply for a child's U.S. passport</b> the applicant must be 15 years old or younger. If the applicant is 16 or older they must apply for a new passport. All travelers require a passport to travel internationally, including infants.`,
    'lost-passport': '<b>Applying for a lost U.S. passport replacement</b> is similar to applying for a new passport, but you must report the loss of your passport with an extra form that we provide. Applicants should replace their lost passport as soon as possible to protect their identity.',
    'damaged-passport': '<b>To apply for a damaged U.S. passport replacement</b> you must be in possession of the damaged passport. What constitutes damaged? Passports with significant tears, page separation, holes, altered in physical appearance or composition are considered damaged.',
    'name-change': '<b>To apply for a U.S. name change passport</b> you must be in possession of your current passport and you must provide legal documentation of your name change. If you do not have your current passport you must apply for a new passport along with proper name change documentation.',
    'second-passport': '<b>To apply for second U.S. passport</b> you must be in possession of your current 10-year passport. Second passports are valid for 4 years and are ideal for frequent travelers who often apply for travel visas and visit countries with restrictive entry requirements.',
    'stolen-passport': '<b>Applying for a stolen U.S. passport replacement</b> is similar to applying for a new passport, but you must report your stolen passport with an extra form that we provide. Applicants should replace their stolen passport as soon as possible to protect their identity.'
  },
  'ca_passport': {
    'new-passport': '<b>To apply for a new Canadian passport</b>, you must meet one of the following criteria: be at least 16 years old and have never held an adult Canadian passport; wish to change information in your existing Canadian passport; or have a previous passport that expired more than 5 years ago. If the applicant is younger than 16, they must apply for a child passport.',
    'passport-renewal': '<b>To renew a Canadian passport</b>, you must provide the passport number, issue, and expiration dates from your previous passport, which must have been issued within the past 15 years. If you wish to change any details in your passport, such as your name, date or place of birth, or gender, you must apply for a new passport. <b>Child passports cannot be renewed.</b>',
    'child-passport': `<b>To apply for a Canadian passport for a child</b>, the applicant must be 15 years old or younger. Applicants who are 16 or older are required to apply for an adult passport. A passport is mandatory for all travelers for international travel, including infants.`
  }
}