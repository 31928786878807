<!-- TrustBox widget - Carousel --> 
<!-- <div id="trustbox"
  class="trustpilot-widget" 
  data-locale="en-US" 
  data-template-id="53aa8912dec7e10d38f59f36" 
  data-style-height="140px" 
  data-style-width="100%" 
  data-theme="light"
  data-stars="5"> 
  <a href="https://www.trustpilot.com/review/rushmypassport.com" target="_blank" rel="noopener">
    Trustpilot
  </a> 
</div>  -->
<!-- End TrustBox widget -->

<!-- TrustBox widget - Slider -->
<div class="trustpilot-widget" 
  data-locale="en-US" 
  data-template-id="54ad5defc6454f065c28af8b" 
  data-businessunit-id="4f22c5a500006400051282dd" 
  data-style-height="240px" 
  data-style-width="100%" 
  data-theme="light" 
  data-stars="5" 
  data-review-languages="en">
  <a href="https://www.trustpilot.com/review/rushmypassport.com" target="_blank" rel="noopener">
    Trustpilot
  </a>
</div>
<!-- End TrustBox widget -->
