import { Component, OnInit, Input } from '@angular/core'

declare global {
  interface Window {
    Trustpilot: any
  }
}

@Component({
  selector: 'gw-chkout-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss']
})

export class TrustpilotComponent implements OnInit {
  @Input() trustpilot: string

  ngOnInit() {
    const trustboxRef = document.getElementById('trustbox')

    if (trustboxRef) {
      trustboxRef.setAttribute('data-businessunit-id', this.trustpilot)
      window.Trustpilot.loadFromElement(trustboxRef)
    }
  }
}
