<section class="gwc-idp-option">
  @for (item of option.meta.items; track item) {
    <div class="gwc-idp-option__item">
      <div>
        {{ item.label }}
      </div>
      <div>
        {{ item.price | currency:'USD':'symbol':'1.2-2' }}
      </div>
    </div>
  }
  <div class="gwa-idp-option__subtotal">
    <div>
      Subtotal
    </div>
    <div>
      {{ option.default_price | currency:'USD':'symbol':'1.2-2' }}
    </div>
  </div>
</section>