<div class="gw-chkout-form">
  <mat-form-field class="gw-chkout-field gw-chkout-field--half"
    [hideRequiredMarker]="true">
    <mat-label> {{ label ? label : type === 'shipping' ? 'Enter Shipping Address': 'Enter Billing Address' }} </mat-label>
    <input matInput
      [formControl]="addressControl.get('address_line')"
      (blur)="onBlur()"
      #shippingAddressInput>
      @if (addressControl.get('address_line').hasError('required')) {
        <mat-error>
          Please enter a valid address.
        </mat-error>
      } @else if (addressControl.get('address_line').hasError('selected')) {
        <mat-error>
          Please choose an address from the list
        </mat-error>
      }
  </mat-form-field>
  @if (showAddress2) {
    <mat-form-field class="gw-chkout-field gw-chkout-field--full gw-chkout-ship__apt">
      <mat-label>Apt/Ste</mat-label>
      <input matInput 
        [formControl]="addressControl.get('address').get('address_2')">
      <mat-hint>
        Optional
      </mat-hint>
    </mat-form-field>
  }
</div>
