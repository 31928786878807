import { Component, OnInit } from '@angular/core'
import { OrderService } from 'src/app/services/order.service'
import { ActivatedRoute } from '@angular/router'
import { TagService } from 'src/app/services/tag.service'
import { FormBuilder, Validators } from '@angular/forms'
import { environment } from 'src/environments/environment'
import { EnvironmentLandingPage } from 'src/app/types/environment'
import { Meta } from '@angular/platform-browser'

@Component({
  selector: 'gw-chkout-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public domain: string = environment.source.domain
  public hide_banner: boolean|undefined = environment.banner?.hide
  public type: string
  public variation: number
  // public aarp_login: string|undefined = environment.aarp_login
  public applicantForm = this.formBuilder.group({
    citizenship: ['US', Validators.required],
    residency: ['', Validators.required],
    country: ['', Validators.required],
  })
  hasContactSupport: boolean

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private tagService: TagService,
    private formBuilder: FormBuilder,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.orderService.getCart()
    this.activatedRoute.data.subscribe((response) => {
      this.type = response.type

      const landing_page: EnvironmentLandingPage = environment.landing_pages?.[this.type]

      if (landing_page.meta) {
        this.updateMetaTags(landing_page.meta)
      }
      this.hide_banner = this.type !== 'passport' || landing_page?.hide_banner
      this.variation = landing_page?.variation || 1
      let params = {...this.activatedRoute.snapshot.queryParams}
      let current = this.orderService.orderForm.controls.params.value
  
      this.orderService.orderForm.controls.params.patchValue({...params, ...current})
      this.orderService.saveCart()
  
      if (this.type == 'passport') {
        this.tagService.utagPageView('travel:expedited travel:start')
      } else if (this.type === 'visa') {
        this.tagService.utagPageView('travel:expedited travel:visa:start')
      }
    })

    this.hasContactSupport = !!environment.support.phone || !!environment.livechat?.enabled || !!environment.support.email
  }

  private updateMetaTags(meta) {
    this.meta.removeTag('name="description"')
    this.meta.addTag({name: 'description', content: meta.description})
  }
}
