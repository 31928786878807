export const visa_types: { [key: string]: string } = {
  tourist: 'Tourist',
  business: 'Business',
  private: 'Private',
  official: 'Official',
  work: 'Work',
  employment: 'Employment',
  crew: 'Crew',
  visitor: 'Visitor',
  student: 'Student',
  tourist_evisa: 'eVisa Tourist',
  business_evisa: 'eVisa Business',
  tourist_business_evisa: 'eVisa Tourist/Business',
  volunteer: 'Volunteer',
  religious: 'Religious/Missionary',
  tourist_schengen: 'Tourist-Schengen Visa',
  eta: 'ETA (Electronic Travel Authority)',
  transit: 'Transit',
  diplomatic_official: 'Diplomatic-Official',
  tourist_business: 'Tourist/Business',
  residence: 'Residence',
  tourist_under_16: 'Tourist (Under 16)',
  tourist_private_stay: 'Tourist (Private Stay)',
  conference: 'Conference',
  tourist_east_africa: 'East Africe Tourist Visa',
  child_under_17: 'Child (Age 17 and Under)',
  child_under_13: 'Child (Age 13 and Under)',
  humanitarian: 'Humanitarian'
}
