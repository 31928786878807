import { Component, Input } from '@angular/core'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'gw-chkout-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})

export class SecureComponent {
  @Input() headline: string
  @Input() description: string
  public isRMPhotoEnv = environment?.source?.type === 'photo'
}
