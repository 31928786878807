import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { OrderService } from '../services/order.service'
import { AuthService } from '../services/auth.service'

@Injectable({
  providedIn: 'root'
})

export class AccountGuard  {
  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private router: Router
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.check()
      .subscribe((response) => {
        if (!response.message && response.roles && response.roles.includes('customer')) {
          if (this.orderService.previous == '/step-3') {
            this.router.navigate(['/step-1'])
          } else {
            this.orderService.createHotLead()
            this.router.navigate(['/step-3'])
          }
        }
      })

    return true
  }
}
