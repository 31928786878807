<div class="gw-chkout-disclaimer"
  [ngClass]="{'gw-chkout-disclaimer--photo': isRMPhotoEnv,
  'gw-chkout-elevation': isRMPhotoEnv}">
  <form [formGroup]="ccForm" #paymentForm="ngForm">
    <mat-checkbox class="gw-chkout-chkbox"
      color="primary"
      [class.gw-chkout-chkbox--submitted]="submitted"
      formControlName="confirm">
      @if (!isPickupOptSelected) {
        By authorizing payment for <strong>{{ total | currency:currency:'symbol':'1.2-2' }}</strong>,
      } @else {
        By Submitting an order,
      }
      I am acknowledging that I have reviewed and agree with the
      <gwc-legal-link type="terms"></gwc-legal-link>
      and
      <gwc-legal-link type="privacy"></gwc-legal-link>.
      @if (isPickupOptSelected) {
        Your Request will be submitted to the selected Office Depot location and you will be required to pay <strong>{{ total | currency:currency:'symbol':'1.2-2' }}</strong> at the time of pick-up.
      }
    </mat-checkbox>
  </form>
</div>
