import { Component, OnInit, Input } from '@angular/core'
import { VisaOption } from 'src/app/types/products'
import { HelperService } from 'src/app/services/helper.service'

@Component({
  selector: 'gw-chkout-visa-option',
  templateUrl: './visa.option.component.html',
  styleUrls: ['./visa.option.component.scss']
})

export class VisaOptionComponent implements OnInit {
  @Input() service: VisaOption

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit(): void {}

  public beautifyRange(from: string, to: string): string {
    return this.helperService.beautifyRange(from, to)
  }
}
