export const visa_entries: {[key: string]: string} = {
  single: 'Single',
  multiple: 'Multiple',
  double: 'Double',
  dependent_on_consular_approval: 'Dependent On Consular Approval',
  eta_double: 'ETA-Double',
  multiple_renewal: 'Multiple/Renewal',
  single_oman_qatar: 'Single/Oman&Qatar',
  multiple_first_visa: 'Multiple/First Visa',
  single_multiple: 'Single/Multiple',
  single_minor: 'Single Minor',
  triple: 'Triple'
}
