import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})

export class AgentGuard {
  constructor(
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return environment.source.domain === 'aaa' ? true : this.router.parseUrl('/')
  }
}
