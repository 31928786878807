<div class="gw-chkout-main gw-chkout-payment">
	<div>
		<h5>Protect Your order with RushMyPassport.com's 100% Refund Guarantee</h5>
		<p>Add this plan to help protect your {{ getServicePrice() | currency:currency:'symbol':'1.2-2' }} passport service. Add valuable coverage for your passport process. Plan includes:</p>
		<ul class="gw-refund__ul--my">
			<li>Passport Fee Refund - 100% refund guarantee if you can't proceed with your order for a <a href="#">covered reason</a>.</li>
		</ul>
	</div>
	<hr>
	<form [formGroup]="orderForm">
		<mat-radio-group formControlName="refund_guarantee">
			<div class="gw-refund__radio-btn">
				<mat-radio-button
					color="primary"
					[value]="true">
					<strong>Yes, insure my order for only {{ getRefundGuarantee() | currency:currency:'symbol':'1.2-2' }}.</strong>
					<br>
					<span>I have read, understand, and agree to the terms and conditions of the Refund Guarantee Plan.</span>
				</mat-radio-button>
			</div>
			<div class="gw-refund__radio-btn">
				<mat-radio-button
					color="primary"
					[value]="false">
					<strong>No, I don't want to cover my {{ getServicePrice() | currency:currency:'symbol':'1.2-2' }} service, and I understand that unforeseen events could lead to additional expenses.</strong>
				</mat-radio-button>
			</div>
		</mat-radio-group>
	</form>
</div>
