@if (type !== 'heading') {
  <div
    class="gw-chkout-applicant-info gw-chkout-applicant-info--{{ type }}">
    <h4>
      {{ title }}
    </h4>
    <div class="gw-chkout-applicant-info__details">
      <section class="gw-chkout-applicant-info__details-item">
        <span>I'm&nbsp;a</span>
        <gw-chkout-location-select type="country"
          label="Citizenship"
          [field]="citizenship"
          (onSelect)="formUpdated('citizenship')">
        </gw-chkout-location-select>
      </section>
      <section class="gw-chkout-applicant-info__details-item">
        <span>residing&nbsp;in</span>
        <gw-chkout-location-select type="state"
          label="State"
          [field]="residency"
          (onSelect)="formUpdated('residency')">
        </gw-chkout-location-select>
      </section>
      <section class="gw-chkout-applicant-info__details-item">
        <span>traveling&nbsp;to</span>
        <gw-chkout-location-select type="country"
          label="Country"
          [field]="country"
          (onSelect)="formUpdated('country')">
        </gw-chkout-location-select>
      </section>
      @if (type) {
        <button mat-flat-button
          (click)="onSearch($event)"
          class="gw-chkout-main-btn">
          Search
        </button>
      }
    </div>
  </div>
} @else {
  <section class="gwc-applicant-info-joined">
    <gw-chkout-location-select type="country"
      label="Citizen of"
      [field]="citizenship"
      appearance="outline"
      (onSelect)="formUpdated('citizenship')">
    </gw-chkout-location-select>
    <gw-chkout-location-select type="state"
      label="Residing in"
      [field]="residency"
      appearance="outline"
      (onSelect)="formUpdated('residency')">
    </gw-chkout-location-select>
    <gw-chkout-location-select type="country"
      label="Traveling to"
      [field]="country"
      appearance="outline"
      (onSelect)="formUpdated('country')">
    </gw-chkout-location-select>
    @if (type) {
      <button mat-flat-button
        (click)="onSearch($event)"
        class="gw-chkout-main-btn">
        Search Visas
      </button>
    }
  </section>
}
