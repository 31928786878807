import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

/*google pay*/
// document.write('<script async type="text/javascript"' +
//     'src="https://pay.google.com/gp/p/js/pay.js" ' +
//     'onload="onGooglePayLoaded()"> ' +
//   '</script>')

// let google_pay_load = document.createElement('script')
// google_pay_load.type = 'text/javascript'
// google_pay_load.innerHTML = `function onGooglePayLoaded() {
//     window["googlePayClient"] = new google.payments.api.PaymentsClient({ environment: ' + (environment.production ? '"PRODUCTION"' : '"TEST"') + '});
//   }`
let payment_script = document.createElement('script')
payment_script.type = 'text/javascript'

if (environment.production) {
  payment_script.src = 'https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js'
} else {
  payment_script.src = 'https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js'
  payment_script.async = true
}

document.head.appendChild(payment_script)

if (!environment.production || environment.tags?.noindex) {
  let noindex = document.createElement('meta')
  noindex.name = "robots"
  noindex.content = "noindex"
  document.head.appendChild(noindex)
}

// Authorize script
let script = document.createElement('script')
script.type = 'text/javascript'

if (environment.production) {
  enableProdMode()
  script.src = 'https://js.authorize.net/v1/Accept.js'
} else {
  script.src = 'https://jstest.authorize.net/v1/Accept.js'
}

document.head.appendChild(script)

// Tags
if (environment.hasOwnProperty('tags') && environment.tags.hasOwnProperty('tag_manager')) {
  let tag_manager_head = document.createElement('script')
  tag_manager_head.innerHTML = `
    (function(w,d,s,l,i){
      w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.tags.tag_manager}');`
  document.head.appendChild(tag_manager_head)

  let tag_manager_body = document.createElement('noscript')
  tag_manager_head.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.tags.tag_manager}"
      height="0" 
      width="0" 
      style="display:none;visibility:hidden">
    </iframe>`
  
  let first_body = document.body.firstChild
  first_body.parentNode.insertBefore(tag_manager_body, first_body)
}

if (environment.production && environment.hasOwnProperty('tags')) {
  if (environment.tags.hasOwnProperty('analytics')) {
    let ua_script = document.createElement('script')
    ua_script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.tags.analytics}`
    ua_script.async = true
    document.head.appendChild(ua_script)

    // Google Data Layer
    let data_layer = document.createElement('script')
    data_layer.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${environment.tags.analytics}');
      
      if (${environment.tags.hasOwnProperty('adwords')}) {
        gtag('config', '${environment.tags.adwords}');
      }
      if (${environment.tags.hasOwnProperty('ga4')}) {
        gtag('config', '${environment.tags.ga4}');
      }
    `
    document.head.appendChild(data_layer)
    
    // Google Analytics
    let analytics = document.createElement('script')
    analytics.innerHTML = `
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', '${environment.tags.analytics}', 'auto');  
    `

    document.head.appendChild(analytics)
  } else if (environment.tags.hasOwnProperty('ga4')) {
    let ga4_array: string[] = environment.tags.ga4
    let gtag_ga4_config = ``

    ga4_array.forEach(ga4 => {
      let ga4_script = document.createElement('script')
      ga4_script.async = true
      ga4_script.src = `https://www.googletagmanager.com/gtag/js?id=${ga4}`
      document.head.appendChild(ga4_script)

      gtag_ga4_config += ` gtag('config', '${ga4}');`
    })

    // Google Data Layer
    let data_layer = document.createElement('script')
    data_layer.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      ${gtag_ga4_config}

      if (${environment.tags.hasOwnProperty('adwords')}) {
        gtag('config', '${environment.tags.adwords}');
      }
    `
    document.head.appendChild(data_layer)
  }

  if (environment.tags.hasOwnProperty('fullstory')) {
    // Fullstory tag
    let fullstory = document.createElement('script')
    fullstory.innerHTML = `
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_org'] = '${environment.tags.fullstory}';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
        if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
        g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
        o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_host+'/s/fs.js';
        y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
        g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
        g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
        g.log = function(a,b) { g("log", [a,b]) };
        g.consent=function(a){g("consent",!arguments.length||a)};
        g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
        g.clearUserCookie=function(){};
      })(window,document,window['_fs_namespace'],'script','user');
    `

    document.head.appendChild(fullstory)
  }

  if (environment.tags.hasOwnProperty('mouseflow')) {
    let mouseflow = document.createElement('script')
    mouseflow.type = 'text/javascript'
    mouseflow.innerHTML = `
        window.mouseflowEnableCssRecording = true;
        window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "//cdn.mouseflow.com/projects/${environment.tags.mouseflow}.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();
      `
    document.head.appendChild(mouseflow)
  }

  if (environment.tags.hasOwnProperty('bing')) {
    let bing = document.createElement('script')
    bing.innerHTML = `
      (function(w,d,t,r,u){
        var f,n,i;
        w[u]=w[u]||[],f=function(){var o={ti:"${environment.tags.bing}"};
        o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
      `
    document.head.appendChild(bing)
  }

  if (environment.tags.hasOwnProperty('tealium')) {
    let tealium_tag = environment.tags.tealium
    let tealium = document.createElement('script')
    tealium.src = `//tms.ace.aaa.com/${tealium_tag.profile}/${tealium_tag.env}/utag.sync.js`

    document.head.appendChild(tealium)
  }

  if (environment.tags.hasOwnProperty('criteo')) {
    let criteo = document.createElement('script')
    criteo.type = "text/javascript"
    criteo.src = "//static.criteo.net/js/ld/ld.js"
    document.head.appendChild(criteo)
  }

  if (environment.tags.hasOwnProperty('yahoo')) {
    let yahoo = document.createElement('script')
    yahoo.type = "application/javascript"
    yahoo.innerHTML = `(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'${environment.tags.yahoo}'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`
    document.head.appendChild(yahoo)
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))
