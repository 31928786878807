<div class="gw-chkout-login">
  @if (mode === 'password') {
    <div @slideRight
      [@.disabled]="initialLoad"
      class="gw-chkout-login__body">
      <div class="gw-chkout-login__logo">
        <a href="#">
          <img src="{{ logo }}" alt="Brand Logo">
        </a>
      </div>
      <span class="gw-chkout-login__title">
        {{ data.message }}
      </span>
      <form [formGroup]="authForm" (ngSubmit)="login()">
        <div class="gw-chkout-form">
          <mat-form-field class="gw-chkout-field"
            [hideRequiredMarker]="true">
            <mat-label>Email Address</mat-label>
            <input matInput
              email
              type="email"
              formControlName="email"
              pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$">
            <mat-error>
              Please enter valid email address.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="gw-chkout-form">
          <mat-form-field class="gw-chkout-field"
            [hideRequiredMarker]="true">
            <mat-label>Password</mat-label>
            <input matInput
              type="password"
              formControlName="password">
            <mat-error>
              Please enter your password.
            </mat-error>
          </mat-form-field>
        </div>
        <a class="gw-chkout-login__forgot"
          (click)="changeMode('code')">
          Forgot your password?
        </a>
        <gwc-button label="Login"
          [disabled]="submitting"
          type="submit"
          [spinner]="submitting"
          [fullwidth]="true">
        </gwc-button>
        @if (error) {
          <div
            class="gw-chkout-login__error">
            Your username or password is incorrect.
          </div>
        }
      </form>
      <button mat-icon-button mat-dialog-close
        class="gw-chkout-login__close">
        <svg>
          <use href="assets/svg/shared.svg#close"/>
        </svg>
      </button>
    </div>
  }
  @if (mode === 'code') {
    <div [@slideLeft]
      class="gw-chkout-login__body">
      <div class="gw-chkout-login__logo">
        <a href="#">
          <img src="{{ logo }}" alt="Brand Logo">
        </a>
      </div>
      <span class="gw-chkout-login__title">
        {{ code_requested ? 'Please check your email for the temporary login code.' : 'Please verify your email to receive a temporary login code.' }}
      </span>
      <form [formGroup]="authForm" (ngSubmit)="login()">
        @if (!code_requested) {
          <div class="gw-chkout-form">
            <mat-form-field class="gw-chkout-field"
              [hideRequiredMarker]="true">
              <mat-label>Email Address</mat-label>
              <input matInput
                email
                type="email"
                formControlName="email"
                pattern="^\w+([-+._']\w+)*@\w+([-._]\w+)*\.\w+([-.]\w+)*$">
              <mat-error>
                Please enter valid email address.
              </mat-error>
            </mat-form-field>
          </div>
          <gwc-button
            label="Request a code"
            [disabled]="submitting"
            type="button"
            (callback)="requestCode()"
            [spinner]="submitting"
            [fullwidth]="true">
          </gwc-button>
        } @else {
          <div class="gw-chkout-form">
            <mat-form-field class="gw-chkout-field"
              [hideRequiredMarker]="true">
              <mat-label>Login Code</mat-label>
              <input matInput
                placeholder="Login Code"
                type="password"
                formControlName="password">
              <mat-error>
                Please enter the code.
              </mat-error>
            </mat-form-field>
          </div>
          <gwc-button label="Login"
            [disabled]="submitting"
            type="submit"
            [spinner]="submitting"
            [fullwidth]="true">
          </gwc-button>
        }
        <a class="gw-chkout-login__forgot gw-chkout-login__forgot--back"
          (click)="changeMode('password')">
          <svg width="8px" height="8px" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.5H1.915L4.71.705 4 0 0 4l4 4 .705-.705L1.915 4.5H8z" fill="currentColor" fill-rule="evenodd"/></svg>
          Login with a password.
        </a>
        @if (error) {
          <div
            class="gw-chkout-login__error">
            Your username or password is incorrect.
          </div>
        }
      </form>
      <button mat-icon-button mat-dialog-close
        class="gw-chkout-login__close">
        <svg>
          <use href="assets/svg/shared.svg#close"/>
        </svg>
      </button>
    </div>
  }
  <div class="gw-chkout-login__footer">
    <span>Powered by govWorks</span>
  </div>
</div>
