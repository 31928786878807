import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CardModule } from '../card/card.module'
import { RouterModule } from '@angular/router'
import { MatButtonModule } from '@angular/material/button'
import { WarningComponent } from './warning.component'

@NgModule({
  declarations: [
    WarningComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    MatButtonModule,
    RouterModule
  ],
  exports: [
    WarningComponent
  ]
})

export class WarningModule {}
