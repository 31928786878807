import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from '@angular/router'
import { CommonService } from 'src/app/services/common.service'

@Injectable({
  providedIn: 'root'
})

export class CountryMetaResolverService {
  constructor(
    private commonService: CommonService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.commonService.getCountryMeta(route.params.country)
  }
}
