import { Injectable, Inject } from '@angular/core'
import { Subject } from 'rxjs'
import { HelperService } from './helper.service'

declare var Accept: any

@Injectable({
  providedIn: 'root'
})
export class AuthnetService {
  public nonceSubject: Subject<any> = new Subject<any>()

  constructor(
    private helperService: HelperService
  ) {}

  getNonce(merchant, card, zip) {
    let authData = {
      clientKey: merchant.token,
      apiLoginID: merchant.login_id
    }

    let cardData = {
      cardNumber: card.cc_number.replace(/\s/g, ''), 
      month: card.cc_exp_month.toString(),
      year: this.helperService.formatDate(card.cc_exp_year, 'yyyy', 'yy'),
      cardCode: card.cc_ccv,
      fullName: card.cc_name,
      zip
    }

    let secureData = {
      authData, 
      cardData
    }

    Accept.dispatchData(secureData, (response) => {
      this.nonceSubject.next(response.opaqueData)
    })
  }
}
