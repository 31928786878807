<div class="gw-chkout-visa-opt">
  <span class="gw-chkout-visa-opt__label">
    <strong>{{ service.name }}</strong>
  </span>
  <span>
    {{ beautifyRange(service.min_interval, service.max_interval) }}
  </span>
  <span class="gw-chkout-visa-opt__fee">
    ${{ service.gov_fee }}<br/>
    <span>Consulate fee</span>
  </span>
  <span class="gw-chkout-visa-opt__fee">
    ${{ service.default_price }}<br/>
    <span>Service fee</span>
  </span>
</div>
