import { CurrencyPipe } from '@angular/common'
import { Component, Input } from '@angular/core'
import { HelperService } from 'src/app/services/helper.service'

@Component({
  selector: 'gw-chkout-passport-option',
  templateUrl: './passport.option.component.html',
  styleUrls: ['./passport.option.component.scss'],
  standalone: true,
  imports: [
    CurrencyPipe
  ]
})

export class PassportOptionComponent {
  @Input() option

  constructor(
    private helperService: HelperService
  ) {}

  public displayDuration(option): string {
    if (option.min_interval === 'P0D') {
      return ''
    }

    if (option.from) {
      return this.helperService.parseDuration(option.from, option.to)
    }

    return this.helperService.parseDuration(option.min_interval, option.max_interval)
  }
}
