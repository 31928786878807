import { Injectable } from "@angular/core"
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { environment } from './../../environments/environment'

@Injectable()

export class ApiService {
  constructor(private http: HttpClient) {}
  options = {
    withCredentials: true
  }

  getRequest(url: string): Observable<any> {
    return this.http.get(`${environment.API}${url}`, this.options)
      .pipe(
        map((result: any) => {
          return result.data
        })
      )
  }

  getRequestExternal(url: string): Observable<any> {
    return this.http.get(url)
  }

  postRequest(url: string, data): Observable<any> {
    return this.http
      .post<any>(`${environment.API}${url}`, data, this.options)
      .pipe(
        map((result: any) => {
          if (result instanceof HttpErrorResponse) {
            throw result
          }

          return result
        }),
        catchError(error => {
          if (error instanceof Error || error instanceof HttpErrorResponse) {
            return throwError(() => error)
          } else {
            return throwError(() => new Error(`gW unknown error ${JSON.stringify(error)}`))
          }
        })
      )
  }
}