<gw-chkout-card [title]="!hideHeader ? 'Common Questions':''"
  titleStyle="capitalize">
  <mat-accordion class="gw-chkout-questions">
    @for (question of questions; track question) {
      <mat-expansion-panel hideToggle #expansionPanel
        [id]="question.id">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            <div class="gw-chckout-question">
              <span>
                {{ question.title }}
              </span>
              <mat-icon svgIcon="arrow_right"
                class="gw-chkout-question__arrow"
              [class.gw-chckout-question__arrow--down]="expansionPanel.expanded"></mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="gw-checkout-questions__answer"
        [innerHTML]="question.description"></p>
      </mat-expansion-panel>
    }
  </mat-accordion>
</gw-chkout-card>
